import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {assignToHierarchy} from '#core/utils/config-utils.js';
import {isMobile} from '#core/utils/context-utils.js';
import {Intents} from '#core/application/intents/index.js';
import {AUT_METHOD} from '#core/application/application.js';
import {actionsProxyStorage} from './actions-proxy.js';

const {dispatch} = MojitoCore.Services.redux.store;
const IntentActions = MojitoCore.Intents.actions;

// This feature setup mojito configs to route login/deposit to actionsProxyStorage
// And configure this store to use Intents.LOGIN/DEPOSIT intents (by default)
// In case sportsbook is mounted as a widget and LOGIN/DEPOSIT is handled by Portal, LOGIN/DEPOSIT
// listeners can be set by portal using  Sportsbook API: Sportsbook.setListener('SHOW_DEPOSIT', fn),
// Sportsbook.setListener('SHOW_LOGIN', fn).
class ActionsProxyFeature extends AbstractFeature {
    get name() {
        return 'Login/Deposit Proxy';
    }

    // eslint-disable-next-line no-unused-vars
    setupConfigs(globalConfig, options, filterFn) {
        super.setupConfigs(...arguments);
        this.authMethod = options.authMethod;
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const LOGIN_INTENT = Intents.LOGIN;
        const DEPOSIT_INTENT = Intents.DEPOSIT;
        const LOGOUT_INTENT = Intents.LOGOUT;

        // If login intent is specified, disable standard login dialog
        // and pass handler to proxy
        assignToHierarchy(mojitoConfig, 'modules.stores.login', {
            ignoreShowLoginView: !!LOGIN_INTENT || this.authMethod === AUT_METHOD.EXTERNAL,
        });

        // Set default handlers
        actionsProxyStorage.setShowLoginHandler(LOGIN_INTENT);
        actionsProxyStorage.setLogoutHandler(LOGOUT_INTENT);
        actionsProxyStorage.setDepositHandler(() => {
            // Behavior differs for mobile/desktop
            if (isMobile()) {
                dispatch(IntentActions.publishIntent(DEPOSIT_INTENT.type, DEPOSIT_INTENT.data));
            } else {
                dispatch(
                    IntentActions.publishIntent(MojitoPresentation.Base.Intent.Types.SHOW_ACCOUNT_POPUP, {
                        selectedMenuId: 'deposit-portal',
                    })
                );
            }
        });
        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

new ActionsProxyFeature(allFeatures);
