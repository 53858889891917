import React, {useEffect} from 'react';
import MojitoCore from 'mojito/core';
import MojitoApplication from 'mojito/application';
import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import SCHEMA from './routes.schema.yaml';
import {removeStartingSlash} from '#core/utils/url-utils.js';
import {getExternalUrlIntent} from '#core/utils/intent-factory.js';
import {merge, mergeToHierarchy} from '#core/utils/config-utils.js';

const {dispatch} = MojitoCore.Services.redux.store;
const IntentActions = MojitoCore.Intents.actions;
const {RouteResolver, PATHS: mojitoPaths} = MojitoApplication;

class RoutesFeature extends AbstractFeature {
    get configSchema() {
        return SCHEMA;
    }

    // eslint-disable-next-line no-unused-vars
    setupConfigs(globalConfig, options, filterFn) {
        super.setupConfigs(...arguments);

        this.routingPrefix = options.routingPrefix;
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        super.beforeMojitoConfigBuild(mojitoConfig);
        const paths = merge(mojitoPaths, this.config.override || {});

        mergeToHierarchy(mojitoConfig, 'application.stores.router', {
            routeResolver: new RouteResolver(paths, this.routingPrefix),
        });

        //----------------

        mojitoConfig.customRoutes = mojitoConfig.customRoutes || [];

        (this.config.add || []).forEach(item => {
            if (!item.path || !item.action) return;
            const path = removeStartingSlash(item.path);
            const action = getExternalUrlIntent(item.action);

            const component = () => {
                useEffect(() => {
                    if (action) dispatch(IntentActions.publishIntent(action.type, action.data));
                }, []);

                return null;
            };

            mojitoConfig.customRoutes.push({path, element: React.createElement(component)});
        });
    }
}

new RoutesFeature(allFeatures);
