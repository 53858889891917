import {isFunction} from '#core/utils/utils.js';

class MessageAdaptersService {
    constructor() {
        this._messageListeners = [];
    }

    getMessageAdapters(MessagingService) {
        let {LOGIN_SUCCESS, GET_PLAYER_INFO_SUCCESS, GET_PLAYER_INFO_FAILED} = IMS_MESSAGE_IDS;

        return {
            [LOGIN_SUCCESS]: () => {
                this._runMessageListeners({event: LOGIN_SUCCESS, MessagingService});
            },
            [GET_PLAYER_INFO_SUCCESS]: message => {
                this._runMessageListeners({event: GET_PLAYER_INFO_SUCCESS, message});
            },
            [GET_PLAYER_INFO_FAILED]: () => {
                this._runMessageListeners({event: GET_PLAYER_INFO_FAILED});
            },
        };
    }

    _runMessageListeners({event, message, MessagingService}) {
        let messageAdaptersLength = this._messageListeners.length;

        for (let i = 0; i < messageAdaptersLength; i++) {
            let adapter = this._messageListeners[i];

            if (adapter[event] && isFunction(adapter[event])) {
                adapter[event]({message, MessagingService});
            }
        }
    }

    addListeners(listeners) {
        this._messageListeners.push(listeners);
    }
}

export const IMS_MESSAGE_IDS = {
    LOGIN_SUCCESS: 31002,
    GET_PLAYER_INFO: 31082,
    GET_PLAYER_INFO_SUCCESS: 31083,
    GET_PLAYER_INFO_FAILED: 31084,
    SET_PLAYER_TAGS_REQUEST: 35544,
};

export const IMSMessageService = IMS_BASED ? new MessageAdaptersService() : null;
