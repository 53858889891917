import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {DebugFlags} from '#core/application/debug/index.js';
import {DBXConsolePerformanceLogger} from './abstract-performance-logger.js';
import './mojito-patching.js';

const reduxInstance = MojitoCore.Services.redux;
const {AbstractPerformanceService, types} = MojitoCore.Services.Performance;
const bootstrapActions = MojitoServices.Bootstrap.actions;
const {TIMELINE_RECORD_TYPES, WEBSOCKET_STATUS_TYPES} = types;

const SUBSCRIPTION_INTENT = 0;
const SUBSCRIPTION_SENT = 1;
const SUBSCRIPTION_RECEIVED = 2;
const SUBSCRIPTION_PROCESSED = 3;

export const METRIC = {
    JS_LOAD_TIME: 'dbx.js_load_time',

    INIT_START: 'dbx.init.start',
    INIT_DURATION: 'dbx.init.duration',
    RENDER_START: 'dbx.render_start',
    MOJ_TIMINGS_GET_CONFIG_DURATION: 'dbx.moj_timings.get_config_duration',

    INITIAL_PAGE_WS_DURATION: 'dbx.initial_page.ws_duration',
    INITIAL_PAGE_WS_TTFB: 'dbx.initial_page.ws_ttfb',
    INITIAL_PAGE_WS_TTFS: 'dbx.initial_page.ws_ttfs',
    INITIAL_PAGE_WS_SUBSCRIPTIONS_COUNT: 'dbx.initial_page.ws_subscriptions_count',
    INITIAL_PAGE_WS_STALE_SUBSCRIPTIONS_COUNT: 'dbx.initial_page.ws_stale_subscriptions_count',
    INITIAL_PAGE_WS_CONNECTING_DURATION: 'dbx.initial_page.ws_connecting_duration',

    INITIAL_PAGE_FULL_LOAD_TIME: 'dbx.initial_page.full_load_time',
    INITIAL_PAGE_READY: 'dbx.initial_page.ready',
    INITIAL_PAGE_INTERACTED_BEFORE_READY: 'dbx.initial_page.interacted_before_ready',

    PAGE_WS_DURATION: 'dbx.page.ws_duration',
    CONNECTION_DOWNLINK: 'dbx.connection_downlink',

    PAGE_WS_SUBSCRIPTIONS_COUNT: 'dbx.page.ws_subscriptions_count',
    PAGE_WS_STALE_SUBSCRIPTIONS_COUNT: 'dbx.page.ws_stale_subscriptions_count',
};

/*eslint no-console: "off"  */
class DBXPerformanceServiceImpl extends AbstractPerformanceService {
    constructor() {
        super();
        this.loggers = [];
        this.initialLoading = true;
        this.tags = {}; // Public
        this.report = this.report.bind(this);
        this.statsHasBeenSentForPath = '';

        this.wsActiveSubscriptionsMap = new Map();
        this.wsAllSubscriptions = [];

        this.checkIfAllSubscriptionsFinished = this.checkIfAllSubscriptionsFinished.bind(this);
        this.timeoutId = 0;

        this._allTimings = new Map();
        this._allDurations = new Map();
        this._allMetrics = new Map();

        this.resetStats();
    }

    /**
     * Should be called from outside when to allow sending collected metrics to the external storage or 3rd party services.
     * For example activate the service only after user gave cookie consent.
     *
     */
    activate() {
        // Do nothing.
        // Activeness is depending on added loggers.
        // Cookie consent is ignored (atm).
    }

    configure() {
        // Do nothing.
        // All configurations could be accepted in runtime
    }

    /**
     * This method should be called from outside when transition from one page to another happened.
     *
     * @param {string} newRoute - The route of a new page where a user was just navigated to.
     */
    navigate(newRoute) {
        try {
            if (newRoute === this.pathname) return;
            if (this.statsHasBeenSentForPath !== this.pathname && this.statsHasBeenSentForPath !== '') {
                this.logWSActivityFinished();
            }
            this.resetStats(newRoute);
            this.statsHasBeenSentForPath = '';
        } catch (e) {
            // Just to re-ensure it not break mojito flow
        }
    }

    report(type, payload) {
        // console.debug('%cReport:', 'color: red', type, payload);
        let id;

        try {
            switch (type) {
                case TIMELINE_RECORD_TYPES.WS_SUBSCRIPTION_INTENT:
                    id = `${payload.collection}/${payload.id}`;
                    this.trackSubscription(id, payload, SUBSCRIPTION_INTENT);
                    break;
                case TIMELINE_RECORD_TYPES.WS_MESSAGE:
                    id = `${payload.collection}/${payload.id}`;
                    this.trackSubscription(id, payload, SUBSCRIPTION_RECEIVED);
                    break;
                case TIMELINE_RECORD_TYPES.WS_MESSAGE_PROCESSED:
                    id = `${payload.collection}/${payload.id}`;
                    this.trackSubscription(id, payload, SUBSCRIPTION_PROCESSED);
                    break;
                case TIMELINE_RECORD_TYPES.WS_STATUS:
                    if (payload.status) {
                        performance.mark(`WS_STATUS - ${payload.status}`);
                        switch (payload.status) {
                            case WEBSOCKET_STATUS_TYPES.CONNECTING:
                                this.wsConnectionStartedTimestamp = performance.now();
                                break;
                            case WEBSOCKET_STATUS_TYPES.READY:
                                this.wsConnectionReadyTimestamp = performance.now();
                                performance.measure(
                                    'WS_CONNECTION',
                                    `WS_STATUS - ${WEBSOCKET_STATUS_TYPES.CONNECTING}`
                                );
                                break;
                        }
                    } else if (payload.type === TIMELINE_RECORD_TYPES.WS_SUBSCRIPTION) {
                        // ID has different format here, thx mojito
                        id = `${payload.payload.collection}/${payload.payload.itemId.split(';').join('-')}`;
                        if (id[id.length - 1] === '-') {
                            id = id.slice(0, -1);
                        }
                        this.trackSubscription(id, payload, SUBSCRIPTION_SENT);
                    }
                    break;
                case TIMELINE_RECORD_TYPES.MODULE_RENDERED:
                    performance.mark('MODULE_RENDERED');
                    break;
                case TIMELINE_RECORD_TYPES.PAGE_READY:
                    performance.mark('PAGE_READY');
                    this.reportTiming(METRIC.INITIAL_PAGE_READY, payload.timestamp);
                    break;
                case TIMELINE_RECORD_TYPES.PAGE_INTERACTED_BEFORE_READY:
                    performance.mark('PAGE_INTERACTED_BEFORE_READY');
                    this.reportTiming(METRIC.INITIAL_PAGE_INTERACTED_BEFORE_READY, performance.now());
                    break;
                default:
                    performance.mark(type);
            }
        } catch (e) {
            // Just to re-ensure it not break mojito flow
        }
    }

    // ====================================
    addLogger(logger) {
        if (this.loggers.indexOf(logger) >= 0) return; // already added
        this.loggers.push(logger);

        // Report to newly added logger everything we collected before
        logger.setTags && logger.setTags(this.tags);
        this._allTimings.forEach((value, key) => logger.reportTiming && logger.reportTiming(key, value));
        this._allDurations.forEach((value, key) => logger.reportDuration && logger.reportDuration(key, value));
        this._allMetrics.forEach(
            (value, key) => logger.reportMetric && logger.reportMetric(key, value.value, value.unit)
        );
    }

    removeLogger(logger) {
        const idx = this.loggers.indexOf(logger);
        if (idx >= 0) this.loggers.splice(idx, 1);
    }

    resetAll() {
        this.loggers = [];
        this.resetStats();
    }

    reportTiming(name, timestampInMs) {
        if (typeof timestampInMs !== 'number' || !isFinite(timestampInMs)) return; // durationInMs is not a Number
        this._allTimings.set(name, timestampInMs);
        for (let logger of this.loggers) {
            try {
                logger.reportTiming && logger.reportTiming(name, timestampInMs);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportDuration(name, durationInMs) {
        if (typeof durationInMs !== 'number' || !isFinite(durationInMs)) return; // durationInMs is not a Number
        this._allDurations.set(name, durationInMs);
        for (let logger of this.loggers) {
            try {
                logger.reportDuration && logger.reportDuration(name, durationInMs);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportMetric(name, value, unit) {
        if (!unit) return; // it is mandatory
        this._allMetrics.set(name, {value, unit});
        for (let logger of this.loggers) {
            try {
                logger.reportMetric && logger.reportMetric(name, value, unit);
            } catch (e) {
                // do nothing
            }
        }
    }

    reportSystemMeasurementStartTime(perfName, reportName) {
        const measure = performance.getEntriesByName(perfName);
        if (measure.length <= 0) return;
        const value = measure[0].startTime;
        this.reportTiming(reportName, value);
    }

    reportSystemMeasurementDuration(perfName, reportName) {
        const measure = performance.getEntriesByName(perfName);
        if (measure.length <= 0) return;
        const value = measure[0].duration;
        this.reportDuration(reportName, value);
    }
    // ====================================
    setTags(tags) {
        Object.assign(this.tags, tags);
        for (let logger of this.loggers) {
            try {
                logger.setTags && logger.setTags(this.tags);
            } catch (e) {
                // do nothing
            }
        }
    }
    // ====================================

    resetStats(newRoute) {
        this.pathname = newRoute || window.location.pathname;

        this.firstSubscriptionTimestamp = -1;
        this.firstByteTimestamp = -1;
        this.lastByteTimestamp = performance.timeOrigin;
        this.lastSubscriptionProcessedTimestamp = -1;

        this.wsConnectionStartedTimestamp = -1;
        this.wsConnectionReadyTimestamp = -1;

        this.wsActiveSubscriptionsMap.clear();
        this.wsAllSubscriptions = [];
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = 0;

        this._allTimings.clear();
        this._allDurations.clear();
        this._allMetrics.clear();
    }

    trackSubscription(id, payload, phase) {
        if (IS_LOCAL && payload.collection === 'promotions') return;
        if (this.statsHasBeenSentForPath === this.pathname) return;
        // id = `${payload.collection}/${payload.id}`;
        const entry = this.wsActiveSubscriptionsMap.get(id);
        const timestamp = performance.now();
        switch (phase) {
            case SUBSCRIPTION_INTENT:
                // New subscription
                this.wsActiveSubscriptionsMap.set(id, {
                    id,
                    intentTimestamp: timestamp,
                });

                if (this.firstSubscriptionTimestamp < 0) {
                    this.firstSubscriptionTimestamp = timestamp;
                    this.logWSActivityStarted();
                }
                performance.mark(`WS_SUBSCRIPTION_INTENT - ${id}`, {startTime: timestamp});
                break;
            case SUBSCRIPTION_SENT:
                // Sent subscription
                if (!entry) return;
                entry.sentTimestamp = timestamp;

                performance.mark(`WS_SUBSCRIPTION - ${id}`, {startTime: timestamp});
                performance.measure(`WS/QUEUED - ${id}`, `WS_SUBSCRIPTION_INTENT - ${id}`);
                break;
            case SUBSCRIPTION_RECEIVED:
                // Received subscription
                if (!entry) return;
                entry.receivedTimestamp = timestamp;
                if (this.firstByteTimestamp < 0) {
                    this.firstByteTimestamp = timestamp;
                }
                this.lastByteTimestamp = timestamp;

                performance.mark(`WS_MESSAGE - ${id}`, {startTime: timestamp});
                performance.measure(`WS/NETWORK - ${id}`, `WS_SUBSCRIPTION - ${id}`);
                break;
            case SUBSCRIPTION_PROCESSED:
                // Processed subscription
                if (!entry) return;
                entry.processedTimestamp = performance.now();
                this.wsAllSubscriptions.push(entry);
                this.wsActiveSubscriptionsMap.delete(id); // Track only first response
                this.lastSubscriptionProcessedTimestamp = timestamp;

                performance.mark(`WS_MESSAGE_PROCESSED - ${id}`, {startTime: timestamp});
                performance.measure(`WS/PROCESSING - ${id}`, `WS_MESSAGE - ${id}`, `WS_MESSAGE_PROCESSED - ${id}`);
                if (this.wsActiveSubscriptionsMap.size === 0) {
                    if (this.timeoutId) clearTimeout(this.timeoutId);
                    this.timeoutId = setTimeout(this.checkIfAllSubscriptionsFinished, 200);
                }
                break;
        }
    }
    checkIfAllSubscriptionsFinished() {
        if (this.wsActiveSubscriptionsMap.size === 0) {
            // If all subscriptions are finished
            this.reportCollectedData();
            this.logWSActivityFinished();
            this.statsHasBeenSentForPath = this.pathname;

            this.resetStats();
        }
        this.timeoutId = 0;
    }

    /**
     * @private
     */
    reportCollectedData() {
        const totalWSSubscriptions = this.wsAllSubscriptions.length + this.wsActiveSubscriptionsMap.size;
        const staleWSSubscriptions = this.wsActiveSubscriptionsMap.size;

        let totalWSDuration = Math.round(this.lastByteTimestamp - this.firstByteTimestamp);

        const result = {
            path: this.pathname,
            totalWSSize: 0,
            totalWSDuration,
            timeToWSConnectionStarted: Math.round(this.wsConnectionStartedTimestamp),
            timeToWSConnectionReady: Math.round(this.wsConnectionReadyTimestamp),
            timeToFirstWSSubscription: Math.round(this.firstSubscriptionTimestamp),
            timeToFirstWSByte: Math.round(this.firstByteTimestamp),
            timeToLastWSByte: Math.round(this.lastByteTimestamp),
            timeToLastWSSubscriptionProcessed: Math.round(this.lastSubscriptionProcessedTimestamp),
            totalWSSubscriptions,
            staleWSSubscriptions,
            initialLoading: this.initialLoading,
            subscriptions: this.wsAllSubscriptions,
        };
        if (!this.initialLoading) {
            delete result.timeToWSConnectionStarted;
            delete result.timeToWSConnectionReady;
        }

        this.logWSActivityStatistics(result);

        this.initialLoading = false;
    }

    /**
     * @private
     */
    logWSActivityStarted() {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityStarted && logger.wsActivityStarted(this.pathname);
            } catch (e) {
                // do nothing
            }
        }
    }

    /**
     * @private
     */
    logWSActivityFinished() {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityMostlyFinished && logger.wsActivityMostlyFinished(this.pathname);
            } catch (e) {
                // do nothing
            }
        }
    }

    /**
     * @private
     * @param data {object}
     */
    logWSActivityStatistics(data) {
        for (let logger of this.loggers) {
            try {
                logger.wsActivityData && logger.wsActivityData(data);
            } catch (e) {
                // do nothing
            }
        }
    }
}

export const DBXPerformanceService = new DBXPerformanceServiceImpl();

function handleVisibilityChange() {
    if (document.visibilityState === 'hidden') {
        performance.mark('Sportsbook page is hidden');
    } else if (document.visibilityState === 'visible') {
        performance.mark('Sportsbook page is visible');
    }
}

const GO_ONLINE = 'Network change: on-line';
const GO_OFFLINE = 'Network change: off-line';
function handleNetworkChange() {
    if (window.navigator.onLine) {
        // Report as soon as we went online
        // This event triggered only if was offline before
        performance.mark(GO_ONLINE);
        performance.measure('OFFLINE', GO_OFFLINE, GO_ONLINE);
    } else {
        performance.mark(GO_OFFLINE);
    }
}
document.addEventListener('visibilitychange', handleVisibilityChange);
window.addEventListener('online', handleNetworkChange);
window.addEventListener('offline', handleNetworkChange);

reduxInstance.actionListener.startListening({
    actionCreator: bootstrapActions.dispose,
    effect: () => {
        DBXPerformanceService.resetAll();
    },
});

if (DebugFlags.allLogs) {
    DBXPerformanceService.addLogger(new DBXConsolePerformanceLogger());
}
