import {resolveBackground} from '#core/utils/color-utils.js';
import {borderStyle} from '#core/utils/style-utils.js';
import {registerTileSchema} from '#core/application/schema-registry.js';
import {constructTilePalette} from '#core/application/modules/tiles-cache.js';

import {generateDropdownMenu} from '../dropdown-menu/index.js';

import SECONDARY_DROPDOWN from './secondary-dropdown.palette-schema.yaml';

registerTileSchema(SECONDARY_DROPDOWN);

export function generateSecondaryDropdown(TILE) {
    const {FONT} = TILE;

    const DROPDOWN_MENU_TILE = constructTilePalette('DROPDOWN.MENU');

    return {
        style: {
            rootContainer: {
                height: 40,
            },
        },
        controlButton: {
            style: {
                base: {
                    fontSize: FONT.SIZE_MEDIUM,
                    justifyContent: 'space-between',
                    ...resolveBackground(TILE.SECONDARY_DROPDOWN_BG),
                    ...borderStyle(TILE.SECONDARY_DROPDOWN_BORDER_RADIUS, TILE.SECONDARY_DROPDOWN_BORDER_COLOR),
                    ...TILE.SECONDARY_DROPDOWN_TEXT,
                    gap: 8,
                    fill: TILE.SECONDARY_DROPDOWN_ICON_COLOR,
                },
                disabled: {
                    borderColor: TILE.SECONDARY_DROPDOWN_DISABLED_BORDER_COLOR,
                    fill: TILE.SECONDARY_DROPDOWN_DISABLED_ICON_COLOR,
                    ...TILE.SECONDARY_DROPDOWN_DISABLED_TEXT,
                },
            },
        },
        controlText: {
            style: {
                fontSize: 'inherit',
                fontWeight: FONT.WEIGHT_NORMAL,
                color: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
            },
        },
        rotation: {
            image: {
                style: {
                    padding: 0,
                    minHeight: 8,
                    minWidth: 8,
                    width: 8,
                    fill: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
                },
            },
        },
        ...generateDropdownMenu(DROPDOWN_MENU_TILE),
    };
}
