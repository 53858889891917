import {registerTileSchema} from '#core/application/schema-registry.js';

import {resolveBackground} from '#core/utils/color-utils.js';

import SKELETON_PART from './skeleton-part.palette-schema.yaml';

registerTileSchema(SKELETON_PART);

export function generateSkeletonPart(TILE) {
    return {
        style: {
            ...resolveBackground(TILE.SKELETON_COLOR),
            borderRadius: TILE.SKELETON_BORDER_RADIUS,
            opacity: 1,
        },
    };
}
