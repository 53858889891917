import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';
import {isMobile} from '#core/utils/context-utils.js';

import {generatePivotsRow} from '#core/application/modules/tiles/pivot/index.js';
import {generateSkeletonPart} from '#core/application/modules/tiles/skeleton-part/index.js';
import {generatePromotionQuickLinks} from '#core/application/modules/tiles/promotion-quick-links/index.js';

import {getSportBackgroundImages} from './utils.js';

const {LOCATION} = MojitoServices.Promotions.types;
const {merge} = MojitoCore.Presentation.uiStyle;

export const SPORT_BACKGROUND_IMAGE_MAP = {
    american_football: 'american_football',
    baseball: 'baseball',
    basketball: 'basketball',
    boxing: 'boxing',
    cricket: 'cricket',
    cycling: 'cycling',
    darts: 'darts',
    football: 'soccer',
    futsal: 'futsal',
    golf: 'golf',
    handball: 'handball',
    ice_hockey: 'ice_hockey',
    martial_arts: 'martial_arts',
    motorsport: 'motorsport',
    snooker: 'snooker',
    soccer: 'soccer',
    table_tennis: 'table_tennis',
    tennis: 'tennis',
    volleyball: 'volleyball',
    horse_racing: 'horse_racing',
    greyhound_racing: 'greyhound_racing',
};

// DBX-12727
const SPORT_COUPON_TYPE_CLASS_SKELETON_PART = !isMobile()
    ? {
          style: {
              flexDirection: 'row',
              alignItems: 'center',
          },
      }
    : {};

export function sportsPagesFactory(PALETTE) {
    const {FONT} = PALETTE;

    return {
        SportPage: {
            leftPromotions: {
                locationId: LOCATION.LEFT,
            },
            sportBackgroundImages: getSportBackgroundImages({sportBackgroundImageMap: SPORT_BACKGROUND_IMAGE_MAP}),
            _mobile_sportBackgroundImages: getSportBackgroundImages({
                sportBackgroundImageMap: SPORT_BACKGROUND_IMAGE_MAP,
                folderName: 'mobile',
            }),
            pageHeader: {
                headerContainer: {
                    style: {
                        borderTop: 'none',
                        ...resolveBackground(PALETTE.SPORT_HEADER_BG),
                    },
                },
                label: {
                    style: {
                        zIndex: 1,
                        fontSize: FONT.SIZE_XLARGE,
                        fontWeight: FONT.WEIGHT_BOLD,
                        ...PALETTE.SPORT_HEADER_TEXT,
                    },
                },
                backgroundImage: {
                    src: 'sport-page/generic.png',
                    _mobile_src: 'sport-page/mobile/generic.png',
                    style: {
                        objectFit: 'fill',
                        width: 'auto',
                        height: '100%',
                        justifyContent: 'end',
                    },
                },
                backgroundImageContainer: {
                    style: {
                        width: 'auto',
                    },
                },
            },
            sportTabSelector: {
                tabs: merge(generatePivotsRow(PALETTE.SPORT_TABS_PIVOT), {
                    style: {
                        container: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            margin: 0,
                            padding: '0 4px',
                        },
                    },
                }),
            },
        },
        CompetitionsView: {
            container: {
                style: {
                    backgroundColor: 'transparent',
                },
            },
            menuLinkPromotions: merge(generatePromotionQuickLinks(PALETTE.COMPETITIONS_PROMOTION_QUICK_LINKS), {
                menuRow: {
                    itemInnerContainer: {
                        style: {
                            padding: '0 8px',
                        },
                    },
                },
            }),
        },
        OutrightsView: {
            container: {
                style: {
                    backgroundColor: 'transparent',
                },
            },
        },
        EventListHeader: {
            marketOptionsContainer: {
                container: {
                    style: {
                        display: 'flex', // fix for Mojito wrong behaviour
                        _mobile_display: 'block', // moved from Mojito default
                        _desktop_justifyContent: 'flex-end',
                        flexGrow: 0,
                        flexShrink: 0,
                        flexBasis: 'auto',
                    },
                },
                sportWidthOverride: null,
            },
            title: {
                truncate: true,
            },
        },
        ...generateLeagues(PALETTE),
        ...generateEventListSkeleton(PALETTE),
    };
}

function generateLeagues(PALETTE) {
    const {FONT} = PALETTE;

    const LEAGUES_HEADER_TEXT = {
        style: {
            fontSize: FONT.SIZE_MEDIUM,
            textTransform: 'uppercase',
            ...PALETTE.LEAGUES_SECTION_HEADER_TEXT,
        },
    };
    const LEAGUES_LIST_CONTAINER = {
        style: {
            ...resolveBackground(PALETTE.LEAGUES_CONTAINER_BG),
            borderRadius: PALETTE.LEAGUES_CONTAINER_BORDER_RADIUS,
            boxShadow: PALETTE.LEAGUES_CONTAINER_SHADOW,
            padding: 0,
            overflow: 'hidden',
            ...(PALETTE.LEAGUES_CONTAINER_BORDER_WIDTH && PALETTE.LEAGUES_CONTAINER_BORDER_COLOR !== 'none'
                ? {
                      borderWidth: PALETTE.LEAGUES_CONTAINER_BORDER_WIDTH,
                      borderColor: PALETTE.LEAGUES_CONTAINER_BORDER_COLOR,
                      borderStyle: 'solid',
                  }
                : undefined),
        },
    };

    return {
        SportGroupsListView: {
            headerText: LEAGUES_HEADER_TEXT,
            listContainer: LEAGUES_LIST_CONTAINER,
            locationContainer: {
                style: {
                    minHeight: '40px',
                    padding: '0 8px',
                    ...resolveBackground(PALETTE.LEAGUES_HEADER_BG),
                },
            },
            locationName: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    fontWeight: FONT.WEIGHT_BOLD,
                    ...PALETTE.LEAGUES_HEADER_TEXT,
                },
            },
            sportGroupButton: {
                style: {
                    base: {
                        minHeight: '40px',
                        padding: '0 8px',
                    },
                },
            },
            sportGroupName: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.LEAGUES_ITEM_NAME_TEXT,
                },
            },
            chevronIcon: icon(PALETTE.LEAGUES_ITEM_CHEVRON_ICON_COLOR),
            countryIcon: icon(PALETTE.LEAGUES_ITEM_SPORT_ICON_COLOR),
        },
        SportGroupsListSkeleton: {
            headerText: LEAGUES_HEADER_TEXT,
            iconPlaceholder: {
                style: {
                    height: '16px',
                },
            },
            regionPlaceholder: {
                style: {
                    height: '16px',
                },
            },
            leaguePlaceholder: {
                style: {
                    height: '16px',
                },
            },
            listContainer: merge(LEAGUES_LIST_CONTAINER, {
                itemSpacing: '24px',
                style: {
                    padding: 8,
                },
            }),
        },
    };
}

function generateEventListSkeleton(PALETTE) {
    const {EVENT_LIST_SKELETON} = PALETTE;

    const EVENT_LIST_HEADER_WITH_SEPARATOR = isMobile()
        ? EVENT_LIST_SKELETON.EVENT_LIST_MOBILE_HEADER_WITH_SEPARATOR
        : EVENT_LIST_SKELETON.EVENT_LIST_HEADER_WITH_SEPARATOR;

    return {
        EventListSkeleton: {
            container: {
                style: {
                    ...resolveBackground(EVENT_LIST_SKELETON.EVENT_LIST_BG),
                    padding: 0,
                },
                separator: {
                    style: {
                        size: EVENT_LIST_HEADER_WITH_SEPARATOR ? '1px' : '0px',
                        color: EVENT_LIST_SKELETON.EVENT_LIST_HEADER_SEPARATOR_COLOR,
                    },
                },
            },
            eventListHeaderContainer: {
                style: {
                    ...resolveBackground(EVENT_LIST_SKELETON.EVENT_LIST_HEADER_BG),
                    padding: '0 8px',
                },
            },
            sortingSelector: generateSkeletonPart(PALETTE.EVENT_LIST_HEADER_ITEM_SKELETON_PART),
            marketSwitcherWhenSortingEnabled: generateSkeletonPart(PALETTE.EVENT_LIST_HEADER_ITEM_SKELETON_PART),
            groupHeaderContainer: {
                style: {
                    ...resolveBackground(EVENT_LIST_SKELETON.EVENT_LIST_GROUP_HEADER_BG),
                    height: '42px',
                    padding: '0 8px',
                },
            },
            typeAndClassContainer: SPORT_COUPON_TYPE_CLASS_SKELETON_PART,
            type: generateSkeletonPart(PALETTE.EVENT_LIST_GROUP_HEADER_ITEM_SKELETON_PART),
            class: generateSkeletonPart(PALETTE.EVENT_LIST_GROUP_HEADER_ITEM_SKELETON_PART),
            selectionHeader: generateSkeletonPart(PALETTE.EVENT_LIST_GROUP_HEADER_ITEM_SKELETON_PART),
            eventsContainer: {
                style: {
                    padding: '8px',
                },
                separator: {
                    style: {
                        color: EVENT_LIST_SKELETON.EVENT_LIST_GROUP_EVENTS_SEPARATOR_COLOR,
                    },
                },
            },
        },
    };
}
