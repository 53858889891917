import React from 'react';
import PropTypes from 'prop-types';

import MojitoApplication from 'mojito/application';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';

import {getConfigByContext} from '#core/utils/config-utils.js';

const AppContextProvider = MojitoCore.Presentation.AppContext.Context.Provider;
const ContextExtender = MojitoCore.Presentation.AppContext.ContextExtender;
const ContextBuilder = MojitoCore.Presentation.AppContext.ContextBuilder;
const ResourceHandler = MojitoApplication.ResourceHandler;

const {LOGGED_IN, LOGGED_OUT} = MojitoServices.Authentication.types.STATES;
const userSettingsSelectors = MojitoServices.UserSettings.selectors;
const systemSettingsSelectors = MojitoCore.Services.SystemSettings.selectors;
const authenticationSelectors = MojitoServices.Authentication.selectors;
const sportsContentSelectors = MojitoServices.SportsContent.Sports.selectors;
const {dispatch} = MojitoCore.Services.redux.store;
const IntentActions = MojitoCore.Intents.actions;
const {OVERLAY_INTERACTION} = MojitoPresentation.Base.Intent.Types;

// This code needs to break circular dependency (context-utils -> Localization -> DebugFlags -> AbstractFeature -> context-utils)
// Anyway Localization object needed later, not on static init
let Localization;
import('#core/localization.js').then(module => (Localization = module.Localization));

const uiContextPath = () => {
    const context = systemSettingsSelectors.selectApplicationMode();
    const additionalContext = systemSettingsSelectors.selectAdditionalContext();
    return [`_${context}`, additionalContext].filter(Boolean).join('_');
};

/*
A complete example of using MojitoCore.Presentation.AppContext.ContextBuilder can be found at:
https://stash.playtech.corp/projects/DGT/repos/digital-mojito/browse/client/mojito/application/app/controllerview.jsx?at=b0e3b96fd0f4d17c6cfd75c802fe12490d1a7a10#57-68
 */

// Used for playground
const CTX_APPLICATION = new ContextBuilder()
    .withSystemSettings(() => systemSettingsSelectors.selectState())
    .withUserSettings(() => userSettingsSelectors.selectUserSettings())
    .withGetLoginState(() => authenticationSelectors.selectLoginState())
    .withGetImage(path => ResourceHandler.getImage(path))
    .withSportName(sportId => sportsContentSelectors.selectSport(sportId)?.name || '')
    .withIsContentShown(() => false) // TODO: investigate if it doesn't break anything
    .withRouteResolver({getRoute: () => {}}) // TODO: the same as content shown
    .withOverlayInteraction(() => false)
    .withUiContextPath(uiContextPath)
    .build();
const CTX_EMPTY_ANALYTICS = new ContextBuilder()
    .withAnalyticsEmitter({
        emitAnalytics: (/* type, data */) => {},
    })
    .build();
const CTX_LOGGED_IN = new ContextBuilder().withGetLoginState(() => LOGGED_IN).build();
const CTX_LOGGED_OUT = new ContextBuilder().withGetLoginState(() => LOGGED_OUT).build();
const CTX_MOBILE = new ContextBuilder()
    .withUserSettings(() => userSettingsSelectors.selectUserSettings())
    .withSystemSettings(() => {
        return {language: Localization.getCurrentLanguage(), applicationMode: 'mobile'};
    })
    .withUiContextPath(() => '_mobile_')
    .withGetImage(path => ResourceHandler.getImage(path))
    .withOverlayInteraction(() => dispatch(IntentActions.publishIntent(OVERLAY_INTERACTION)))
    .build();
const CTX_DESKTOP = new ContextBuilder()
    .withUserSettings(() => userSettingsSelectors.selectUserSettings())
    .withSystemSettings(() => {
        return {language: Localization.getCurrentLanguage(), applicationMode: 'desktop'};
    })
    .withUiContextPath(() => '_desktop_')
    .withGetImage(path => ResourceHandler.getImage(path))
    .withOverlayInteraction(() => dispatch(IntentActions.publishIntent(OVERLAY_INTERACTION)))
    .build();

export function LoggedInContext(props) {
    return React.createElement(ContextExtender, {value: {...CTX_EMPTY_ANALYTICS, ...CTX_LOGGED_IN}}, props.children);
}

export function LoggedOutContext(props) {
    return React.createElement(ContextExtender, {value: {...CTX_EMPTY_ANALYTICS, ...CTX_LOGGED_OUT}}, props.children);
}

export function MobileContext(props) {
    return React.createElement(ContextExtender, {value: CTX_MOBILE}, props.children);
}

export function DesktopContext(props) {
    return React.createElement(ContextExtender, {value: CTX_DESKTOP}, props.children);
}

export function AutoContext(props) {
    return isMobile() ? MobileContext(props) : DesktopContext(props);
}

export function ApplicationContext(props) {
    return React.createElement(
        AppContextProvider,
        {value: {...CTX_APPLICATION, ...CTX_EMPTY_ANALYTICS}},
        props.children
    );
}

LoggedInContext.propTypes = {children: PropTypes.node.isRequired};
LoggedOutContext.propTypes = {children: PropTypes.node.isRequired};
MobileContext.propTypes = {children: PropTypes.node.isRequired};
DesktopContext.propTypes = {children: PropTypes.node.isRequired};
ApplicationContext.propTypes = {children: PropTypes.node.isRequired};

export function resolveConfigByCurrentContext(config) {
    const isNative = getAdditionalContext() === 'native';
    if (isNative) {
        // Apply both contexts - mobile and native
        const result = getConfigByContext(config, 'mobile');
        return getConfigByContext(result, 'native');
    }

    let context = isMobile() ? 'mobile' : 'desktop';
    return getConfigByContext(config, context);
}

export const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.platform || '');
};

export const isAndroid = () => {
    return /Android/i.test(navigator.userAgent);
};

let isMobileCached = undefined;
export const isMobile = () => {
    if (isMobileCached !== undefined) {
        return isMobileCached;
    }
    // Check for Pupeeter headless mode.
    // Unfortunately it is always start with screen width 800px, so its always detected as mobile (which is wrong)
    // So check for user agent first
    if (/ScamperUI\/desktop/i.test(navigator.userAgent)) {
        isMobileCached = false;
        return false;
    }
    if (/ScamperUI\/mobile/i.test(navigator.userAgent)) {
        isMobileCached = true;
        return true;
    }

    const isHandheld = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    isMobileCached = isHandheld || screen.width <= 1024;
    return isMobileCached;
};

// Retrieving context requires application to be initialized. Pls avoid calling this method before app init
export const getAdditionalContext = () => {
    return systemSettingsSelectors.selectAdditionalContext(); // URL is already considered in store
};

let isNativeCached = undefined;
export const isNative = () => {
    if (isNativeCached === undefined) {
        const context = getAdditionalContext();
        isNativeCached = context === 'native';
    }
    return isNativeCached;
};
