// Just global store of token descriptions
// No need to overweight with redux here

import {fetchInternalResource, isJsonContentType} from '#core/utils/utils.js';
import {assignRecursively} from '#core/utils/config-utils.js';

export const DescriptionsStore = {
    //
};

export function loadDescriptions() {
    return fetchInternalResource('mozaic/token-descriptions.json')
        .then(response => {
            let isJson = isJsonContentType(response);
            if (response.ok && isJson) {
                return response.json();
            }

            return Promise.reject(
                `Failed to load token descriptions. Response status: ${response.status} ${response.statusText}`
            );
        })
        .then(data => {
            assignRecursively(DescriptionsStore, data);
        });
}
