import {registerTileSchema} from '#core/application/schema-registry.js';
import {constructTilePalette} from '#core/application/modules/tiles-cache.js';

import {generateDropdownMenu} from '../dropdown-menu/index.js';

import TERTIARY_DROPDOWN from './tertiary-dropdown.palette-schema.yaml';

registerTileSchema(TERTIARY_DROPDOWN);

export function generateTertiaryDropdown(TILE) {
    const {FONT} = TILE;

    const DROPDOWN_MENU_TILE = constructTilePalette('DROPDOWN.MENU');

    return {
        style: {
            rootContainer: {
                height: 40,
            },
        },
        controlButton: {
            style: {
                base: {
                    borderWidth: 0,
                    justifyContent: 'start',
                    fontSize: FONT.SIZE_MEDIUM,
                    fill: TILE.TERTIARY_DROPDOWN_ICON_COLOR,
                    gap: 8,
                    ...TILE.TERTIARY_DROPDOWN_TEXT,
                },
                disabled: {
                    fill: TILE.TERTIARY_DROPDOWN_DISABLED_ICON_COLOR,
                    ...TILE.TERTIARY_DROPDOWN_DISABLED_TEXT,
                },
            },
        },
        controlText: {
            container: {
                flexGrow: 0,
            },
            style: {
                fontSize: 'inherit',
                fontWeight: FONT.WEIGHT_NORMAL,
                color: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
            },
        },
        rotation: {
            image: {
                style: {
                    padding: 0,
                    minHeight: 8,
                    minWidth: 8,
                    width: 8,
                    fill: 'inherit', // It is workaround, because mojito don't support text/icon styles for disabled state
                },
            },
        },
        ...generateDropdownMenu(DROPDOWN_MENU_TILE),
    };
}
