import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {ensureHierarchy, isEmpty, mergeTo} from '#core/utils/config-utils.js';
import SCHEMA from './user-settings.schema.yaml';
import {Localization} from '#core/localization.js';

class UserSettingsFeature extends AbstractFeature {
    get name() {
        return 'User Settings';
    }

    get configSchema() {
        return SCHEMA;
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const configurations = ensureHierarchy(mojitoConfig, 'services.stores');
        const {locales, timeZone, timeOffset, oddsFormats, defaultOddsFormat} = this.config;

        mergeTo(configurations, {
            userSettings: {
                dateTimeFormat: {
                    locales: isEmpty(locales) ? Localization.getCurrentLocale() : locales,
                    ...(timeZone && {timeZone}),
                    ...(timeOffset && {timeOffset}),
                },
                defaultOddsFormat,
                oddsFormats,
            },
        });

        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

new UserSettingsFeature(allFeatures);
