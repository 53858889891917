import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {
    BrowserClient,
    captureMessage,
    defaultStackParser,
    getCurrentScope,
    makeFetchTransport,
    setCurrentClient,
    setUser,
} from '@sentry/react';

import {AbstractFeature, allFeatures, PRIORITY} from '#core/application/abstract-feature.js';
import {currentScriptSrc} from '#core/utils/url-utils.js';
import {addErrorHook} from '#core/utils/logger.js';
import {beforeBreadcrumb} from './sentry.breadcrumbs';
import {getIntegrations} from './sentry.integrations';
import SCHEMA from './sentry.schema.yaml';
import {DBXPerformanceService} from '#core/services/performance-reporting-service/index.js';
import {DBXSentryPerformanceLogger} from './sentry.performance-logger';

const mojitoLogger = MojitoCore.logger;
const reduxInstance = MojitoCore.Services.redux;
const authenticationActions = MojitoServices.Authentication.actions;

const hostname = window.location.hostname;
const IS_PROBABLY_STAGE_ENV =
    hostname.indexOf('ptstaging') > 0 ||
    hostname.indexOf('pbs-master') > 0 ||
    hostname.indexOf('-prev') > 0 ||
    hostname.indexOf('-admin') > 0 ||
    hostname.indexOf('-live') > 0 ||
    hostname.indexOf('-stg') > 0 ||
    hostname.indexOf('mhub2') > 0;

// To prevent trashing database
// disallow Sentry for some environments
const IS_PRODUCTION = !IS_PROBABLY_STAGE_ENV && hostname !== 'localhost';
const IS_LOCAL_CLEANBOOK = IS_LOCAL && CLIENT_NAME === 'cleanbook';
const RUN_SENTRY =
    !!FEATURES.sentry && (IS_LOCAL_CLEANBOOK || (IS_PRODUCTION && navigator.userAgent.indexOf('HeadlessChrome') <= 0));

class SentryIntegrationFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);
        this.routingPrefix = '/';
        this._sentryAlreadyInitialized = false;

        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleLogError = this.handleLogError.bind(this);
    }
    initSentry() {
        if (this._sentryAlreadyInitialized) return;
        this._sentryAlreadyInitialized = true;

        let buildDate = new Date();
        buildDate.setTime(BUILD_TIMESTAMP);
        buildDate = buildDate.toLocaleString('en-GB', {hour12: false});

        const sentryConfig = {
            dsn: this.config.dsn,
            maxBreadcrumbs: this.config.maxBreadcrumbs,
            sampleRate: this.config.sampleRate, // Configures the sample rate for error events
            tracesSampleRate: this.config.tracesSampleRate, // Configures the sample rate for transactions
            release: `${CLIENT_NAME}@${MOJITO_VERSION}+${QUALIFIER}`,
            environment: window.location.host,
            initialScope: {
                tags: {
                    version: APP_VERSION,
                    hash: GIT_HASH,
                    buildDate: buildDate,
                    clientName: CLIENT_NAME,
                },
            },

            allowUrls: [currentScriptSrc.toString(), '/bundle.js'],

            transport: makeFetchTransport,
            stackParser: defaultStackParser,
            integrations: getIntegrations(),
            beforeBreadcrumb,
        };

        sentryConfig.tracesSampler = context => {
            if (Sportsbook.initializationState === '') {
                // Ignore transactions if sportsbook is not initialized or not mounted
                return 0;
            }

            if (context.parentSampled !== undefined) {
                return context.parentSampled; // just use parent's decision
            }

            if (context.name.startsWith('/')) {
                // It is URL
                if (!context.name.startsWith(this.routingPrefix)) {
                    // Only report sportsbook related transactions (not casino, poker, etc)
                    // This is to prevent trashing quota limit
                    return 0;
                }
            }
            const origin = context.attributes && context.attributes['sentry.origin'];
            if (origin === 'manual') {
                // Typically it is orphans, which are not so useful, but frequent and trashing quota limit
                return 0;
            }
            if (origin === 'auto.navigation.browser') {
                // 100 times less navigation transactions
                // Because they are not so useful, but frequent and trashing quota limit
                return this.config.tracesSampleRate / 100;
            }

            return this.config.tracesSampleRate;
        };

        // Following code is replacement for "Sentry.init(config)"
        // Only purpose for this is tree-shaking (Sentry.init have a lot of not needed stuff)
        // Documentation is slightly obsolete (on 19-02-24) https://docs.sentry.io/platforms/javascript/configuration/tree-shaking/
        getCurrentScope().update(sentryConfig.initialScope);
        const client = new BrowserClient(sentryConfig);
        setCurrentClient(client);
        client.init();
        // ---

        addErrorHook(this.handleLogError);
        mojitoLogger.addListener((namespace, logLevel, ...otherArgs) => {
            if (logLevel === mojitoLogger.ERROR) this.handleLogError(namespace, ...otherArgs);
        });

        DBXPerformanceService.addLogger(DBXSentryPerformanceLogger);
    }

    get configSchema() {
        return SCHEMA;
    }

    get priority() {
        return PRIORITY.FIRST;
    }

    // eslint-disable-next-line no-unused-vars
    setupConfigs(globalConfig, options, filterFn) {
        super.setupConfigs(...arguments);

        this.routingPrefix = options.routingPrefix;
    }

    beforeMojito() {
        this.initSentry();
        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.loginSuccess,
            effect: this.handleLoginSuccess,
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.logoutSuccess,
            effect: this.handleLogout,
        });

        return super.beforeMojito();
    }

    dispose() {
        reduxInstance.actionListener.stopListening({
            actionCreator: authenticationActions.loginSuccess,
            effect: this.handleLoginSuccess,
        });

        reduxInstance.actionListener.stopListening({
            actionCreator: authenticationActions.logoutSuccess,
            effect: this.handleLogout,
        });
        super.dispose();
    }

    handleLoginSuccess(action) {
        const {userInfo = {}} = action.payload;
        const {userName = '', userId = '', country = ''} = userInfo;

        setUser({id: userId, username: userName, segment: country});
    }

    handleLogout() {
        setUser(null);
    }

    handleLogError(namespace, ...params) {
        const message = params.reduce((prev, curr) => {
            if (typeof curr === 'string') {
                return prev + ' ' + curr;
            }
            if (typeof curr === 'object') {
                return prev + '\r\n' + JSON.stringify(curr) + '\r\n';
            }
            return prev + ' ' + JSON.stringify(curr);
        }, namespace + ':');
        captureMessage(message);
    }
}

if (RUN_SENTRY) {
    // Will be tree-shaken
    new SentryIntegrationFeature(allFeatures);
}
