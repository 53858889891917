import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';

const { EventFormatter } = MojitoPresentation.Utils;
const { DateTimeFormatter } = MojitoPresentation.Utils.DateTime;
const { DATE_TIME_FORMAT } = MojitoPresentation.Utils.DateTime.DateTimeTypes;
const EventTypes = MojitoServices.SportsContent.Events.types;

/**
 * Contains utility functions specifically for `RaceMeetingCoupons`.
 *
 * @namespace RaceCouponsUtils
 * @private
 * @memberof Mojito.Modules.RaceCoupons
 */

/**
 * Returns an object for analytics reporting, capturing race selection on a coupon.
 *
 * @param {Array} meetings - List of meetings.
 * @param {string} meetingId - Id of the meeting that contains the selected race.
 * @param {string} raceId - The id of the selected race.
 * @param {object} context - View component context.
 *
 * @returns {object} An object containing the race name and status for use in analytics reporting.
 *
 * @function Mojito.Modules.RaceCoupons.RaceCouponsUtils#buildRaceAnalyticsData
 */
export function buildRaceAnalyticsData(meetings, meetingId, raceId, context) {
    const meeting = meetings[meetingId];
    const raceEvent = findRaceInMeetings(raceId, meetings);
    const startTime = DateTimeFormatter.formatDateTime(
        raceEvent.start,
        DATE_TIME_FORMAT.SHORT_TIME,
        context.userSettings()
    );
    return {
        raceName: EventFormatter.formatEventName(meeting.canonicalName, startTime),
        status: raceEvent.status,
    };
}

/**
 * Checks if there are ongoing races in the meeting.
 *
 * @param {Array} meetingIds - List of meeting IDs for a specific location.
 * @param {object} meetings - Object containing all available meetings.
 * @returns {boolean} Flag indicating if there are ongoing races in the meeting.
 * @function Mojito.Modules.RaceCoupons.RaceCouponsUtils#hasOngoingRaces
 */
export function hasOngoingRaces(meetingIds, meetings) {
    return meetingIds.some(id => {
        const meeting = meetings[id];
        if (meeting) {
            return meeting.races.some(({ status }) => status !== EventTypes.RACE_STATUS.FINISHED);
        }
    });
}

function findRaceInMeetings(id, meetings) {
    const races = Object.values(meetings).flatMap(meeting => meeting.races);
    return races.find(race => race.id === id);
}
