import MojitoCore from 'mojito/core';
const { UIView } = MojitoCore.Presentation;
import HighlightsViewImpl from './highlights-view';

/**
 * HighlightsView - renders highlights content in the SportPage.
 *
 * @class HighlightsView
 * @memberof Mojito.Application.Pages
 */
export default UIView('HighlightsView', HighlightsViewImpl);
