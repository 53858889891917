import {getObjectProperty, merge, isObject} from '#core/utils/config-utils.js';
import {AbstractSchemaValidator} from './abstract-schema-validator.js';
import {
    DATA_TYPE,
    parseBool,
    parseInteger,
    parseIntent,
    parseList,
    parseNumber,
    parseRegExp,
    parseString,
} from '#core/utils/data-validation.cjs';

export class SchemaValidator extends AbstractSchemaValidator {
    constructor(logger, typeTransformHandlers = null) {
        super();
        this.logger = logger;
        this.typeTransformHandlers = typeTransformHandlers;
    }

    buildConfigFromSchema(config, schema) {
        const errors = [];
        let result = getObjectProperty(config, schema.key);

        if (result) {
            result = this.validateMap(result, schema.items, schema.key, errors, schema.key !== ''); // schema.key !== '' means just ignore undescribed if no root
        } else {
            if (schema.required) {
                errors.push(`No config at "${schema.key}"`);
            } else {
                if (schema.nullable) {
                    result = null;
                } else {
                    // fill with default values
                    result = this.validateMap({}, schema.items, schema.key, errors);
                }
            }
        }

        if (errors.length > 0) {
            errors.forEach(msg => this.logger.warn(msg));
            if (IS_LOCAL) {
                throw new Error(`Configuration is not valid`);
            }
        }

        return result;
    }

    validateMap(config, schemaItems, pathPrefix = '', errors = [], showUndescribed = true) {
        if (!isObject(config)) {
            errors.push(`${pathPrefix} is not map object`);
            return {};
        }
        const result = {};
        const copiedConfig = {...config};

        for (const schemaItem of schemaItems) {
            const parsed = this.getParsedValue(config, schemaItem, pathPrefix, errors, showUndescribed);

            if (parsed.error) {
                errors.push(parsed.error);

                continue;
            }

            if (parsed.value !== undefined) {
                result[schemaItem.key] = parsed.value;
            }
            delete copiedConfig[schemaItem.key];
        }

        if (showUndescribed) {
            for (const configKey in copiedConfig) {
                const fullPathKey = pathPrefix ? `${pathPrefix}.${configKey}` : configKey;

                this.logger.warn(
                    `This config item is not described with SCHEMA: %c ${fullPathKey}`,
                    'font-weight: bold'
                );
            }
        }

        return result;
    }

    getParsedValue(config, schemaItem, pathPrefix = '', errors = [], showUndescribed) {
        const fullPath = pathPrefix ? `${pathPrefix}.${schemaItem.key}` : schemaItem.key;
        const value = config[schemaItem.key];

        if (schemaItem.required && (value === undefined || value === null)) {
            return {error: `Property "${fullPath}" is required.`};
        }

        let parsed;

        if (schemaItem.nullable && (value === undefined || value === null)) {
            parsed = {value};
        } else {
            switch (schemaItem.type) {
                case DATA_TYPE.INT:
                    parsed = parseInteger(value, schemaItem.required, schemaItem.def, fullPath);
                    break;
                case DATA_TYPE.BOOL:
                    parsed = parseBool(value, schemaItem.required, schemaItem.def, fullPath);
                    break;
                case DATA_TYPE.NUMBER:
                    parsed = parseNumber(value, schemaItem.required, schemaItem.def, fullPath);
                    break;
                case DATA_TYPE.STRING:
                    parsed = parseString(value, schemaItem.required, schemaItem.def, fullPath);
                    break;
                case DATA_TYPE.MAP:
                    parsed = {
                        value: this.validateMap(
                            value || {},
                            schemaItem.items,
                            fullPath,
                            errors,
                            showUndescribed && !schemaItem.extendable
                        ),
                    };
                    if (schemaItem.extendable) {
                        if (value !== undefined && !isObject(value)) {
                            this.logger.warn(`${fullPath} is not map object`);
                            break;
                        }
                        parsed.value = merge(value || {}, parsed.value);
                    }
                    break;
                case DATA_TYPE.LIST:
                    parsed = parseList(value, schemaItem.required, schemaItem.def, schemaItem.listItemsType, fullPath);

                    break;
                case DATA_TYPE.REGEXP:
                    parsed = parseRegExp(value, schemaItem.required, schemaItem.def, fullPath);
                    break;
                case DATA_TYPE.ANY:
                    parsed = {value};
                    break;
                case DATA_TYPE.INTENT:
                    parsed = parseIntent(value, schemaItem.required, schemaItem.def, fullPath);

                    break;
                default:
                    throw new Error(`Unknown data type: ${schemaItem.type}`);
            }
        }

        const typeTransformHandler = this.typeTransformHandlers ? this.typeTransformHandlers[schemaItem.type] : null;

        if (typeTransformHandler && parsed.value) parsed.value = typeTransformHandler(parsed.value);

        if (schemaItem.possibleValues && !schemaItem.possibleValues.includes(parsed.value)) {
            return {
                error: `Value of "${fullPath}" property should be one of: ${schemaItem.possibleValues.join(
                    ' / '
                )}. Current value is "${parsed.value}".`,
            };
        }

        return parsed;
    }
}
