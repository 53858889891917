import {fetchInternalResource, isJsonContentType} from '#core/utils/utils.js';
import {isObject, merge, countProperties} from '#core/utils/config-utils.js';
import {Logger, LOG_LEVEL} from '#core/utils/logger.js';

const log = Logger('DBX Config');

export const SESSION_STORAGE_CONFIG_KEY = 'dbx.app-config';

function applicationConfigRetriever(configName = null) {
    if (!configName) {
        // Do not load anything. Return empty object
        return Promise.resolve({});
    }

    return new Promise((success, reject) => {
        fetchInternalResource(configName, {credentials: 'include'})
            .then(response => {
                let isJson = isJsonContentType(response);
                let message = 'Client config loading failed.';
                if (response.ok && isJson) {
                    return response.json();
                }

                return Promise.reject(
                    isJson
                        ? `${message} Response status: ${response.status} ${response.statusText}`
                        : `${message} Content-type is not 'application/json'`
                );
            })
            .then(data => success(data))
            .catch(error => {
                log.error(`Failed to load ${configName}.`, error);
                return reject();
            });
    });
}

class ApplicationConfigImpl {
    constructor() {
        this.initialized = false;
        this.configAccessViolated = false;
        this.config = {};
    }

    init(bundledConfig, externalConfig, options = {}) {
        const {configNameToLoad} = options;

        const start = performance.now();

        // ------ Promisify external config
        if (typeof externalConfig === 'object' || externalConfig === undefined) {
            externalConfig = Promise.resolve(externalConfig || {});
        }
        externalConfig.then(conf => (this.externalConfig = conf || {}));
        // ------ Promisify main config
        const mainConfig = applicationConfigRetriever(configNameToLoad);
        mainConfig.then(conf => (this.loadedConfig = conf));
        // ------

        return Promise.all([mainConfig, externalConfig]).then(() => {
            this.initialized = true;
            this.bundledConfig = bundledConfig;
            this.sessionConfig = this.getFromSessionStorage();
            this.options = options;

            this.printConfigSourcesInfo();
            this._rebuildConfigs();

            this.checkDeprecated();
            this.checkAppliedConfigs(this.config, bundledConfig);
            performance.measure('dbx:load-build-configs', {start});
        });
    }

    _rebuildConfigs() {
        this.config = merge(this.bundledConfig, this.loadedConfig, this.externalConfig, this.sessionConfig);
    }

    getFromSessionStorage() {
        let sessionConfig = {};
        try {
            sessionConfig = JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_CONFIG_KEY) || '{}');
        } catch (e) {
            // do nothing if error
        }
        return sessionConfig;
    }

    updateInSessionStorage(config) {
        let sessionConfig = this.getFromSessionStorage();
        sessionConfig = merge(sessionConfig, config);
        window.sessionStorage.setItem(SESSION_STORAGE_CONFIG_KEY, JSON.stringify(sessionConfig, null, 2));
    }

    clearSessionStorage() {
        window.sessionStorage.setItem(SESSION_STORAGE_CONFIG_KEY, undefined);
    }

    printConfigSourcesInfo() {
        if (!log.isLoggingEnabled(LOG_LEVEL.DEBUG)) return; // Return earlier to avoid expensive calculations

        let str =
            `%cGot configuration from following sources:` + `\n   bundled (${countProperties(this.bundledConfig)})`;
        if (Object.keys(this.loadedConfig).length > 0)
            str += `\n + ${this.options.configNameToLoad}.json (${countProperties(this.loadedConfig)})`;
        if (Object.keys(this.externalConfig).length > 0)
            str += `\n + external (${countProperties(this.externalConfig)})`;
        if (Object.keys(this.sessionConfig).length > 0)
            str += `\n + session storage (${countProperties(this.sessionConfig)})`;

        log.debug(str, Logger.STYLE_DEBUG);
    }

    checkInitialized(configName) {
        /*eslint no-console: "off"  */
        if (IS_LOCAL) {
            if (this.initialized) return;
            this.configAccessViolated = true;
            console.error(
                `%cAttempt to use application config (ApplicationConfig.${configName}) before it is actually initialized. Execution aborted`,
                'font-size: x-large; padding: 10px 0; color: red'
            );
            throw new Error('Execution aborted');
        }
    }

    checkDeprecated() {
        function check(show, oldName, newName) {
            if (!show) return;
            let msg =
                `Configuration parameter '${oldName}' is deprecated` +
                (newName ? `. Please use '${newName}' instead` : ' and not used anymore');
            log.warn(msg);
        }

        const c = this.config;
        check(c.virtualStreaming, 'virtualStreaming', 'virtuals');
    }

    checkAppliedConfigs(actualConfig, defaultConfig) {
        const newKeys = [];

        function traverse(path, act, def) {
            for (const aKey in act) {
                if (def && (def[aKey] === undefined || def[aKey] === null)) {
                    newKeys.push(path + '.' + aKey);
                } else if (typeof act[aKey] === 'object') {
                    traverse(path + '.' + aKey, act[aKey], def[aKey]);
                }
            }
        }

        const actualEl = actualConfig.customLinks || {};
        const defaultEl = defaultConfig.customLinks || {};

        traverse('customLinks', actualEl, defaultEl);
        if (newKeys.length > 0) {
            log.warn(
                `Keys probably are not used by application: ${newKeys.reduce((prev, curr) => `${prev}\r\n${curr}`, '')}`
            );
        }
    }

    analyzeConfigs() {
        const duplicatedPaths = [];

        function compareValues(path, bundledValue, loadedValue) {
            if (isObject(bundledValue) && isObject(loadedValue)) {
                for (const bundledValueKey of Object.keys(bundledValue)) {
                    compareValues(
                        `${path}.${bundledValueKey}`,
                        bundledValue[bundledValueKey],
                        loadedValue[bundledValueKey]
                    );
                }
                return;
            }
            if (Array.isArray(bundledValue) && Array.isArray(loadedValue)) {
                if (isArrayEqual(bundledValue, loadedValue)) {
                    duplicatedPaths.push(path);
                }
                return;
            }

            if (bundledValue === loadedValue) duplicatedPaths.push(path);
        }

        function isArrayEqual(bundledValue, loadedValue) {
            if (bundledValue.length !== loadedValue.length) {
                return false;
            }

            for (let i = 0; i < bundledValue.length; i++) {
                if (!isItemEqual(bundledValue[i], loadedValue[i])) {
                    return false;
                }
            }

            return true;
        }

        function isItemEqual(bundledValue, loadedValue) {
            if (isObject(bundledValue) && isObject(loadedValue)) {
                for (const bundledValueKey of Object.keys(bundledValue)) {
                    if (!isItemEqual(bundledValue[bundledValueKey], loadedValue[bundledValueKey])) {
                        return false;
                    }
                }
                return true;
            }
            if (Array.isArray(bundledValue) && Array.isArray(loadedValue)) {
                return isArrayEqual(bundledValue, loadedValue);
            }

            return bundledValue === loadedValue;
        }

        for (const bundledConfigKey of Object.keys(this.bundledConfig)) {
            compareValues(bundledConfigKey, this.bundledConfig[bundledConfigKey], this.loadedConfig[bundledConfigKey]);
        }

        if (duplicatedPaths.length !== 0) {
            log.group(
                '%cThese configuration keys are duplicating bundled configuration, redundant and could be removed: ',
                Logger.STYLE_DEBUG
            );
            const str = duplicatedPaths.reduce((result, item) => {
                return result + item + '\r\n';
            }, '\r\n');
            log.info(str);
            log.groupEnd();
        }
    }

    /**
     * @deprecated
     * @private
     * @returns {{object}}
     */
    get configuration() {
        this.checkInitialized('configuration');
        return this.config;
    }

    get apiUrl() {
        return this.config.apiUrl;
    }
    get assetsLocation() {
        return this.config.assetsLocation;
    }

    get loginIntegrationBaseURL() {
        this.checkInitialized('loginIntegrationBaseURL');
        return this.config.loginIntegrationBaseURL;
    }

    get baseApiUrl() {
        this.checkInitialized('baseApiUrl');
        return this.config.baseApiUrl;
    }

    get isPromotionsEnabled() {
        return !!this.config.promotionsEnabled;
    }

    get hideDownloadPdfButton() {
        return !!this.config.hideDownloadPdfButton;
    }

    //Was left for backward compatibility
    get externalLinks() {
        this.checkInitialized('externalLinks');
        return this.config.externalLinks || {};
    }

    get customLinks() {
        this.checkInitialized('customLinks');
        return this.config.customLinks || {};
    }

    get templateProcessing() {
        this.checkInitialized('templateProcessing');
        return this.config.templateProcessing || {};
    }

    /**
     * Customer specific PAM configuration
     * Format is free and not specified
     *
     * @returns {?object} Returns customer specific PAM configuration or null
     */
    get PAMConfig() {
        this.checkInitialized('PAMConfig');
        return this.config.PAMConfig;
    }

    get mojitoConfiguration() {
        this.checkInitialized('mojitoConfiguration');
        return this.config.mojitoConfiguration || {};
    }
}

export const ApplicationConfig = new ApplicationConfigImpl();
