import {registerTileSchema} from '#core/application/schema-registry.js';

import {icon} from '#core/utils/style-utils.js';

import {generatePrimaryLink} from '#core/application/modules/tiles/link/index.js';
import {generateSkeletonPart} from '#core/application/modules/tiles/skeleton-part/index.js';

import PROMOTION_QUICK_LINKS from './promotion-quick-links.palette-schema.yaml';
import {generatePivotsRow} from '../pivot/index.js';

registerTileSchema(PROMOTION_QUICK_LINKS);

export function generatePromotionQuickLinks(TILE) {
    const PRIMARY_LINK = generatePrimaryLink(TILE.PROMOTION_QUICK_LINK);
    const PIVOT_ROW = generatePivotsRow(TILE.PROMOTION_QUICK_LINKS_SELECTOR);

    return {
        menuRow: {
            item: {
                selected: PRIMARY_LINK,
                unselected: PRIMARY_LINK,
                indicator: {
                    style: {
                        base: {
                            height: 0,
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            chevronIcon: icon(TILE.PROMOTION_QUICK_LINK.PRIMARY_LINK_ICON_COLOR, 'chevron-right'),
            buttonScrollPane: PIVOT_ROW.buttonScrollPane,
        },
        menuRowSkeleton: {
            container: {
                style: {
                    _mobile_padding: '0 8px',
                },
            },
            linkPlaceholder: generateSkeletonPart({
                ...TILE.PROMOTION_QUICK_LINK_SKELETON_PART,
                SKELETON_BORDER_RADIUS: 20,
            }),
            iconPlaceholder: generateSkeletonPart(TILE.PROMOTION_QUICK_LINK_SKELETON_PART),
        },
    };
}
