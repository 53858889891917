import MojitoServices from 'mojito/services';
import {Bonuses} from '#core/application/modules/bonuses/bonuses.js';

const {types: UserInfoTypes} = MojitoServices.UserInfo;
const {utils: BonusFreeBetsUtils} = MojitoServices.BonusFreeBets;

export const WITHDRAWABLE = 'withdrawable';
export const SPORTS_BONUS = 'sportsBonus';
export const SPORTS_TOKEN = 'sportsToken';
export const SPORTS_TOTAL = 'sportsTotal';

export function calculateBalances({isUserLoggedIn, userInfo, activeFreeBets}) {
    const result = [];
    const {balances, currency} = userInfo || {};
    const addBalanceType = (amount, type) => {
        result.push({
            balance: {
                amount: '' + amount,
                currencyCode: currency,
            },
            balanceType: type,
        });
    };
    if (!isUserLoggedIn || !balances) {
        addBalanceType(0, WITHDRAWABLE);
        addBalanceType(0, SPORTS_BONUS);
        addBalanceType(0, SPORTS_TOKEN);
        addBalanceType(0, SPORTS_TOTAL);
    } else {
        let doCalculateFreebets =
            !Bonuses.useIMSFreebetBalance && balances[UserInfoTypes.BALANCE_TYPES.FREE_BET] === undefined;
        const freebetsAmount = doCalculateFreebets
            ? BonusFreeBetsUtils.calculateTotalFreeBetsAmount(activeFreeBets) // calculate based on freebet items from store. Store may have outdated freebet items
            : balances[UserInfoTypes.BALANCE_TYPES.FREE_BET];

        addBalanceType(balances.withdrawable || 0, WITHDRAWABLE);
        addBalanceType(balances.bonus || 0, SPORTS_BONUS);
        addBalanceType(freebetsAmount || 0, SPORTS_TOKEN);
        addBalanceType((balances.withdrawable || 0) + (balances.bonus || 0) + (freebetsAmount || 0), SPORTS_TOTAL);
    }

    return result;
}

export function balancesAreEqual(a, b) {
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; i++) {
        if (
            a[i].balanceType !== b[i].balanceType ||
            a[i].balance.amount !== b[i].balance.amount ||
            a[i].balance.currencyCode !== b[i].balance.currencyCode
        ) {
            return false;
        }
    }
    return true;
}
