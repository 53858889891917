/**
 * Navigation types.
 *
 * @class NavigationTypes
 * @name types
 * @memberof Mojito.Presentation.Base.Navigation
 */
export default class NavigationTypes {}

/**
 * The types of internal navigation.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Base.Navigation.types
 */
NavigationTypes.NAVIGATION = {
    HOME: 'HOME',
    REDIRECT_HOME_1: 'REDIRECT_HOME_1',
    REDIRECT_HOME_2: 'REDIRECT_HOME_2',
    INPLAY_PAGE: 'INPLAY_PAGE',
    INPLAY_EVENT_PAGE: 'INPLAY_EVENT_PAGE',
    INPLAY_SPORT_PAGE: 'INPLAY_SPORT_PAGE',
    SPORTS_COUPON_PAGE: 'SPORTS_COUPON_PAGE',
    SPORT_PAGE: 'SPORT_PAGE',
    SPORT_CATEGORY_PAGE: 'SPORT_CATEGORY_PAGE',
    SPORT_EVENT_PAGE: 'SPORT_EVENT_PAGE',
    RACE_MEETING_PAGE: 'RACE_MEETING_PAGE',
    RACE_PAGE: 'RACE_PAGE',
    FUTURE_RACE_PAGE: 'FUTURE_RACE_PAGE',
    VIRTUAL_SPORTS_PAGE: 'VIRTUAL_SPORTS_PAGE',
    VIRTUAL_SPORT_CLASS_PAGE: 'VIRTUAL_SPORT_CLASS_PAGE',
    VIRTUAL_SPORT_PAGE: 'VIRTUAL_SPORT_PAGE',
    VIRTUAL_SPORT_EVENT_PAGE: 'VIRTUAL_SPORT_EVENT_PAGE',
    ACCOUNT_PAGE: 'ACCOUNT_PAGE',
    ACCOUNT_ITEM_PAGE: 'ACCOUNT_ITEM_PAGE',
    ACCOUNT_MAIN_PAGE: 'ACCOUNT_MAIN_PAGE',
    PRICE_BOOST_PAGE: 'PRICE_BOOST_PAGE',
    GAMIFICATION_ROOT: 'GAMIFICATION_ROOT',
    GAMIFICATION_DASHBOARD: 'GAMIFICATION_DASHBOARD',
    GAMIFICATION_MILESTONES_LIST: 'GAMIFICATION_MILESTONES_LIST',
    GAMIFICATION_CHALLENGES_LIST: 'GAMIFICATION_CHALLENGES_LIST',
    DEEP_LINKS_ADD_SELECTIONS: 'DEEP_LINKS_ADD_SELECTIONS',
    BAD_REQUEST_PAGE: 'BAD_REQUEST_PAGE',
    CUSTOM_NAVIGATION: 'CUSTOM_NAVIGATION',
    COMPETITIONS: 'COMPETITIONS',
    MATCHES: 'MATCHES',
    PRICE_BOOSTS: 'PRICE_BOOSTS',
    OUTRIGHTS: 'OUTRIGHTS',
    EVENTS_COUPON: 'EVENTS_COUPON',
    OUTRIGHTS_LEAGUE: 'OUTRIGHTS_LEAGUE',
    COMPETITIONS_LEAGUE: 'COMPETITIONS_LEAGUE',
    QUICK_START_GUIDE: 'QUICK_START_GUIDE',
    HIGHLIGHTS: 'HIGHLIGHTS',
    MEETINGS: 'MEETINGS',
    MEETINGS_COUPON: 'MEETINGS_COUPON',
    FUTURE: 'FUTURE',
};

/**
 * Enumeration of parameter types used in navigation routes.
 * Often utilized to construct dynamic routing paths. For instance, `/sports/${SPORT_ID}/events/${EVENT_ID}`.
 *
 * @readonly
 * @enum {string}
 * @memberof Mojito.Presentation.Base.Navigation.types
 */
NavigationTypes.PARAMETER = {
    SPORT_ID: 'sportId',
    EVENT_ID: 'eventId',
    CLASS_ID: 'classId',
    TYPE_ID: 'typeId',
    COUPON_ID: 'couponId',
    CATEGORY: 'category',
    FILTER: 'filter',
    MEETING_ID: 'meetingId',
    RACE_ID: 'raceId',
    ITEM_ID: 'itemId',
    PART_ID: 'partId',
    URL: 'url',
};

/**
 * The NavigationPayload object, which includes navigation type, parameters, and occasionally, a nested navigation type.
 * Parameters can be utilized for dynamic routing path generation.
 *
 * @typedef NavigationPayload
 * @type {object}
 * @property {Mojito.Presentation.Base.Navigation.types.NAVIGATION} type - The type of navigation.
 * @property {string} anchor - An anchor link. This will only function at the final level of nesting.
 * @property {object} params - An object defining the parameters used in navigation path generation.
 * For instance, to navigate to a sport event page with the path pattern '/sports/:sportId/events/:eventId', the params could be
 * <code>{sportId: 'soccer', eventId: '12345'}</code>.
 * Note: If the CUSTOM_NAVIGATION type is used, the params object should include a 'url' property with a ready-to-use path,
 * e.g., <code>{url: '/terms-and-conditions'}</code> to navigate to the custom 'Terms and Conditions' page.
 * @property {NavigationPayload} nested - A nested navigation payload used to generate paths like '/page/:itemId/subPage/:subItemId'.
 *
 * @memberof Mojito.Presentation.Base.Navigation.types
 */

/**
 * NavigationTypes MENUS types.
 *
 * @typedef MENUS
 * @property {string} HIGHLIGHTS - Highlights item.
 * @property {string} COMPETITIONS - Competitions item.
 * @property {string} OUTRIGHTS - Outrights item.
 * @property {string} MATCHES - Matches item.
 * @property {string} TODAY - Today item.
 * @property {string} TOMORROW - Tomorrow item.
 * @property {string} PRICE_BOOSTS - Price Boosts item.
 * @memberof Mojito.Presentation.Base.Navigation.types
 */
NavigationTypes.MENUS = {
    HIGHLIGHTS: 'highlights',
    COMPETITIONS: 'competitions',
    OUTRIGHTS: 'outrights',
    MATCHES: 'matches',
    MEETINGS: 'meetings',
    FUTURE: 'future',
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    PRICE_BOOSTS: 'price_boosts',
};
