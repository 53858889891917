import {registerTileSchema} from '#core/application/schema-registry.js';

import {lightenDarkenColor, resolveBackground} from '#core/utils/color-utils.js';

import {getButtonSidesIndent} from '../utils.js';

import TOGGLE_BUTTON from './toggle-button.palette-schema.yaml';

registerTileSchema(TOGGLE_BUTTON);

export function generateToggleButton(TILE, IS_BUTTON_BAR = false, PARAMS = {}) {
    const {WITH_ACTIVE = true, WITH_HOVER = IS_BUTTON_BAR} = PARAMS;
    const {FONT} = TILE;
    const BUTTON_BAR_BUTTON = IS_BUTTON_BAR
        ? {
              minHeight: 40,
              minWidth: 64,
              textTransform: 'capitalize',
          }
        : {
              minHeight: 40,
              maxHeight: 40,
              minWidth: 42,
              textTransform: 'uppercase',
          };
    const BUTTON_BASE = {
        borderWidth: 1,
        padding: `0 ${getButtonSidesIndent(TILE.TOGGLE_BUTTON_BORDER_RADIUS)}px`,
        alignItems: 'center',
        borderStyle: 'solid',
        borderRadius: TILE.TOGGLE_BUTTON_BORDER_RADIUS,
        borderColor: TILE.TOGGLE_BUTTON_BORDER_COLOR,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...BUTTON_BAR_BUTTON,
    };
    const TEXT_BASE = {
        fontSize: FONT.SIZE_MEDIUM,
        fontWeight: FONT.WEIGHT_NORMAL,
    };
    const ACTIVE_BUTTON = {
        ...resolveBackground(TILE.TOGGLE_BUTTON_SELECTED_BG),
        borderColor: TILE.TOGGLE_BUTTON_BORDER_COLOR,
        ...TILE.TOGGLE_BUTTON_SELECTED_TEXT,
    };
    const DISABLED_TEXT = {
        fontSize: FONT.SIZE_MEDIUM,
        fontWeight: FONT.WEIGHT_NORMAL,
        ...TILE.TOGGLE_BUTTON_TEXT,
        ...TILE.TOGGLE_BUTTON_DISABLED_TEXT,
    };

    return {
        selected: {
            style: {
                base: {
                    ...BUTTON_BASE,
                    ...TEXT_BASE,
                    ...TILE.TOGGLE_BUTTON_TEXT,
                    ...TILE.TOGGLE_BUTTON_SELECTED_TEXT,
                    ...resolveBackground(TILE.TOGGLE_BUTTON_SELECTED_BG),
                    borderColor: TILE.TOGGLE_BUTTON_SELECTED_BORDER_COLOR,
                },
                hover: WITH_HOVER ? ACTIVE_BUTTON : {},
                active: WITH_ACTIVE ? ACTIVE_BUTTON : {},
                // due to mojito, active almost always is disabled.
                // And to look correctly we disable 'disabled' styles for active button
                disabled: {}, //DISABLED_TEXT,
            },
        },
        unselected: {
            style: {
                base: {
                    ...BUTTON_BASE,
                    ...TEXT_BASE,
                    ...TILE.TOGGLE_BUTTON_TEXT,
                    ...resolveBackground(TILE.TOGGLE_BUTTON_BG),
                },
                hover: WITH_HOVER
                    ? {
                          ...ACTIVE_BUTTON,
                          ...resolveBackground(lightenDarkenColor(TILE.TOGGLE_BUTTON_SELECTED_BG, -5)),
                      }
                    : {},
                active: WITH_ACTIVE ? ACTIVE_BUTTON : {},
                disabled: {
                    ...DISABLED_TEXT,
                    ...resolveBackground(TILE.TOGGLE_BUTTON_DISABLED_BG),
                    borderColor: TILE.TOGGLE_BUTTON_DISABLED_BORDER_COLOR,
                },
            },
        },
    };
}
