import {resolveBackground} from '#core/utils/color-utils.js';
import {registerTileSchema} from '#core/application/schema-registry.js';

import {BASE_BACKGROUND_RESETER, getButtonSidesIndent} from '../utils.js';

import TERTIARY_BUTTON from './tertiary-button.palette-schema.yaml';

registerTileSchema(TERTIARY_BUTTON);

export function generateTertiaryButton(TILE) {
    const {FONT} = TILE;

    const ACTIVE_BUTTON = {
        ...BASE_BACKGROUND_RESETER,
        borderRadius: TILE.TERTIARY_BUTTON_BORDER_RADIUS,
        ...resolveBackground(TILE.TERTIARY_BUTTON_SELECTED_BG),
        ...TILE.TERTIARY_BUTTON_SELECTED_TEXT,
    };

    return {
        style: {
            base: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                backgroundColor: 'transparent',
                alignItems: 'center',
                height: 40,
                padding: `0 ${getButtonSidesIndent(TILE.TERTIARY_BUTTON_BORDER_RADIUS)}px`,
                ...TILE.TERTIARY_BUTTON_TEXT,
            },
            active: ACTIVE_BUTTON,
            hover: ACTIVE_BUTTON,
            disabled: {
                ...BASE_BACKGROUND_RESETER,
                ...TILE.TERTIARY_BUTTON_DISABLED_TEXT,
            },
        },
    };
}
