import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import RaceCouponsViewImpl from './race-coupons-view.jsx';
import { noop } from 'mojito/utils';

const { UIView } = MojitoCore.Presentation;

/**
 * A view that presents a filter for today/tomorrow/streaming and provides a list of all meetings with comprehensive information and races.
 *
 * @class RaceCouponsView
 * @private
 * @memberof Mojito.Modules.RaceCoupons
 */

/**
 * RaceCouponView prop types.
 *
 * @property {string} sportId - The current sport id to retrieve the coupons.
 * @property {boolean} [isContentPending] - Boolean to confirm if content is available  or not.
 * @property {object} [meetings = {}] - An associative array containing all meetings.
 * @property {Array} [meetingsForLocations = []] - An array containing all meetings for each location.
 * @property {string} [selectedFilter] - Specifies how to filter the data based on the selected filter (e.g. "today", "tomorrow", etc.).
 * @property {Array} [initiallyExpandedMeetings] - Number of initially expanded meetings.
 * @property {Mojito.Presentation.Utils.Filters.DAY} [filters] - Specifies which meetings to display.
 * @property {Function} [onFilterClicked = ()=>{}] - A callback triggered when a filter item is clicked. It receives 'id' as parameter.
 * @property {Function} [onRaceClicked = ()=>{}] - A callback triggered when a race is clicked. It receives the corresponding 'meetingId' and raceId as parameters.
 *
 * @memberof Mojito.Modules.RaceCoupons.RaceCouponView
 */
const propTypes = {
    sportId: PropTypes.string.isRequired,
    isContentPending: PropTypes.bool,
    meetings: PropTypes.object,
    meetingsForLocations: PropTypes.array,
    selectedFilter: PropTypes.string,
    initiallyExpandedMeetings: PropTypes.number,
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    onFilterClicked: PropTypes.func,
    onRaceClicked: PropTypes.func,
    allowBestOddsGuaranteed: PropTypes.bool,
};

const defaultProps = {
    meetings: {},
    meetingsForLocations: [],
    onFilterClicked: noop,
    onRaceClicked: noop,
};

export default UIView('RaceCouponsView', RaceCouponsViewImpl, propTypes, defaultProps);
