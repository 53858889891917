import { useCallback, useState, useMemo } from 'react';
import MojitoServices from 'mojito/services';
import MojitoCore from 'mojito/core';
import TopSportsView from './top-sports-view/index.jsx';
import { useContentPreload, useSportMetaInfo, useSportMetasLoadDone } from 'modules/common/hooks';
import { useAwaitingClientsRegistry, useAwaitingClientsLoadDone } from 'modules/performance/hooks';

const isShallowEqual = MojitoCore.Base.objUtils.isShallowEqual;
const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;
const UIViewImplementation = MojitoCore.Presentation.UIViewImplementation;
const sportMetaInformationDescriptor = MojitoServices.SportsContent.SportMetaInformation.descriptor;

const { SPORT_META_INFORMATION_CHUNK } = sportMetaInformationDescriptor.DATA_TYPES;

class TopSportsControllerView extends UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            selectedSport: undefined,
        };
        const { sportListName } = this.config;
        this.analyticsContext = new AnalyticsContextBuilder()
            .withAnalyticsPath('TopSports')
            .withData({ listName: sportListName })
            .build();
        this.onSportClicked = this.onSportClicked.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isShallowEqual(this.props, nextProps) || !isShallowEqual(this.state, nextState);
    }

    onSportClicked(sportId) {
        this.emitAnalytics('topSportClicked', {
            sportListName: this.config.sportListName,
            sportType: sportId,
        });
        this.setState({ selectedSport: sportId });
    }

    getSelectedSport(sportId, sports) {
        return sports.some(sport => sport.id === sportId) ? sportId : sports[0]?.id;
    }

    render() {
        const { topSports, isLoadDone, navigationTitle, onShowUp } = this.props;
        const selectedSport = this.getSelectedSport(this.state.selectedSport, topSports);

        if (isLoadDone && topSports.length === 0) {
            return null;
        }

        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <TopSportsView
                    topSports={topSports}
                    isLoading={!isLoadDone}
                    navigationTitle={navigationTitle}
                    selectedSport={selectedSport}
                    onSportClicked={this.onSportClicked}
                    onShowUp={onShowUp}
                    config={this.config.view}
                />
            </AnalyticsContextExtender>
        );
    }
}

const TopSportsModule = props => {
    const {
        mojitoTools: { instanceId, config },
    } = props;
    const { sportListName, enableContentLazyLoading, reportLoadingStatus } = config;

    const [visible, setVisible] = useState(!enableContentLazyLoading);
    const sportListNames = useMemo(
        () => (visible ? [sportListName] : []),
        [sportListName, visible]
    );
    const [shouldRequestData] = useContentPreload(
        visible ? instanceId : undefined,
        'TopSports',
        SPORT_META_INFORMATION_CHUNK,
        sportListNames
    );
    const topSports = useSportMetaInfo(sportListName, shouldRequestData);
    const isLoadDone = useSportMetasLoadDone(sportListNames);
    const syncAwaitingClients = useAwaitingClientsRegistry(
        'TopSports',
        reportLoadingStatus ? instanceId : undefined
    );
    useAwaitingClientsLoadDone(isLoadDone, instanceId);
    const onShowUp = useCallback(() => {
        setVisible(true);
        syncAwaitingClients(isLoadDone);
    }, [syncAwaitingClients, isLoadDone]);

    return (
        <TopSportsControllerView
            {...props}
            topSports={topSports}
            onShowUp={onShowUp}
            isLoadDone={isLoadDone}
        />
    );
};
export default TopSportsModule;
