import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import RaceCouponsControllerViewImpl from './race-coupons-controller-view.jsx';

const { UIControllerView } = MojitoCore.Presentation;

/**
 * Module designated to displaying race meetings coupons.
 *
 * @class RaceCouponsControllerView
 * @name RaceCoupons
 * @memberof Mojito.Modules
 */

/**
 * RaceCouponsControllerView prop types.
 *
 * @property {string} sportId - The sport id.
 * @property {string} [couponSelectedFilter] - Determines which race coupons should be displayed (e.g. "today", "tomorrow", etc.).
 * @property {Array} [couponFilters] - An array comprising the filters to be displayed in every CollapsiblePane.
 *
 * @memberof Mojito.Modules.RaceCoupons
 */
const propTypes = {
    sportId: PropTypes.string.isRequired,
    couponSelectedFilter: PropTypes.string,
    couponFilters: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
        })
    ),
};

export default UIControllerView('RaceCoupons', RaceCouponsControllerViewImpl, propTypes);
