export const DEFAULT_RANGE_FILTERS = [
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.LAST_BETS_SELECTION',
            value: '10',
        },
        type: 'LAST_BETS_AMOUNT',
    },
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.LAST_BETS_SELECTION',
            value: '20',
        },
        type: 'LAST_BETS_AMOUNT',
    },
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.TODAY',
            value: 'today',
            timeSpan: 'today',
        },
        type: 'TIME_FRAME',
    },
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.LAST_WEEK',
            value: 'last-week',
            duration: 1,
            timeSpan: 'week',
        },
        type: 'TIME_FRAME',
    },
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.LAST_MONTH',
            value: 'last-month',
            duration: 1,
            timeSpan: 'month',
        },
        type: 'TIME_FRAME',
    },
    {
        options: {
            translationKey: '$DBX.BET_HISTORY.TIME_FRAME.LAST_THREE_MONTHS',
            value: 'last-three-months',
            duration: 3,
            timeSpan: 'months',
        },
        type: 'TIME_FRAME',
    },
].concat(
    GENBET_BASED
        ? [
              {
                  options: {
                      translationKey: '$DBX.BET_HISTORY.TIME_FRAME.ONE_YEAR',
                      value: 'one-year',
                      duration: 1,
                      timeSpan: 'year',
                  },
                  type: 'TIME_FRAME',
              },
              {
                  options: {
                      translationKey: '$DBX.BET_HISTORY.TIME_FRAME.FIVE_YEARS',
                      value: 'five-years',
                      duration: 5,
                      timeSpan: 'year',
                  },
                  type: 'TIME_FRAME',
              },
          ]
        : []
);
