import MojitoPresentation from 'mojito/presentation';
import { useParams, useOutletContext } from 'react-router-dom';
import MojitoModules from 'mojito/modules';

const { FlexPane } = MojitoPresentation.Components;
const { RacingHelper } = MojitoPresentation.Utils;
const { COUPONS } = MojitoModules.SingleLeagueCoupon.SingleLeagueCouponTypes;
const { module: SportGroupsList, types: SportGroupsListTypes } = MojitoModules.SportGroupsList;

import { useEffect, useState } from 'react';

export default function OutrightsView({ mojitoTools }) {
    const { config, emitPerformanceMark } = mojitoTools;
    const { sportId } = useParams();
    const contentInfo = useOutletContext();
    const dataLabel = RacingHelper.isRacingSport(sportId) ? 'future' : 'outrights';

    const [dataLoaded, setDataLoaded] = useState(false);

    const handleDataLoad = () => setDataLoaded(true);

    useEffect(() => {
        emitPerformanceMark('moduleRendered', dataLoaded);
    }, [emitPerformanceMark, dataLoaded]);

    return (
        <FlexPane config={config.container}>
            <SportGroupsList
                sportId={sportId}
                type={SportGroupsListTypes.TYPE.TOP}
                groupId={`${sportId}${SportGroupsListTypes.GROUP_ID.TOP_OUTRIGHTS}`}
                pageType={COUPONS.OUTRIGHTS}
                onDataLoad={handleDataLoad}
                config={config.topLeagues}
            />
            <SportGroupsList
                sportId={sportId}
                type={SportGroupsListTypes.TYPE.ALL}
                groupId={contentInfo?.[dataLabel]?.group}
                pageType={COUPONS.OUTRIGHTS}
                onDataLoad={handleDataLoad}
                config={config.allLeagues}
            />
        </FlexPane>
    );
}
