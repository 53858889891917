import {registerTileSchema} from '#core/application/schema-registry.js';

import {resolveBackground} from '#core/utils/color-utils.js';

import {generateBetStatusIcon} from '#core/application/modules/tiles/bet-status-icon/index.js';
import {generateEarlyPayoutIndicator} from '#core/application/modules/tiles/early-payout-indicator/index.js';

import BET_BUILDER_SELECTION_ITEM from './bet-builder-selection-item.palette-schema.yaml';

registerTileSchema(BET_BUILDER_SELECTION_ITEM);

export function generateBetBuilderSelectionItem(TILE) {
    const {FONT} = TILE;

    return {
        selectionLabel: {
            style: {
                fontWeight: FONT.WEIGHT_BOLD,
                fontSize: FONT.SIZE_MEDIUM,
                ...TILE.BB_BET_SELECTION_NAME_TEXT,
            },
        },
        marketNameInnerContainer: {
            useSpacingSeparator: true,
            itemSpacing: 4,
        },
        marketName: {
            style: {
                fontWeight: FONT.WEIGHT_NORMAL,
                fontSize: FONT.SIZE_SMALL,
                ...TILE.BB_BET_MARKET_TEXT,
            },
        },
        separatorLine: {
            style: {
                backgroundColor: TILE.BB_BET_PATH_LINE_COLOR,
            },
        },
        selectionIcon: {
            style: {
                fill: TILE.BB_BET_ICON_COLOR,
                width: 12,
            },
        },
        suspendedSelectionLabel: {
            suspendedText: {
                container: {
                    ...resolveBackground(TILE.BB_BET_SUSPENDED_NOTIFICATION.INFO_BG),
                    fontWeight: FONT.WEIGHT_NORMAL,
                    ...TILE.BB_BET_SUSPENDED_NOTIFICATION.INFO_TEXT,
                },
            },
        },
        legStatusIcon: generateBetStatusIcon(TILE.BB_BET_STATUS_ICON),
        earlyPayoutIndicator: generateEarlyPayoutIndicator(TILE.BB_EARLY_PAYOUT_INDICATOR),
    };
}
