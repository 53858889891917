import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';

const { SkeletonPart, FlexPane } = MojitoPresentation.Components;

const RaceCouponsSkeleton = props => {
    const {
        mojitoTools: { config },
    } = props;

    return (
        <FlexPane config={config.topLevelContainer}>
            {range(2).map(i => (
                <FlexPane class="ta-RaceCouponSkeleton" key={i} config={config.container}>
                    <RaceCouponHeader config={config} />
                    <FlexPane config={config.startTimeAndMeetingsContainer}>
                        <FlexPane config={config.meetingsContainer}>
                            <SkeletonPart config={config.meetingName} />
                            <SkeletonPart config={config.trackStatus} />
                        </FlexPane>
                        <FlexPane config={config.startTimeContainer}>
                            {range(config.numberOfStartTimes).map(i => (
                                <SkeletonPart key={i} config={config.startTime} />
                            ))}
                        </FlexPane>
                    </FlexPane>
                </FlexPane>
            ))}
        </FlexPane>
    );
};

export default RaceCouponsSkeleton;

const RaceCouponHeader = ({ config }) => {
    return (
        <FlexPane config={config.headerContainer}>
            <FlexPane config={config.iconAndRegionContainer}>
                <SkeletonPart config={config.icon} />
                <SkeletonPart config={config.region} />
            </FlexPane>
            <FlexPane config={config.filterContainer}>
                <SkeletonPart config={config.filter} />
                <SkeletonPart config={config.filter} />
            </FlexPane>
            <FlexPane config={config.meetingsContainer}>
                <SkeletonPart config={config.meetingName} />
                <SkeletonPart config={config.trackStatus} />
            </FlexPane>
            <FlexPane config={config.startTimeContainer}>
                {range(config.numberOfStartTimes).map(i => (
                    <SkeletonPart key={i} config={config.startTime} />
                ))}
            </FlexPane>
        </FlexPane>
    );
};
