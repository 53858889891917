/*
   This fixes issues:
     1) Misuse of react lifeCycle (useEffect is not the same as componentDidMount).
        Misusing makes Safari crashing on portal integrated app (portal just call logout.defer() before render) - DBX-9768
     2) Unmount-mount causes bugs with routing. This fix recreates 'history' on component creation (not app start)
        that resets history cache (DBX-9946)
   Code is cloned & fixed from 22.5 version
*/

import React, {useLayoutEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Routes, useLocation, unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import MojitoApplication from 'mojito/application';

const {Router, AppControllerView} = MojitoApplication;
const {selectors: routerSelectors, actions: routerActions} = Router;
const {analyticsListener} = MojitoApplication.Analytics;

import {createBrowserHistory, parsePath} from 'history';

const propTypes = {
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

MainControllerView.propTypes = propTypes;

export function MainControllerView({customRoutes}) {
    const dispatch = useDispatch();
    const route = useSelector(routerSelectors.selectRoute);
    const routeResolver = routerSelectors.getRouteResolver();
    const isRedirected = useSelector(routerSelectors.selectIsRedirect);
    const [history] = useState(createBrowserHistory({window}));

    useLayoutEffect(() => {
        analyticsListener.init(history);
    }, [history]);

    useLayoutEffect(() => {
        const onHistoryChangeWithRoute = ({location, action}) => {
            onHistoryChange({location, action, route, dispatch});
        };
        const historyUnlisten = history.listen(onHistoryChangeWithRoute);

        return () => {
            historyUnlisten();
        };
    }, [customRoutes, route, dispatch, history]);

    useLayoutEffect(() => {
        route && updateHistory({route, isRedirected, history});
    }, [route, isRedirected, history]);

    useLayoutEffect(() => {
        dispatch(routerActions.routeInit(history.location.pathname));
    }, [history.location.pathname]);

    let root = (routeResolver && routeResolver.getRoot()) || '/';
    root = root.endsWith('/') ? `${root}*` : `${root}/*`;
    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path={root} element={<ApplicationContent customRoutes={customRoutes} />} />
            </Routes>
        </HistoryRouter>
    );
}

const ApplicationContent = ({customRoutes}) => {
    const location = useLocation();
    return <AppControllerView location={location} customRoutes={customRoutes} />;
};

ApplicationContent.propTypes = {
    customRoutes: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            component: PropTypes.func,
        })
    ),
};

async function onHistoryChange({location, action, route, dispatch}) {
    // Sync router store with current history location path. This is needed for a cases when navigation is done
    // using ReactRouter.Redirect element or when someone explicitly calls `history.back()`.
    const pathChanged = location.pathname !== route;
    const historyChanged = action === 'REPLACE' || action === 'POP';
    if (historyChanged && pathChanged) {
        dispatch(routerActions.routeChange(location.pathname));
    }
}

function updateHistory({history, route, isRedirected}) {
    const {pathname, search = '', hash = ''} = parsePath(route);
    const queryParams = search || history.location.search;
    const url = `${pathname}${queryParams}${hash}`;
    // Use `history.replace` for routes that meant to be redirected,
    // and for the ones detected/flagged by ReactRouter (action = 'REPLACE').
    // Avoid pushing new state in browser history for proper Back/Forward navigation
    // This is the analog of `<ReactRouter.Redirect>` element
    if (isRedirected) {
        history.replace(url);
    } else if (
        // Prevent the same route from being added twice in a row:
        route !== history.location.pathname
    ) {
        history.push(url);
    }
}
