import MojitoServices from 'mojito/services';
import MojitoModules from 'mojito/modules';

import {merge} from '#core/utils/config-utils.js';
import {resolveBackground} from '#core/utils/color-utils.js';
import {icon} from '#core/utils/style-utils.js';

import {generatePivotButton} from '#core/application/modules/tiles/pivot/index.js';
import {generateProgressBar} from '#core/application/modules/tiles/progress-bar/index.js';
import {BET_FUNDS_INFO_TYPES, generateBetFundsInfo} from '#core/application/modules/tiles/bet-funds-info/index.js';

import {activeBonusesFactory} from './factory/active-bonuses.factory';
import {inProgressBonusesFactory} from './factory/in-progress-bonuses.factory';
import {bonusHistoryFactory} from './factory/bonus-history.factory';

const BonusTypes = MojitoServices.Bonus.types;
const BonusViewTypes = MojitoModules.Bonuses.types;

export function bonusesFactory(Bonuses, PALETTE) {
    const {FONT} = PALETTE;
    const BONUS_ITEM = generateBonusItem(PALETTE);

    return {
        Bonuses: {
            ...(!Bonuses.showFreeBetCode
                ? {
                      freeBetCodePosition: BonusViewTypes.FREE_BET_CODE_POSITION.HIDDEN,
                      _mobile_freeBetCodePosition: BonusViewTypes.FREE_BET_CODE_POSITION.HIDDEN,
                  }
                : {}),
            showPrewager: Bonuses.showPrewager,
            showTabSwitcher: true,
            _mobile_showTabSwitcher: true,
            tabs: [
                {
                    id: BonusTypes.BONUS_FILTER.ACTIVE,
                    label: '$BONUSES.ACTIVE',
                },
                ...(GENBET_BASED
                    ? [
                          {
                              id: BonusTypes.BONUS_FILTER.IN_PROGRESS,
                              label: '$BONUSES.IN_PROGRESS',
                          },
                      ]
                    : []),
                {
                    id: BonusTypes.BONUS_FILTER.HISTORIC,
                    label: '$BONUSES.HISTORY',
                },
            ],
            freeBetCode: {
                container: {
                    style: {
                        _desktop_paddingTop: 4,
                        _desktop_paddingRight: 25,
                        _mobile_padding: 8,
                    },
                },
                contentContainer: {
                    style: {
                        _mobile_flexBasis: '40px',
                    },
                },
                input: {
                    style: {
                        base: {
                            _mobile_flex: 'initial',
                        },
                    },
                },
            },
            spinnerLabel: {
                style: {
                    fontSize: FONT.SIZE_MEDIUM,
                    ...PALETTE.BONUSES_LOADING_TEXT,
                },
            },
            spinner: {
                image: icon(PALETTE.BONUSES_LOADING_SPINNER_ICON_COLOR),
            },
            tabsStyles: {
                container: {
                    style: {
                        ...resolveBackground(PALETTE.BONUSES_TAB_SELECTOR_CONTAINER_BG),
                    },
                },
                selectableButton: generatePivotButton(PALETTE.BONUSES_TAB_SELECTOR),
            },
            _mobile_tabsStyles: {
                // 🥴🥴🥴
                container: {
                    style: {
                        ...resolveBackground(PALETTE.BONUSES_TAB_SELECTOR_CONTAINER_BG),
                    },
                },
                selectableButton: generatePivotButton(PALETTE.BONUSES_TAB_SELECTOR),
            },
        },
        ...activeBonusesFactory(PALETTE, BONUS_ITEM),
        ...inProgressBonusesFactory(PALETTE, BONUS_ITEM),
        ...bonusHistoryFactory(PALETTE, BONUS_ITEM),
    };
}

function generateBonusItem(PALETTE) {
    const {FONT} = PALETTE;

    const BONUS_ITEM_BET_FUNDS_INFO = generateBetFundsInfo(
        PALETTE.BONUS_ITEM_BET_FUNDS_INFO,
        BET_FUNDS_INFO_TYPES.MEDIUM
    );

    return {
        container: {
            padding: 8,
            borderRadius: 8,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: PALETTE.BONUS_ITEM_BORDER_COLOR,
            ...resolveBackground(PALETTE.BONUS_ITEM_BG),
            boxShadow: PALETTE.BONUS_ITEM_SHADOW,
        },
        title: {
            fontSize: FONT.SIZE_LARGE,
            fontWeight: FONT.WEIGHT_BOLD,
            textTransform: 'capitalize',
            ...PALETTE.BONUS_ITEM_TITLE_TEXT,
        },
        icon: {
            padding: '0 4px 0 0',
            width: 16,
            height: 16,
            fill: PALETTE.BONUS_ITEM_ICON_COLOR,
        },
        status: {
            fontSize: FONT.SIZE_MEDIUM,
            fontWeight: FONT.WEIGHT_BOLD,
            textTransform: 'uppercase',
            ...PALETTE.BONUS_ITEM_STATUS_TEXT,
        },
        amount: {
            fontSize: FONT.SIZE_MEDIUM,
            fontWeight: FONT.WEIGHT_BOLD,
            textTransform: 'uppercase',
            ...PALETTE.BONUS_ITEM_AMOUNT_TEXT,
        },
        expiryDate: {
            fontSize: FONT.SIZE_SMALL,
            fontWeight: FONT.WEIGHT_NORMAL,
            ...PALETTE.BONUS_ITEM_EXPIRY_DATE_TEXT,
        },
        // tmp until Mojito refactor bonuses in next versions (MOJ-13806)
        balance: {
            fontSize: FONT.SIZE_SMALL,
            fontWeight: FONT.WEIGHT_NORMAL,
            ...PALETTE.BONUS_ITEM_BALANCE_TEXT,
        },
        description: {
            fontSize: FONT.SIZE_MEDIUM,
            fontStyle: 'normal',
            fontWeight: FONT.WEIGHT_NORMAL,
            ...PALETTE.BONUS_ITEM_DESCRIPTION_TEXT,
        },
        step: {
            numberLabelContainer: {
                ...resolveBackground(PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_BG),
                borderRadius: '50%',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                borderColor: PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_BORDER_COLOR,
            },
            numberLabel: {
                fontSize: 10,
                fontWeight: FONT.WEIGHT_BOLD,
                ...PALETTE.BONUS_ITEM_STEP_NUMBER_LABEL_TEXT,
            },
            title: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_BOLD,
                ...PALETTE.BONUS_ITEM_STEP_TITLE_TEXT,
            },
            status: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                ...PALETTE.BONUS_ITEM_STEP_STATUS_TEXT,
            },
            description: {
                fontSize: FONT.SIZE_MEDIUM,
                ...PALETTE.BONUS_ITEM_STEP_DESCRIPTION_TEXT,
            },
        },
        progressBar: generateProgressBar(PALETTE.BONUS_ITEM_PROGRESS_BAR),
        betFundsInfo: merge(BONUS_ITEM_BET_FUNDS_INFO, {
            container: {
                itemSpacing: 4,
                style: {
                    justifyContent: 'end',
                },
            },
            label: {
                container: {
                    flexGrow: 0,
                },
            },
        }),
    };
}
