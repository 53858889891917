import RaceCouponsSkeletonImpl from './race-coupons-skeleton';
import MojitoCore from 'mojito/core';

const { UIView } = MojitoCore.Presentation;

/**
 * Skeleton component designated for race coupons.
 *
 * @function RaceCouponsSkeleton
 * @memberof Mojito.Modules.RaceCoupons
 */

export default UIView('RaceCouponsSkeleton', RaceCouponsSkeletonImpl);
