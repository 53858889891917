import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {ensureHierarchy} from '#core/utils/config-utils.js';
import {ExternalNavigationService} from './external-navigation-service.js';

class ExternalNavigationFeature extends AbstractFeature {
    get name() {
        return 'External Navigation';
    }

    beforeMojitoConfigBuild(mojitoConfig) {
        const externalNavigationService = ensureHierarchy(mojitoConfig, 'services.externalNavigationService');
        externalNavigationService.service = new ExternalNavigationService();

        return super.beforeMojitoConfigBuild(mojitoConfig);
    }
}

new ExternalNavigationFeature(allFeatures);
