import {registerTileSchema} from '#core/application/schema-registry.js';

import {icon} from '#core/utils/style-utils.js';

import BET_FUNDS_INFO from './bet-funds-info.palette-schema.yaml';

registerTileSchema(BET_FUNDS_INFO);

export const BET_FUNDS_INFO_TYPES = {
    NORMAL: 'NORMAL',
    MEDIUM: 'MEDIUM',
    EMPHASIZED: 'EMPHASIZED',
};

const BET_FUNDS_INFO_TEXT_MAP = {
    [BET_FUNDS_INFO_TYPES.NORMAL]: generateNormalInfoText,
    [BET_FUNDS_INFO_TYPES.MEDIUM]: generateMediumInfoText,
    [BET_FUNDS_INFO_TYPES.EMPHASIZED]: generateEmphasizedInfoText,
};

export function generateBetFundsInfo(TILE, TYPE = BET_FUNDS_INFO_TYPES.NORMAL) {
    const betFundsInfoTextGenerator =
        BET_FUNDS_INFO_TEXT_MAP[TYPE] || BET_FUNDS_INFO_TEXT_MAP[BET_FUNDS_INFO_TYPES.NORMAL];
    const infoTextStyles = betFundsInfoTextGenerator(TILE);

    return {
        label: {
            style: infoTextStyles,
        },
        value: {
            style: infoTextStyles,
        },
        icon: icon(TILE.BET_FUNDS_INFO_ICON_COLOR),
    };
}

function generateNormalInfoText(TILE) {
    const {FONT} = TILE;

    return {
        textTransform: 'capitalize',
        fontWeight: FONT.WEIGHT_NORMAL,
        fontSize: FONT.SIZE_SMALL,
        ...TILE.BET_FUNDS_INFO_TEXT,
    };
}

function generateMediumInfoText(TILE) {
    const {FONT} = TILE;

    return {
        textTransform: 'capitalize',
        fontWeight: FONT.WEIGHT_NORMAL,
        fontSize: FONT.SIZE_MEDIUM,
        ...TILE.BET_FUNDS_INFO_MEDIUM_TEXT,
    };
}

function generateEmphasizedInfoText(TILE) {
    const {FONT} = TILE;

    return {
        textTransform: 'capitalize',
        fontWeight: FONT.WEIGHT_BOLD,
        fontSize: FONT.SIZE_MEDIUM,
        ...TILE.BET_FUNDS_INFO_EMPHASIZED_TEXT,
    };
}
