import {registerTileSchema} from '#core/application/schema-registry.js';

import {resolveBackground} from '#core/utils/color-utils.js';

import NOTIFICATION_INFO from './notification-info.palette-schema.yaml';
import NOTIFICATION_ERROR from './notification-error.palette-schema.yaml';
import NOTIFICATION_WARNING from './notification-warning.palette-schema.yaml';
import NOTIFICATION_SUCCESS from './notification-success.palette-schema.yaml';

registerTileSchema(NOTIFICATION_INFO);
registerTileSchema(NOTIFICATION_ERROR);
registerTileSchema(NOTIFICATION_WARNING);
registerTileSchema(NOTIFICATION_SUCCESS);

const NOTIFICATION_TYPES = {
    INFO: 'INFO',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    SUCCESS: 'SUCCESS',
};

export const generateInfoNotification = TILE => generateNotification(TILE, NOTIFICATION_TYPES.INFO);
export const generateErrorNotification = TILE => generateNotification(TILE, NOTIFICATION_TYPES.ERROR);
export const generateWarningNotification = TILE => generateNotification(TILE, NOTIFICATION_TYPES.WARNING);
export const generateSuccessNotification = TILE => generateNotification(TILE, NOTIFICATION_TYPES.SUCCESS);

function generateNotification(TILE, TYPE = NOTIFICATION_TYPES.INFO) {
    const {FONT} = TILE;
    const KEYS = getPaletteKeys(NOTIFICATION_TYPES[TYPE] || NOTIFICATION_TYPES.INFO);

    return {
        container: {
            itemSpacing: 8,
            style: {
                ...resolveBackground(TILE[KEYS.BG]), // until Mojito fix their bug with background property
                padding: 8,
                height: 'auto',
                alignItems: 'center',
                borderColor: TILE[KEYS.BORDER_COLOR],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: TILE[KEYS.BORDER_RADIUS],
                fontSize: FONT.SIZE_MEDIUM,
                ...TILE[KEYS.TEXT],
            },
        },
        text: {
            container: {
                padding: 0,
            },
            style: {
                wordBreak: 'break-word',
                ...TILE[KEYS.TEXT],
            },
        },
        icon: {
            style: {
                fill: TILE[KEYS.ICON_COLOR],
                // Override mojito default
                minWidth: 16,
                minHeight: 16,
            },
        },
    };
}

function getPaletteKeys(PREFIX) {
    return {
        BG: `${PREFIX}_BG`,
        BORDER_COLOR: `${PREFIX}_BORDER_COLOR`,
        TEXT: `${PREFIX}_TEXT`,
        ICON_COLOR: `${PREFIX}_ICON_COLOR`,
        BORDER_RADIUS: `${PREFIX}_BORDER_RADIUS`,
    };
}
