import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Route,
    Routes,
    useLocation,
    unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import AppControllerView from '../app/index.jsx';
import analyticsListener from 'application/analytics/analytics-listener.js';
import preBuiltAnalyticsListener from 'application/analytics/prebuilt-analytics-listener.js';
import {
    getRouteResolver,
    selectRoute,
    selectIsRedirect,
} from 'application/stores/router/selectors.js';
import { actions as routerActions } from 'application/stores/router/slice.js';
import { createBrowserHistory, parsePath } from 'history';

const { routeChange, routeInit } = routerActions;

export default function MainControllerView({ customRoutes }) {
    const dispatch = useDispatch();
    const route = useSelector(selectRoute);
    const routeResolver = getRouteResolver();
    const isRedirected = useSelector(selectIsRedirect);
    const [history] = useState(createBrowserHistory({ window }));

    useEffect(() => {
        analyticsListener.init(history);
        preBuiltAnalyticsListener.init();
    }, [history]);

    useEffect(() => {
        const onHistoryChangeWithRoute = ({ location, action }) => {
            onHistoryChange({ location, action, route, dispatch });
        };
        const historyUnlisten = history.listen(onHistoryChangeWithRoute);
        return () => {
            historyUnlisten();
        };
    }, [customRoutes, route, dispatch, history]);

    useEffect(() => {
        route && updateHistory(history, route, isRedirected);
    }, [route, isRedirected, history]);

    useEffect(() => {
        dispatch(routeInit(history.location.pathname));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.pathname]);

    let root = (routeResolver && routeResolver.getRoot()) || '/';
    root = root.endsWith('/') ? `${root}*` : `${root}/*`;
    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path={root} element={<ApplicationContent customRoutes={customRoutes} />} />
            </Routes>
        </HistoryRouter>
    );
}

const ApplicationContent = ({ customRoutes }) => {
    const location = useLocation();
    return <AppControllerView location={location} customRoutes={customRoutes} />;
};

function onHistoryChange({ location, action, route, dispatch }) {
    // Sync router store with current history location path. This is needed for a cases when navigation is done
    // using ReactRouter.Redirect element or when someone explicitly calls `history.back()`.
    const pathChanged = location.pathname !== route;
    const historyChanged = action === 'REPLACE' || action === 'POP';
    if (historyChanged && pathChanged) {
        dispatch(routeChange(location.pathname));
    }
}

function updateHistory(history, route, isRedirect) {
    const { pathname, search = '', hash = '' } = parsePath(route);
    const queryParams = search || history.location.search;
    const url = `${pathname}${queryParams}${hash}`;
    // Use `history.replace` for routes that meant to be redirected,
    // and for the ones detected/flagged by ReactRouter (action = 'REPLACE').
    // Avoid pushing new state in browser history for proper Back/Forward navigation
    // This is the analog of `<ReactRouter.Redirect>` element
    if (isRedirect) {
        history.replace(url);
    } else if (
        // Prevent the same route from being added twice in a row:
        route !== history.location.pathname
    ) {
        history.push(url);
    }
}
