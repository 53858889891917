import {PaletteRetriever} from './palette-retriever.js';
import {isLightColor} from '#core/utils/color-utils.js';
import {assignRecursively, ensureHierarchy, getObjectProperty} from '#core/utils/config-utils.js';
import {Logger} from '#core/utils/logger.js';
import {BasicPalette} from './basic-palette.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import {tileSchemaById} from '../schema-registry.js';

const tileCacheLog = Logger('TileCache');
const cache = new Map();

const EMPTY = Object.create(null);
export function constructTilePalette(id, logger, basedOnValue, extendWith) {
    const schema = tileSchemaById[id];

    if (!schema) {
        throw new Error(`Unknown tile ${id}`);
    }

    let cacheKey;
    if (!extendWith) {
        cacheKey = basedOnValue ? `${id}/${basedOnValue}` : id;
        if (cache.has(cacheKey)) {
            return cache.get(cacheKey); // Just return from the cache
        }
    }

    let variantId;
    if (basedOnValue && schema.darkLightVariants) {
        variantId = isLightColor(basedOnValue, false) ? 'ON_LIGHT' : 'ON_DARK';
    }

    let configToReadFrom;
    if (extendWith || variantId) {
        configToReadFrom = Object.create(null);
        const data = ensureHierarchy(configToReadFrom, schema.key);
        assignRecursively(data, getObjectProperty(ApplicationConfig.config, schema.key, EMPTY));
        if (variantId in data) {
            assignRecursively(data, data[variantId]);
        }
        if (extendWith) {
            assignRecursively(data, extendWith);
        }
    } else {
        configToReadFrom = ApplicationConfig.config;
    }

    let retriever = new PaletteRetriever(logger || tileCacheLog, {
        defaults: BasicPalette.palette,
        defaultsResolved: BasicPalette.palette,
        basedOn: basedOnValue,
    });
    const value = retriever.constructPaletteBySchema(configToReadFrom, schema);

    cacheKey && cache.set(cacheKey, value);
    return value;
}

window.DBX = {...window.DBX, tileCache: cache};
