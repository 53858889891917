import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {INTENT_TYPES} from '#core/utils/intent-types.js';
import {isInternalUrl} from '#core/utils/url-utils.js';
import {isNative} from '#core/utils/context-utils.js';

const {dispatch} = MojitoCore.Services.redux.store;
const AbstractExternalNavigationService = MojitoServices.ExternalNavigation.AbstractExternalNavigationService;
const IntentActions = MojitoCore.Intents.actions;

export class ExternalNavigationService extends AbstractExternalNavigationService {
    /**
     * Navigate to external url.
     * @param payload - External navigation payload.
     */
    navigate(payload) {
        // Foreign windows are delegated to the ForeignWindow domain
        if (payload.modal) {
            return;
        }

        if (Sportsbook.isEmbedded && !isInternalUrl(payload.url)) {
            /*
            The payload.windowName parameter is an empty string when the "Open Link In New Tab" option enabled in GenMoj CMS.
            Portal doesn't support opening a URL in a new tab at the moment.
            It was agreed that Sportsbook will open such a URL by its own on Desktop and on Mobile.
            While on Native Sportsbook will pass the URL to Portal.
             */
            if (payload.windowName !== '' || isNative()) {
                // Let Portal decide how to open the URL
                dispatch(
                    IntentActions.publishIntent(INTENT_TYPES.NAVIGATE_HOST, {
                        url: payload.url,
                    })
                );
                return;
            }
        }

        MojitoCore.Utils.openUrl(payload.url, payload.windowName, payload.windowFeatures);
    }
}
