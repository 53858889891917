import MojitoCore from 'mojito/core';
import AbstractAnalyticsService from './abstractanalyticsservice';
import $script from 'scriptjs';
import AnalyticsTypes from '../types';

const log = MojitoCore.logger.get('GoogleAnalytics4Service');
const gTagUrl = 'https://www.googletagmanager.com/gtag/js';
const RACING_MARKET_TYPES = AnalyticsTypes.RACING_MARKET_TYPES;

export const EVENTS = {
    A_Z_MORE: 'A-Z Sport Menu More Interaction',
    A_Z_SPORT: 'A-Z Sport Menu Sport Interaction',
    A_Z_OPENED: 'A-Z Menu Opened',
    A_Z_CLOSED: 'A-Z Menu Closed',
    BACK_BUTTON_INTERACTION: 'Back Button Interaction',
    BANKERS_SWITCH_TOGGLED: 'Bankers Switch Toggled',
    BANKERS_BET_TOGGLED: 'Bankers Bet Toggled',
    BANNER_INTERACTION: 'Banner Interaction',
    BET_ADD: 'Bet Add',
    BET_HISTORY_BET_DETAIL_INTERACTION: 'Bet History - Bet Detail Interaction',
    BET_HISTORY_BET_TIME_PERIOD_INTERACTION: 'Bet History Bet Time Period Interaction',
    BET_HISTORY_BET_FILTER_INTERACTION: 'Bet History Bet Filter Interaction',
    BET_HISTORY_PRINT: 'Bet History Print',
    BET_RECEIPT_RETAIN_SELECTIONS: 'Bet Receipt Retain Selections',
    BET_RECEIPT_CLEAR_SELECTIONS: 'Bet Receipt Clear Selections',
    BET_RECEIPT_SHOWN: 'Bet Receipt Is Shown',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL: 'Bet Slip Automatic Price Change All Interaction',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER: 'Bet Slip Automatic Price Change Higher Interaction',
    BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER: 'Bet Slip Automatic Price Change Lower Interaction',
    BETSLIP_CLOSE: 'Bet Slip Close',
    BETSLIP_EACH_WAY: 'Bet Slip Each Way Interaction',
    BETSLIP_FREEBET_ADD: 'Bet Slip Free Bet Add',
    BETSLIP_FREEBET_REMOVE: 'Bet Slip Free Bet Remove',
    BETSLIP_KEYPAD_SHOWN: 'Bet Slip Custom Keypad Shown',
    BETSLIP_KEYPAD_HIDDEN: 'Bet Slip Custom Keypad Hidden',
    BETSLIP_KEYPAD_INCREMENT: 'Bet Slip Custom Keypad Value Increment',
    BETSLIP_OPEN: 'Bet Slip Open',
    BETSLIP_REMOVE_ALL: 'Bet Slip Remove All Selections',
    BETSLIP_REQUEST_FAILED: 'Bet Slip Request Failed',
    BETSLIP_SETTINGS: 'Bet Slip Settings Interaction',
    BETSLIP_STAKE_PER_BET: 'Bet Slip Stake Per Bet Interaction',
    BETSLIP_TAB_CHANGE: 'Change bet type',
    TEASER_TYPE_CHANGE: 'Change teaser value',
    BONUSES_TAB_INTERACTION: 'Bonuses Tab Interaction',
    BREADCRUMB_INTERACTION: 'Breadcrumb Interaction',
    CARD_SWIPE: 'Card Swipe',
    CASH_OUT_STATUS: 'Cash Out Status',
    CASH_OUT_CONFIRM: 'Cash Out Confirm',
    CASH_OUT_INTERACTION: 'Cash Out Interaction',
    COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION: 'Competition - Collapse/Expand Interaction',
    COUPON_INTERACTION: 'Coupon Interaction',
    EVENT_INTERACTION: 'Event Element Interaction',
    FAVOURITES_SPORT_ADDED: 'Sport Added To Favourites',
    FAVOURITES_SPORT_REMOVED: 'Sport Removed From Favourites',
    FOOTER_MOBILE_INTERACTION: 'Footer Interaction - Mobile Navigation',
    FUTURE_RACING_COUPON_SELECTED: 'Future Races Coupon Selected',
    HEADER_INTERACTION: 'Header Interaction',
    HIGHLIGHT_MEETINGS_SELECT_MEETING: 'Highlight Meetings Select Meeting',
    HIGHLIGHT_MEETINGS_SELECT_RACE: 'Highlight Meetings Select Race',
    HIGHLIGHT_MEETINGS_SELECT_REGION: 'Highlight Meetings Select Region',
    HIGHLIGHT_MEETINGS: '',
    IN_PLAY_FILTER_INTERACTION: 'In Play Filter Interaction',
    EVENTS_NAVIGATION_MENU_OPENED: 'Open Events Menu Navigation',
    EVENTS_NAVIGATION_MENU_CLOSED: 'Close Events Menu Navigation',
    LEAGUES_NAVIGATION_MENU_OPENED: 'Open Leagues Menu Navigation',
    LEAGUES_NAVIGATION_MENU_CLOSED: 'Close Leagues Menu Navigation',
    IN_PLAY_SIDE_MENU_ITEM_CLICK: 'In Play Side Menu Item Clicked',
    LEAGUE_FILTER_INTERACTION: 'League Filter Interaction',
    LOGIN: 'Login',
    LOGIN_OPEN: 'Login Open',
    LOGO: 'Logo Clicked',
    LOGOUT: 'Logout',
    MARKET_CATEGORIES_INTERACTION: 'Market Categories Interaction',
    HANDICAP_MARKET_TYPE_MENU_INTERACTION: 'Handicap market type menu interaction',
    MARKET_COLLAPSE_OR_EXPAND_INTERACTION: 'Market Collaps/Expand Interaction',
    MARKET_FILTER_INTERACTION: 'Market Filter Interaction',
    MARKET_GROUP_CATEGORY_CLICKED: 'Market Group Category Clicked',
    MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED: 'Market Groups Show/Hide Toggle Clicked',
    MATCH_ACCA_ADD_SELECTION: 'Match Acca Add Selection',
    MATCH_ACCA_REMOVE_SELECTION: 'Match Acca Remove Selection',
    MATCH_ACCA_ADD_TO_BETSLIP: 'Match Acca Add To Betslip',
    MATCH_ACCA_OPENED: 'Match Acca Opened',
    MATCH_ACCA_CLOSED: 'Match Acca Closed',
    MATCH_ACCA_CLEARED: 'Match Acca Cleared',
    MY_ACCOUNT_TAB_CLICKED: 'My Account Tab Changed',
    EVENT_CARD_CLICKED: 'Event Card Clicked',
    NEXT_RACES_RACE_CARD_CHANGE: 'Next Races Race Card Change',
    NEXT_RACES_RACE_CARD_CLICK: 'Next Races Race Card Navigation',
    NOTIFICATION_CLICKED: 'Notification clicked',
    OTHER_FILTER_INTERACTION: 'Other Filter Interaction (US Sport)',
    PLACE_BET: 'Place Bet',
    BETSLIP_CHANGES_ACCEPTED: 'Betslip Changes Accepted',
    PUSH_NOTIFICATIONS_SWITCH: 'Push notifications switch',
    QBS_STAKE_BOX: 'QBS Stake Box Interaction',
    QBS_INCREMENT: 'QBS Custom Keypad Increment Value',
    QBS_OPEN_FULL: 'QBS Open Full Betslip',
    QBS_SETTINGS: 'QBS Settings Interaction',
    QBS_REMOVE_SELECTION: 'QBS Remove Selection',
    QUICK_LINK_INTERACTION: 'Quick Link Interaction',
    RACE_CARD_SORT_RUNNERS: 'Race Card Sort Runners',
    RACE_CARD_SELECT_RACE: 'Race Card Select Race',
    RACE_CARD_SELECT_MEETING: 'Race Card Select Meeting',
    RACE_CARD_MARKET_CHANGED: 'Race Card Market Changed',
    RACE_MEETINGS_DAY_CHANGED: 'Race Meeting Day Changed',
    RACE_MEETINGS_SELECT_MEETING: 'Race Meeting Selected',
    RACE_MEETINGS_SELECT_RACE: 'Race Meeting Race Selected',
    RACING_TABS_NAVIGATION: 'Racing Tabs Interaction',
    SELECTION_ADD: 'Add Selection',
    SELECTION_REMOVE: 'Remove Selection',
    SETTINGS_LANGUAGE: 'Settings Language',
    SETTINGS_ODDS_FORMAT: 'Settings Odds Format',
    SETTINGS_TIME_ZONE: 'Settings Time Zone',
    SEARCH_CLEARED: 'Clear Search',
    SEARCH_INPUT_FOCUSED: 'Open Search',
    SEARCH_TERM_DELETE: 'Delete Search Term',
    SEARCH_TERM_SELECTED: 'Recent Search Term Interaction',
    SEARCH_PERFORMED: 'Search Performed',
    SEARCH_RESULT_SELECTED: 'Search Result Selected',
    SPORTS_CAROUSEL_INTERACTION: 'Sports Carousel Interaction',
    SPORT_FILTER_INTERACTION: 'Sport Filter Interaction',
    SPORT_TABS_INTERACTION: 'Sport Tabs Interaction',
    STREAM_CLOSED: 'Stream Closed',
    STREAM_FULL_SCREEN: 'Stream Full-screen Interaction',
    STREAM_MUTE: 'Stream Muted',
    STREAM_VOLUME_CHANGED: 'Stream Volume Changed',
    STREAM_PLAY: 'Stream Play',
    STREAM_STARTED: 'Stream Started Playing',
    STREAM_UNMUTED: 'Stream Unmuted',
    TOP_SPORT_SHOW_ALL: 'Show All Top Sports Clicked',
    USER_INFO_OPEN: 'User Info Opened',
    USER_INFO_CLOSED: 'User Info Closed',
    USER_INFO_INTERACTION: 'User Info Item Interaction',
    UNEXPECTED_USER_SESSION_LOST: 'Unexpected user session lost',
    VIRTUAL_STREAM_WATCH: 'Virtual Stream Open/Play',
    VIRTUAL_EVENT_SELECTED: 'Virtual Event Selected',
    VIRTUAL_SPORT_SELECTED: 'Virtual Sport Selected',
    YOUR_BETS_TAB_INTERACTION: 'Your Bets Tab Interaction',
    YOUR_BETS_OPEN: 'Your Bets Open',
    TOP_LEAGUE_INTERACTION: 'Top League Interaction',
    MATCHES_FILTER_INTERACTION: 'Matches Filter Interaction',
    QUICK_START_GUIDE_OPENED_FROM_BANNER: 'Quick Start Guide opened from banner',
    QUICK_START_GUIDE_BANNER_CLOSED: 'Quick Start Guide banner closed',
    BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE: 'Betslip price type dropdown change',
};

const STATUS = {
    FAILED: 'Failed',
    SUCCESSFUL: 'Successful',
    INITIATED: 'Initiated',
};

/**
 * Google analytics 4 service.
 *
 * @class GoogleAnalytics4Service
 * @memberof Mojito.Services.Analytics
 * @param {string} googleAnalytics4MeasurementId - Google analytics 4 measurement id.
 * @extends Mojito.Services.Analytics.AbstractAnalyticsService
 */
export default class GoogleAnalytics4Service extends AbstractAnalyticsService {
    constructor(googleAnalytics4MeasurementId) {
        super('ga4');
        this.googleAnalytics4MeasurementId = googleAnalytics4MeasurementId;
        this.isInitialized = false;
    }

    activate() {
        const url = `${gTagUrl}?id=${this.googleAnalytics4MeasurementId}`;
        $script(url, () => {
            window.dataLayer = window.dataLayer || [];
            this.isInitialized = true;
            this.gtag('js', new Date());
            this.gtag('config', this.googleAnalytics4MeasurementId);
        });
    }

    gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
    }

    _safeCall(...args) {
        if (this.isInitialized) {
            this.gtag(...args);
        } else {
            log.warn('Google analytics 4 is not initialized');
        }
    }

    logEvent(event, paramsObj) {
        this._safeCall('event', event, paramsObj);
    }

    setUserId(username) {
        this._safeCall('set', { userId: username });
    }

    setPage(page) {
        this._safeCall('set', { page: page });
    }

    openLogin(loginButtonLocation) {
        this.logEvent(EVENTS.LOGIN_OPEN, {
            location: loginButtonLocation,
        });
    }

    loggedIn(username) {
        this.setUserId(username);
        this.logEvent(`${EVENTS.LOGIN} ${STATUS.SUCCESSFUL}`, {
            username,
        });
    }

    loginFailed(error) {
        this.logEvent(`${EVENTS.LOGIN} ${STATUS.FAILED}`, {
            error_message: error,
        });
    }

    loggedOut() {
        this.setUserId(undefined);
        this.logEvent(EVENTS.LOGOUT);
    }

    openUserInfo() {
        this.logEvent(EVENTS.USER_INFO_OPEN);
    }

    closeUserInfo() {
        this.logEvent(EVENTS.USER_INFO_CLOSED);
    }

    userInfoItemClicked({ itemLabel }) {
        this.logEvent(EVENTS.USER_INFO_INTERACTION, {
            selected_element: itemLabel,
        });
    }

    topSportClicked({ sportListName, sportType }) {
        this.logEvent(EVENTS.SPORT_FILTER_INTERACTION, {
            sport: sportType,
            location_id: sportListName,
        });
    }

    topSportShowAllClicked({ sportType, isInplay }) {
        this.logEvent(EVENTS.TOP_SPORT_SHOW_ALL, {
            sport: sportType,
            in_play: isInplay,
        });
    }

    eventsMarketFilterInteraction({ sportId, marketId }) {
        this.logEvent(EVENTS.MARKET_FILTER_INTERACTION, {
            sport: sportId,
            market_name: marketId,
        });
    }

    eventListMarketSelectorValueChanged({ sportId, marketId }) {
        this.eventsMarketFilterInteraction({ sportId, marketId });
    }

    homePageEventListMarketSelectorValueChanged({ sportId, marketId }) {
        this.eventsMarketFilterInteraction({ sportId, marketId });
    }

    marketGroupsShowHideToggleClicked(isShown) {
        this.logEvent(EVENTS.MARKET_GROUPS_SHOW_HIDE_TOGGLE_CLICKED, {
            show: isShown,
        });
    }

    marketGroupCategoryClicked(categoryId) {
        this.logEvent(EVENTS.MARKET_GROUP_CATEGORY_CLICKED, {
            categoryId: categoryId,
        });
    }

    eventListSelectedPeriodChanged({ periodName, sportId }) {
        this.logEvent(EVENTS.OTHER_FILTER_INTERACTION, {
            sport: sportId,
            market_name: periodName,
        });
    }

    sportsSideMenuOpenStateChanged(isOpened) {
        this.logEvent(isOpened ? EVENTS.A_Z_OPENED : EVENTS.A_Z_CLOSED);
    }

    sportsMenuSportClicked({ sportType }) {
        this.logEvent(EVENTS.A_Z_SPORT, {
            sport: sportType,
        });
    }

    sportsMenuExpandedStateChanged(isExpanded) {
        this.logEvent(EVENTS.A_Z_MORE, {
            element_id: isExpanded ? 'Show All Sports' : 'Hide All Sports',
        });
    }

    cmsQuickLinksClicked(linkLabel) {
        this.logEvent(EVENTS.QUICK_LINK_INTERACTION, {
            link_label: linkLabel,
        });
    }

    sportsCarouselLinkClicked(sportId) {
        this.logEvent(EVENTS.SPORTS_CAROUSEL_INTERACTION, {
            sport: sportId,
        });
    }

    matchSportMainNavigationClicked({ linkName, sportId }) {
        this.logEvent(EVENTS.SPORT_TABS_INTERACTION, {
            element_label: linkName,
            sport: sportId,
        });
    }

    racingMainNavigationClicked({ linkName }) {
        this.logEvent(EVENTS.RACING_TABS_NAVIGATION, {
            element_label: linkName,
        });
    }

    cmsCouponsClicked(label) {
        this.logEvent(EVENTS.COUPON_INTERACTION, {
            element_label: label,
        });
    }

    sportClassTypeClicked({ typeName, pageType }) {
        this.logEvent(EVENTS.LEAGUE_FILTER_INTERACTION, {
            element_label: typeName,
            page_type: pageType,
        });
    }

    quickLinkClicked(label) {
        this.logEvent(EVENTS.FOOTER_MOBILE_INTERACTION, {
            element_label: label,
        });
    }

    promotionClicked({ locationId, bannerName }) {
        this.logEvent(EVENTS.BANNER_INTERACTION, {
            location_id: locationId,
            banner_name: bannerName,
        });
    }

    navigationEvent({ name, type, sport }) {
        this.logEvent(EVENTS.EVENT_INTERACTION, {
            event_name: name,
            event_type: type,
            sport,
        });
    }

    marketGroupChanged({ sportId, marketGroupName, eventName }) {
        this.logEvent(EVENTS.MARKET_CATEGORIES_INTERACTION, {
            element_name: marketGroupName,
            sport: sportId,
            event_name: eventName,
        });
    }

    handicapMarketMenuChanged(id) {
        this.logEvent(EVENTS.HANDICAP_MARKET_TYPE_MENU_INTERACTION, {
            id,
        });
    }

    matchAccaAddSelection(
        selectionSource,
        selectionId,
        selectionPrice,
        eventName,
        selectionName,
        marketGroupName
    ) {
        this.logEvent(EVENTS.MATCH_ACCA_ADD_SELECTION, {
            selection_source: selectionSource,
            selection_name: selectionName,
            selection_id: selectionId,
            event_name: eventName,
            market_group_name: marketGroupName,
        });
    }

    removeMatchAccaSelection(selectionId, odds) {
        this.logEvent(EVENTS.MATCH_ACCA_REMOVE_SELECTION, {
            selection_id: selectionId,
            odds: odds,
        });
    }

    clearMatchAccaSlip() {
        this.logEvent(EVENTS.MATCH_ACCA_CLEARED);
    }

    matchAccaSlipOpen() {
        this.logEvent(EVENTS.MATCH_ACCA_OPENED);
    }

    matchAccaSlipClose() {
        this.logEvent(EVENTS.MATCH_ACCA_CLOSED);
    }

    addMatchAccaToBetslip(selectionPrice, numberOfSelections, balance) {
        this.logEvent(EVENTS.MATCH_ACCA_ADD_TO_BETSLIP, {
            price: selectionPrice,
            number_selections: numberOfSelections,
            balance,
        });
    }

    openBetslip(betslipType) {
        this.logEvent(EVENTS.BETSLIP_OPEN, { betslip_type: betslipType });
    }

    closeBetslip(betslipType) {
        this.logEvent(EVENTS.BETSLIP_CLOSE, { betslip_type: betslipType });
    }

    betslipStakeGroupChanged(stakeGroup, betslipMode) {
        this.logEvent(`${EVENTS.BETSLIP_TAB_CHANGE} - ${betslipMode} Betslip`, {
            element_name: stakeGroup,
        });
    }

    betslipTeaserTypeChanged(type) {
        this.logEvent(EVENTS.TEASER_TYPE_CHANGE, {
            element_name: type,
        });
    }

    betslipSettingsOpened(isQuickBetslip) {
        this.logEvent(isQuickBetslip ? EVENTS.QBS_SETTINGS : EVENTS.BETSLIP_SETTINGS);
    }

    betslipChangeAcceptAllPrices(isAcceptable) {
        this.logEvent(EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_ALL, {
            element:
                isAcceptable === 'Yes'
                    ? 'Accept All Price Changes Enabled'
                    : 'Accept All Price Changes Disabled',
        });
    }

    betslipChangeAcceptHigherPrices(isAcceptable) {
        this.logEvent(EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_HIGHER, {
            element:
                isAcceptable === 'Yes'
                    ? 'Accept Higher Price Changes Enabled'
                    : 'Accept Higher Price Changes Disabled',
        });
    }

    betslipChangeAcceptLowerPrices(isAcceptable) {
        this.logEvent(EVENTS.BETSLIP_AUTOMATIC_PRICE_CHANGE_LOWER, {
            element:
                isAcceptable === 'Yes'
                    ? 'Accept Lower Price Changes Enabled'
                    : 'Accept Lower Price Changes Disabled',
        });
    }

    addFreebetVoucherSuccess(voucherId, voucherName) {
        this.logEvent(EVENTS.BETSLIP_FREEBET_ADD, {
            element_id: voucherId,
            element_name: voucherName,
            value: 'Free Bet Enabled',
        });
    }

    addFreebetVoucherFailure({ code, error }) {
        this.logEvent(`${EVENTS.BETSLIP_FREEBET_ADD} ${STATUS.FAILED}`, {
            element_id: code,
            error,
        });
    }

    removeFreebetVoucherSuccess(voucherId) {
        this.logEvent(EVENTS.BETSLIP_FREEBET_REMOVE, {
            element_id: voucherId,
            value: 'Free Bet Disabled',
        });
    }

    toggleShowStakePerBetForSingles(showStake) {
        this.logEvent(EVENTS.BETSLIP_STAKE_PER_BET, {
            element: showStake ? 'Show Stake Per Bet Enabled' : 'Show Stake Per Bet Disabled',
        });
    }

    toggleEachWay(isEachWay) {
        this.logEvent(EVENTS.BETSLIP_EACH_WAY, {
            element: isEachWay ? 'Each Way Enabled' : 'Each Way Disabled',
        });
    }

    customKeypadKeyClicked({ value }) {
        this.logEvent(EVENTS.BETSLIP_KEYPAD_INCREMENT, {
            value,
        });
    }

    removeAllInBetslip(betslipSelectionCount) {
        this.logEvent(EVENTS.BETSLIP_REMOVE_ALL, {
            number_selections: betslipSelectionCount,
        });
    }

    selectionAddedToBetslip({
        marketId,
        marketName,
        selectionId,
        selectionLabel,
        selectionPrice,
        selectionSource,
        eventId,
        eventName,
        selectionType,
    }) {
        const availableSelectionType = selectionType ? { selection_type: selectionType } : {};
        this.logEvent(EVENTS.SELECTION_ADD, {
            selection_id: selectionId,
            selection_label: selectionLabel,
            price: selectionPrice,
            location_id: selectionSource,
            market_id: marketId,
            market_name: marketName,
            event_id: eventId,
            event_name: eventName,
            ...availableSelectionType,
        });
    }

    selectionRemovedFromBetslip({
        selectionPrice,
        selectionLabel,
        selectionSource,
        marketName,
        eventName,
    }) {
        this.logEvent(EVENTS.SELECTION_REMOVE, {
            selection_name: selectionLabel,
            selection_source: selectionSource,
            price: selectionPrice,
            market_name: marketName,
            event_name: eventName,
        });
    }

    betPlaced({
        totalStake,
        betType,
        selectionCount,
        odds,
        betslipType,
        freebetStake,
        accaBoost,
        buildType,
        baseOdds,
    }) {
        this.logEvent(`${EVENTS.PLACE_BET} ${STATUS.SUCCESSFUL}`, {
            total_price: odds,
            bet_type: betType,
            total_stake: totalStake,
            number_selections: selectionCount,
            betslip_type: betslipType,
            freebet_stake: freebetStake,
            acca_boost: accaBoost,
            build_type: buildType,
            base_price: baseOdds,
        });
    }

    betslipRequestFailed(betslipType) {
        this.logEvent(EVENTS.BETSLIP_REQUEST_FAILED, {
            betslip_type: betslipType,
        });
    }

    streamFullScreenChanged(isFullScreen, eventName) {
        this.logEvent(EVENTS.STREAM_FULL_SCREEN, { is_fullscreen: isFullScreen, event: eventName });
    }

    playStream(eventName) {
        this.logEvent(EVENTS.STREAM_PLAY, { event: eventName });
    }

    streamFailToPlay(reason) {
        this.logEvent(`${EVENTS.STREAM_PLAY} ${STATUS.FAILED}`, {
            error: reason,
        });
    }

    closeStream(eventName) {
        this.logEvent(EVENTS.STREAM_CLOSED, { event: eventName });
    }

    streamPlaying(eventName) {
        this.logEvent(EVENTS.STREAM_STARTED, { event: eventName });
    }

    oddsFormatChanged(oldOddsFormat, newOddsFormat) {
        this.logEvent(EVENTS.SETTINGS_ODDS_FORMAT, {
            old_odds_format: oldOddsFormat,
            new_odds_format: newOddsFormat,
        });
    }

    streamMute(eventName) {
        this.logEvent(EVENTS.STREAM_MUTE, { event: eventName });
    }

    streamUnmute(eventName) {
        this.logEvent(EVENTS.STREAM_UNMUTED, { event: eventName });
    }

    streamVolumeChanged({ eventName, volume }) {
        this.logEvent(EVENTS.STREAM_VOLUME_CHANGED, { event: eventName, volume });
    }

    competitionToggleExpandable({ competitionName, sport }) {
        this.logEvent(EVENTS.COMPETITION_COLLAPSE_OR_EXPAND_INTERACTION, {
            competition_name: competitionName,
            sport,
        });
    }

    marketToggleExpandable(data) {
        const { marketName, eventName, competitionName, sport } = data;

        this.logEvent(EVENTS.MARKET_COLLAPSE_OR_EXPAND_INTERACTION, {
            market_name: marketName,
            event_name: eventName,
            competition_name: competitionName,
            sport,
        });
    }

    backClicked() {
        this.logEvent(EVENTS.BACK_BUTTON_INTERACTION);
    }

    breadcrumbsClicked({ linkLevel, linkName }) {
        this.logEvent(EVENTS.BREADCRUMB_INTERACTION, {
            link_level: linkLevel,
            link_name: linkName,
        });
    }

    settingsTimeZone(newOffset) {
        this.logEvent(EVENTS.SETTINGS_TIME_ZONE, {
            new_time_zone: newOffset,
        });
    }

    languageChanged(oldLanguage, newLanguage) {
        this.logEvent(EVENTS.SETTINGS_LANGUAGE, {
            old_language: oldLanguage,
            new_language: newLanguage,
        });
    }

    betHistoryOnDetailsClick({ action, betStatus }) {
        if (betStatus) {
            this.logEvent(EVENTS.BET_HISTORY_BET_DETAIL_INTERACTION, {
                click_action: action,
                bet_status: betStatus,
            });
        }
    }

    betHistoryRangeFilterChange(rangeFilter) {
        this.logEvent(EVENTS.BET_HISTORY_BET_TIME_PERIOD_INTERACTION, {
            range_filter: rangeFilter,
        });
    }

    betHistoryStatusChange(status) {
        this.logEvent(EVENTS.BET_HISTORY_BET_FILTER_INTERACTION, {
            filter_status: status,
        });
    }

    cashedOut(status, cashoutValue, betId) {
        this.logEvent(EVENTS.CASH_OUT_STATUS, {
            cash_out_status: status === 'ok' ? STATUS.SUCCESSFUL : STATUS.FAILED,
            cash_out_value: cashoutValue,
            bet_id: betId,
        });
    }

    cashoutConfirmed(cashoutValue, betId) {
        this.logEvent(EVENTS.CASH_OUT_CONFIRM, {
            cash_out_value: cashoutValue,
            bet_id: betId,
        });
    }

    initCashOut({ betId, cashoutValue, numberSelections, betType, betStatus }) {
        this.logEvent(EVENTS.CASH_OUT_INTERACTION, {
            bet_id: betId,
            total_price: cashoutValue,
            number_selections: numberSelections,
            bet_type: betType,
            bet_status: betStatus,
        });
    }

    yourBetsSubTabClicked(tab) {
        this.logEvent(EVENTS.YOUR_BETS_TAB_INTERACTION, {
            tab_name: tab,
        });
    }

    betslipTabChanged(selectedTab, openBetsCount) {
        this.logEvent(EVENTS.YOUR_BETS_OPEN, {
            tab_name: selectedTab,
            number_open_bets: openBetsCount,
        });
    }

    retainSelection({ numberSelections }) {
        this.logEvent(EVENTS.BET_RECEIPT_RETAIN_SELECTIONS, {
            number_selections: numberSelections,
        });
    }

    clearSelection({ numberSelections }) {
        this.logEvent(EVENTS.BET_RECEIPT_CLEAR_SELECTIONS, {
            number_selections: numberSelections,
        });
    }

    betReceiptShown() {
        this.logEvent(EVENTS.BET_RECEIPT_SHOWN);
    }

    quickBetslipStakeBoxInteraction() {
        this.logEvent(EVENTS.QBS_STAKE_BOX);
    }

    quickBetslipToFull() {
        this.logEvent(EVENTS.QBS_OPEN_FULL);
    }

    eventCardsCarouselSwiped() {
        this.logEvent(EVENTS.CARD_SWIPE);
    }

    searchInputFocused() {
        this.logEvent(EVENTS.SEARCH_INPUT_FOCUSED);
    }

    searchPerformed(searchString, numberOfResults, hasMoreResults) {
        this.logEvent(EVENTS.SEARCH_PERFORMED, {
            keyword_term: searchString,
            number_results: numberOfResults,
            has_more_results: hasMoreResults,
        });
    }

    searchInputCleared() {
        this.logEvent(EVENTS.SEARCH_CLEARED);
    }

    searchHistoryItemRemoved(searchString) {
        this.logEvent(EVENTS.SEARCH_TERM_DELETE, {
            keyword_term: searchString,
        });
    }

    searchHistoryItemClicked(searchString) {
        this.logEvent(EVENTS.SEARCH_TERM_SELECTED, {
            keyword_term: searchString,
        });
    }

    searchResultsItemClicked({ sportId, name, parentName, resultType }) {
        this.logEvent(EVENTS.SEARCH_RESULT_SELECTED, {
            sport: sportId,
            event_name: name,
            parent_name: parentName,
            result_type: resultType,
        });
    }

    leaguesQuickNavigationOpened() {
        this.logEvent(EVENTS.LEAGUES_NAVIGATION_MENU_OPENED);
    }

    leaguesQuickNavigationClosed() {
        this.logEvent(EVENTS.LEAGUES_NAVIGATION_MENU_CLOSED);
    }

    eventsQuickNavigationOpened({ eventName, eventType, sportId, isInPlay }) {
        this.logEvent(EVENTS.EVENTS_NAVIGATION_MENU_OPENED, {
            sport: sportId,
            event_name: eventName,
            type_name: eventType,
            event_is_inPlay: isInPlay ? 'Yes' : 'No',
        });
    }

    eventsQuickNavigationClosed() {
        this.logEvent(EVENTS.EVENTS_NAVIGATION_MENU_CLOSED);
    }

    acceptChanges(betslipType) {
        this.logEvent(EVENTS.BETSLIP_CHANGES_ACCEPTED, {
            betslip_type: betslipType,
        });
    }

    placingBet({ betslipType }) {
        this.logEvent(`${EVENTS.PLACE_BET} ${STATUS.INITIATED}`, {
            betslip_type: betslipType,
        });
    }

    customKeypadShown(isShown = true) {
        const event = isShown ? EVENTS.BETSLIP_KEYPAD_SHOWN : EVENTS.BETSLIP_KEYPAD_HIDDEN;
        this.logEvent(event);
    }

    inPlaySideMenuItemClicked() {
        this.logEvent(EVENTS.IN_PLAY_SIDE_MENU_ITEM_CLICK);
    }

    virtualStreamingWatchButtonClicked(eventName) {
        this.logEvent(EVENTS.VIRTUAL_STREAM_WATCH, { event: eventName });
    }

    virtualSportsSelectedEventChanged(eventName) {
        this.logEvent(EVENTS.VIRTUAL_EVENT_SELECTED, { event: eventName });
    }

    virtualSportsSelectedSportChanged(sportName) {
        this.logEvent(EVENTS.VIRTUAL_SPORT_SELECTED, { sport: sportName });
    }

    logoClicked() {
        this.logEvent(EVENTS.LOGO);
    }

    changeRaceCard(raceName) {
        this.logEvent(EVENTS.NEXT_RACES_RACE_CARD_CHANGE, { race: raceName });
    }

    eventCardClicked(eventName) {
        this.logEvent(EVENTS.EVENT_CARD_CLICKED, { event: eventName });
    }

    viewFullRace({ raceName, status }) {
        this.logEvent(EVENTS.NEXT_RACES_RACE_CARD_CLICK, { race: raceName, status });
    }

    selectRace({ raceName, status }) {
        this.logEvent(EVENTS.RACE_CARD_SELECT_RACE, { race: raceName, status });
    }

    selectMeeting(meetingName) {
        this.logEvent(EVENTS.RACE_CARD_SELECT_MEETING, { meeting: meetingName });
    }

    sortRunners(sortType) {
        this.logEvent(EVENTS.RACE_CARD_SORT_RUNNERS, { sort_type: sortType });
    }

    selectLocation(location) {
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_REGION, { location });
    }

    selectRaceOnHighlightsCoupon({ raceName, status }) {
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_RACE, { race: raceName, status });
    }

    selectMeetingOnHighlightsCoupon(meetingName) {
        this.logEvent(EVENTS.HIGHLIGHT_MEETINGS_SELECT_MEETING, { meeting: meetingName });
    }

    changeMeetingsDay({ day, location }) {
        this.logEvent(EVENTS.RACE_MEETINGS_DAY_CHANGED, { day, location });
    }

    selectRaceOnMeetingsCoupon({ raceName, status }) {
        this.logEvent(EVENTS.RACE_MEETINGS_SELECT_RACE, { race: raceName, status });
    }

    selectMeetingOnMeetingsCoupon(meetingName) {
        this.logEvent(EVENTS.RACE_MEETINGS_SELECT_MEETING, { meeting: meetingName });
    }

    selectRaceOnFutureCoupon(raceName) {
        this.logEvent(EVENTS.FUTURE_RACING_COUPON_SELECTED, { race: raceName });
    }

    betHistoryPrint() {
        this.logEvent(EVENTS.BET_HISTORY_PRINT);
    }

    myAccountViewChange(tab) {
        this.logEvent(EVENTS.MY_ACCOUNT_TAB_CLICKED, { tab });
    }

    changeMarketRaceCard(racingMarket) {
        const market = RACING_MARKET_TYPES[racingMarket];
        this.logEvent(EVENTS.RACE_CARD_MARKET_CHANGED, { market });
    }

    bonusesTabChanged(tab) {
        this.logEvent(EVENTS.BONUSES_TAB_INTERACTION, { tab });
    }

    addSportToFavourites(sportId) {
        this.logEvent(EVENTS.FAVOURITES_SPORT_ADDED, { sport: sportId });
    }

    removeSportFromFavourites(sportId) {
        this.logEvent(EVENTS.FAVOURITES_SPORT_REMOVED, { sport: sportId });
    }

    bankersSwitchToggled(value) {
        const newValue = value ? 'On' : 'Off';
        this.logEvent(EVENTS.BANKERS_SWITCH_TOGGLED, { value: newValue });
    }

    bankersBetToggled(betId, value) {
        const newValue = value ? 'On' : 'Off';
        this.logEvent(EVENTS.BANKERS_BET_TOGGLED, { bet_id: betId, value: newValue });
    }

    notificationsSubscriberClick() {
        this.logEvent(EVENTS.NOTIFICATION_CLICKED);
    }

    toggleNotifications({ source, value }) {
        const label = value ? 'On' : 'Off';
        this.logEvent(EVENTS.PUSH_NOTIFICATIONS_SWITCH, { source, label });
    }

    topLeagueClick(typeId) {
        this.logEvent(EVENTS.TOP_LEAGUE_INTERACTION, { typeId });
    }

    matchesFilterClicked({ selectedFilterKey, sport }) {
        this.logEvent(EVENTS.MATCHES_FILTER_INTERACTION, { selectedFilterKey, sport });
    }

    quickStartGuideOpenedFromBanner() {
        this.logEvent(EVENTS.QUICK_START_GUIDE_OPENED_FROM_BANNER);
    }

    quickStartGuideBannerClosed() {
        this.logEvent(EVENTS.QUICK_START_GUIDE_BANNER_CLOSED);
    }

    unexpectedSessionLost() {
        this.logEvent(EVENTS.UNEXPECTED_USER_SESSION_LOST);
    }

    navigation(page) {
        this.setPage(page);
    }

    betslipPriceTypeDropdownChange(sportId) {
        this.logEvent(EVENTS.BETSLIP_PRICE_TYPE_DROPDOWN_CHANGE, { sport: sportId });
    }
}
