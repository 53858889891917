import MojitoCore from 'mojito/core';
import MojitoPresentation from 'mojito/presentation';
import { useDispatch } from 'react-redux';
import withEventCardSkeleton from 'modules/event-cards/skeleton/with-event-card-skeleton.js';

const intentActions = MojitoCore.Intents.actions;
const IntentTypes = MojitoPresentation.Base.Intent.Types;

const { FlexPane, Image, Button } = MojitoPresentation.Components;

const BannerCard = withEventCardSkeleton(
    props => {
        const dispatch = useDispatch();
        const { mojitoTools, imgUrl, targetUrl, openInNewTab, onLoadDone, elementRef } = props;
        const { config } = mojitoTools;
        const onClick = () =>
            dispatch(
                intentActions.publishIntent(IntentTypes.OPEN_URL, {
                    url: targetUrl,
                    ...(openInNewTab && { windowName: '' }),
                })
            );

        return (
            <FlexPane
                class="ta-BannerCard"
                config={config.eventCard.container}
                elementRef={elementRef}
            >
                <Button
                    onClick={onClick}
                    hrefLink={targetUrl}
                    config={config.eventCard.clickableArea}
                >
                    <Image
                        src={imgUrl}
                        config={config.bannerImage}
                        onLoad={onLoadDone}
                        onError={onLoadDone}
                    />
                </Button>
            </FlexPane>
        );
    },
    () => false,
    config => config.eventCard.skeletonLoader
);

export default BannerCard;
