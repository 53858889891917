import MojitoCore from 'mojito/core';
import SportPageImpl from './sport-page.jsx';

const { UIPage } = MojitoCore.Presentation;

/**
 * @namespace SportPage
 * @memberof Mojito.Application.Pages
 */

/**
 * Page that displays sports.
 *
 * @class SportPage
 * @memberof Mojito.Application.Pages.SportPage
 */
export default UIPage('SportPage', SportPageImpl);
