import PropTypes from 'prop-types';
import RaceMeetingCouponImpl from './race-meeting-coupon.jsx';
import MojitoCore from 'mojito/core';
import { noop } from 'mojito/utils';

const UIView = MojitoCore.Presentation.UIView;

/**
 * The RaceMeetingCoupon is used to render a race meeting coupon.
 * It can group more than one meeting in one coupon, representing one meeting.
 * This is useful when changing local time zones, and two meetings for the same track now start on the same day.
 *
 * @class RaceMeetingCoupon
 * @memberof Mojito.Presentation.Components
 */

/**
 * RaceMeetingCoupon prop types.
 *
 * @property {string} [meetingName = ''] - Name of the meeting.
 * @property {string} [trackStatus = ''] - Status of the track.
 * @property {string} meetingId - Meeting id.
 * @property {Array} races - An array of races.
 * @property {boolean} [showOnlyNextRace = false] - Flag indicating whether to display only the next race (true to show only the next race).
 * @property {boolean} [allowBestOddsGuaranteed = false] - Flag indicating signposting icon for best odds guaranteed.
 * @property {Function} [cbRaceClicked = ()=>{}] - Callback function triggered when a race is clicked.
 * @property {boolean} [streamAvailable = false] - Flag signifying whether a live stream is available for a race meeting (true if a stream is available).
 *
 * @memberof Mojito.Presentation.Components.RaceMeetingCoupon
 */
const propTypes = {
    meetingName: PropTypes.string,
    trackStatus: PropTypes.string,
    meetingId: PropTypes.string.isRequired,
    races: PropTypes.array.isRequired,
    showOnlyNextRace: PropTypes.bool,
    allowBestOddsGuaranteed: PropTypes.bool,
    cbRaceClicked: PropTypes.func,
    streamAvailable: PropTypes.bool,
};

const defaultProps = {
    meetingName: '',
    trackStatus: '',
    showOnlyNextRace: false,
    allowBestOddsGuaranteed: false,
    cbRaceClicked: noop,
    streamAvailable: false,
};

export default UIView('RaceMeetingCoupon', RaceMeetingCouponImpl, propTypes, defaultProps);
