import PropTypes from 'prop-types';
import RaceExplanationPaneImpl from './race-explanation-pane.jsx';
import MojitoCore from 'mojito/core';

/**
 * Race explanation pane component. It is used to show the race icons and explanation text about those icons.
 *
 * @class RaceExplanationPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * RaceExplanationPane prop types.
 *
 * @property {boolean} [showBestOddsGuaranteed] - To show/hide BOG (Best Odds Guaranteed) icons from explanation pane.
 *
 * @memberof Mojito.Presentation.Components.RaceExplanationPane
 */
const propTypes = {
    showBestOddsGuaranteed: PropTypes.bool,
};

export default MojitoCore.Presentation.UIView(
    'RaceExplanationPane',
    RaceExplanationPaneImpl,
    propTypes
);
