/* eslint-disable react/no-array-index-key */
import MojitoPresentation from 'mojito/presentation';
import { range } from 'mojito/utils';
import { useParams } from 'react-router-dom';

const { Text, FlexPane, SkeletonPart } = MojitoPresentation.Components;
const { RacingHelper } = MojitoPresentation.Utils;

const SportGroupsListSkeleton = props => {
    const {
        mojitoTools: { config },
        headerText,
        isTopSportGroups,
    } = props;
    const {
        topSportGroups: { show: showTopSportGroupsSkeleton },
        allSportGroups: { show: showAllSportGroupsSkeleton },
    } = config;
    let leaguesToRender;

    if (showTopSportGroupsSkeleton && isTopSportGroups) {
        leaguesToRender = <TopLeagues config={config} headerText={headerText} />;
    } else if (showAllSportGroupsSkeleton && !isTopSportGroups) {
        leaguesToRender = <AllLeagues config={config} headerText={headerText} />;
    }

    return (
        <FlexPane config={config.container} class="ta-SportGroupsListSkeleton">
            {leaguesToRender}
        </FlexPane>
    );
};

const TopLeagues = ({ config, headerText }) => {
    const {
        topSportGroups: { numberOfSportGroups },
    } = config;

    return (
        <>
            <Header config={config} headerText={headerText} />
            <FlexPane config={config.listContainer} class="ta-TopSportGroupsListSkeleton">
                {range(numberOfSportGroups).map((_, index) => (
                    <SkeletonPart key={index} config={config.leaguePlaceholder} /> // NOSONAR - The array index is used as a simple placeholder key.
                ))}
            </FlexPane>
        </>
    );
};

const AllLeagues = ({ config, headerText }) => {
    const { sportId } = useParams();
    const {
        allSportGroups: { numberOfRegions, numberOfSportGroups },
    } = config;

    return (
        <>
            {!RacingHelper.isRacingSport(sportId) && (
                <Header config={config} headerText={headerText} />
            )}
            <FlexPane config={config.leaguesContainer} class="ta-AllSportGroupsListSkeleton">
                {range(numberOfRegions).map(index => (
                    <FlexPane key={index} config={config.listContainer}>
                        {/* NOSONAR - The array index is used as a simple placeholder key. */}
                        <FlexPane config={config.regionContainer}>
                            <SkeletonPart config={config.iconPlaceholder} />
                            <SkeletonPart config={config.regionPlaceholder} />
                        </FlexPane>
                        {range(numberOfSportGroups).map((_, idx) => (
                            <SkeletonPart key={idx} config={config.leaguePlaceholder} /> // NOSONAR - The array index is used as a simple placeholder key.
                        ))}
                    </FlexPane>
                ))}
            </FlexPane>
        </>
    );
};

const Header = ({ config, headerText }) => (
    <FlexPane config={config.headerContainer}>
        <Text config={config.headerText}>{headerText}</Text>
    </FlexPane>
);

export default SportGroupsListSkeleton;
