import MojitoPresentation from 'mojito/presentation';
import RaceCouponsSkeleton from 'modules/race-coupons/skeleton/index.jsx';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import MojitoServices from 'mojito/services';

const { RACE_STATUS } = MojitoServices.SportsContent.Events.types;
const { CollapsibleMeetingPane, FlexPane, LoaderSuspense } = MojitoPresentation.Components;
const { RacingHelper } = MojitoPresentation.Utils;
const EXPAND_ALL_MEETINGS = -1;

const resolveInitiallyExpandedMeetings = ({
    initiallyExpandedMeetings,
    meetingsForLocations = [],
}) => {
    const numberOfInitiallyExpandedMeetings =
        initiallyExpandedMeetings === EXPAND_ALL_MEETINGS
            ? meetingsForLocations.length
            : initiallyExpandedMeetings;
    return meetingsForLocations.slice(0, numberOfInitiallyExpandedMeetings);
};

function renderMeetings(props, config, selectedLocationId, selectedElementRef) {
    const expandedMeetings = resolveInitiallyExpandedMeetings(props);

    return props.meetingsForLocations.map(meetingsForLocation => {
        const selectedMeeting = props.selectedFilter || meetingsForLocation.selected;

        const meetings = meetingsForLocation[selectedMeeting]
            ?.map(id => props?.meetings[id])
            .filter(Boolean);

        if (!meetings?.length) {
            return null;
        }

        const { locationId } = meetingsForLocation;
        const { className } = meetings[0];
        const hasBestOddsGuaranteed = RacingHelper.hasBestOddsGuaranteed(meetings);
        const showBestOddsGuaranteed = props.allowBestOddsGuaranteed && hasBestOddsGuaranteed;

        const meetingsWithRaceStatus = meetings.map(meeting => {
            const { races } = meeting;
            const raceTrackStatus =
                races.find(race => race.status === RACE_STATUS.UPCOMING) || races[races.length - 1];

            return { ...meeting, raceTrackStatus };
        });

        return (
            <div
                id={locationId}
                ref={element => {
                    if (locationId === selectedLocationId) {
                        selectedElementRef.current = element;
                    }
                }}
                key={locationId}
            >
                <FlexPane
                    key={meetingsForLocation.locationId}
                    class="ta-RaceCouponsView-location"
                    config={config.couponContainer}
                >
                    <CollapsibleMeetingPane
                        countryName={className}
                        countryIcon={meetingsForLocation.countryCode}
                        sportId={props.sportId}
                        meetings={meetingsWithRaceStatus}
                        initiallyExpanded={expandedMeetings.includes(meetingsForLocation)}
                        filters={props.filters?.filter(
                            ({ key }) => meetingsForLocation[key]?.length > 0
                        )}
                        selectedFilter={meetingsForLocation.selected}
                        filterClickedCb={selectedFilter =>
                            props.onFilterClicked(selectedFilter, meetingsForLocation.locationId)
                        }
                        onRaceClicked={(meetingId, raceId) =>
                            props.onRaceClicked(meetingId, raceId)
                        }
                        allowBestOddsGuaranteed={showBestOddsGuaranteed}
                        config={config.collapsibleMeetingPane}
                    />
                </FlexPane>
            </div>
        );
    });
}

function RaceCouponsView(props) {
    const {
        mojitoTools: { config },
        isContentPending,
        meetingsForLocations,
    } = props;

    const selectedElementRef = useRef();
    const location = useLocation();

    const selectedLocationId = location.hash?.slice(1);
    const [loadingAnimationDone, setLoadingAnimationDone] = useState(false);

    useEffect(() => {
        if (selectedLocationId && loadingAnimationDone) {
            const selectedLocationElement = selectedElementRef.current;
            selectedLocationElement?.scrollIntoView();
        }
    }, [selectedLocationId, loadingAnimationDone]);

    const onShowContent = () => {
        setLoadingAnimationDone(true);
    };

    const skeleton = <RaceCouponsSkeleton config={config.raceCouponsSkeleton} />;

    return (
        <LoaderSuspense
            config={config.skeletonLoader}
            isContentPending={isContentPending}
            loader={skeleton}
            onShowContent={onShowContent}
        >
            {meetingsForLocations.length > 0 && (
                <FlexPane config={config.mainContainer}>
                    {renderMeetings(props, config, selectedLocationId, selectedElementRef)}
                </FlexPane>
            )}
        </LoaderSuspense>
    );
}
export default RaceCouponsView;
