import React from 'react';
import {RouterProvider} from 'react-router-dom';

import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {WidgetsProviderService} from '#core/application/widgets-provider/index.js';
import {mergeToHierarchy, ensureHierarchy} from '#core/utils/config-utils.js';

import timerView from './views/timer/index.jsx';
import clockView from './views/clock/index.jsx';
import sessionTimeView from './views/sessiontime/index.jsx';
import {FAKE_DATE, getISODate} from './utils.js';
import {FakeContentNotificationsService} from './services/content-notifications';
import {FakeContentTransporter} from './transporter/fake-content-transporter.js';

const {actionsRegistry} = MojitoCore.Services.Content;

const WIDGET_NAME = 'mockup-playground';
const ROOT_PATH = 'playground';

class MockupPlaygroundFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        if (process.env.NODE_ENV === 'development') {
            this._enabled = true;

            WidgetsProviderService.registerWidget(WIDGET_NAME, ROOT_PATH, null, {
                // tmp
                isMockupPage: true,
                noAuthenticationService: true,
                noBaseUrl: true,
            });
        }
    }

    get name() {
        return 'MockupPlayground';
    }

    get enabled() {
        return this._enabled;
    }

    async beforeMojito() {
        if (process.env.NODE_ENV === 'development') {
            const {RouterResolver} = await import(/* webpackMode: "eager" */ './router-resolver');
            await RouterResolver.init(ROOT_PATH);

            WidgetsProviderService.updateWidgetComponent(WIDGET_NAME, () =>
                React.createElement(RouterProvider, {router: RouterResolver.browserRouter})
            );

            if (WidgetsProviderService.getWidgetData().isMockupPage) {
                // Is current url is mockup page ?
                // eslint-disable-next-line no-global-assign
                Date = class extends Date {
                    constructor(options) {
                        if (options) {
                            super(options);
                        } else {
                            super(FAKE_DATE);
                        }
                    }

                    static now() {
                        const mockupDate = new Date(FAKE_DATE);
                        return mockupDate.getTime();
                    }
                };

                {
                    // Store login time, but without actual login and without calling loginSuccess chain
                    const storageService = MojitoCore.Services.Storage.service;
                    const AuthenticationTypes = MojitoServices.Authentication.types;
                    storageService.setItem(AuthenticationTypes.LOGIN_TIME_STORAGE_KEY, getISODate());
                }
            }
        }

        return super.beforeMojito();
    }

    afterMojitoConfigBuild(mojitoConfig) {
        if (WidgetsProviderService.getWidgetData().isMockupPage) {
            const viewImplementations = ensureHierarchy(mojitoConfig, 'services.viewImplementations');
            const mojitoCore = ensureHierarchy(mojitoConfig, 'core');

            viewImplementations.Timer = {
                implementation: timerView,
            };
            viewImplementations.Clock = {
                implementation: clockView,
            };
            viewImplementations.SessionTime = {
                implementation: sessionTimeView,
            };

            // use FakeTransporter to prevent creation of unnecessary ws connections
            mojitoCore.contentTransporter = new FakeContentTransporter({});

            // need to remove all actions subscriptions to not breaking our store manipulations approach
            actionsRegistry.subscribeActions = {};
            actionsRegistry.unsubscribeActions = {};

            mergeToHierarchy(mojitoConfig, 'services.contentNotificationsService', {
                service: new FakeContentNotificationsService(),
            });

            mergeToHierarchy(mojitoConfig, 'services.configurations.ToastView', {
                showTimeout: '5s',
            });

            mergeToHierarchy(mojitoConfig, 'services.configurations.PageSplitLayout', {
                secondary: {
                    show: false, // to be able to mock EventPage without the left sidebar
                },
            });

            mergeToHierarchy(mojitoConfig, 'services.configurations.LoaderSuspense', {
                disableLoader: true,
            });

            mergeToHierarchy(mojitoConfig, 'services.configurations.SkeletonPart', {
                // disable skeleton animation
                style: {
                    animationDuration: 0,
                    animationIterationCount: 1,
                },
            });

            mergeToHierarchy(mojitoConfig, 'services.configurations.BetHistoryView', {
                delayBeforeShowingLoader: 1, // any value except convertable to false just to turn off loading for BetHistory in playground
            });
        }

        super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const MockupPlayground = new MockupPlaygroundFeature(allFeatures);
