/**
 * Race coupons types.
 *
 * @class RaceCouponsTypes
 * @memberof Mojito.Modules.RaceCoupons
 */
export default class RaceCouponsTypes {}

/**
 * Defines the filter types used for selecting race coupons.
 * The filters can assist in narrowing down coupons based on time such as for 'today' or for 'tomorrow'.
 *
 * @property {string} TODAY - Represents a filter that selects coupons scheduled for today.
 * @property {string} TOMORROW - Represents a filter that selects coupons scheduled for tomorrow.
 * @property {string} STREAMING - Represents a filter that selects coupons in events with support for streaming.
 * @property {string} DAY - Represents a filter that selects coupons taking place at the given day offset.
 *
 * @memberof Mojito.Modules.RaceCoupons.RaceCouponsTypes
 */
RaceCouponsTypes.FILTER = {
    TODAY: 'today',
    TOMORROW: 'tomorrow',
    STREAMING: 'streaming',
    DAY: 'day',
};

/**
 * Defines the view type for displaying race coupons.
 * These view types include 'meetings' and 'highlights'.
 *
 * @typedef VIEW_TYPE
 *
 * @property {string} MEETINGS - Represents a view that displays a list of meetings.
 * @property {string} HIGHLIGHTS - Represents a view that displays highlights from meetings.
 *
 * @memberof Mojito.Modules.RaceCoupons.RaceCouponsTypes
 */
RaceCouponsTypes.VIEW_TYPE = {
    MEETINGS: 'meetings',
    HIGHLIGHTS: 'highlights',
};
