import React, {useMemo} from 'react';

import {Localization} from '#core/localization.js';
import {constructTilePalette} from '#core/application/modules/tiles-cache.js';
import {BasicPalette} from '#core/application/modules/basic-palette.js';

// eslint-disable-next-line react/prop-types
const Message = ({message}) => {
    const messageStyle = useMemo(() => {
        const emptyContentMessageTile = constructTilePalette(
            'EMPTY_CONTENT_MESSAGE',
            null,
            BasicPalette.palette.DEFAULT_CONTAINER_BG
        );

        return {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 64,
            fontFamily: emptyContentMessageTile.FONT.FAMILY,
            ...emptyContentMessageTile.EMPTY_CONTENT_MESSAGE_TEXT,
        };
    }, []);

    return <div style={messageStyle}>{message}</div>;
};

export const NotAuthenticatedMessage = () => (
    <Message message={Localization.getTranslation('$DBX.COMMON.NOT_AUTHENTICATED')} />
);

export const BonusesAreLoadingMessage = () => (
    <Message message={Localization.getTranslation('$BONUSES.BONUSES_ARE_LOADING')} />
);
