/**
 * The enumerated types representing available sliding views in the application.
 *
 * @enum {string}
 * @readonly
 * @memberof Mojito.Application.AppViewTypes
 */
export const SLIDING_VIEW_TYPES = {
    BETSLIP: 'betslip',
    OPEN_BETS: 'open-bets',
    AZ_MENU: 'az-menu',
    GAMIFICATION: 'gamification',
    QUICK_EVENTS_NAVIGATION: 'quick-events-navigation',
    QUICK_LEAGUES_NAVIGATION: 'quick-leagues-navigation',
    QUICK_MEETINGS_NAVIGATION: 'quick-meetings-navigation',
    FOREIGN_WINDOW: 'foreign-window',
    MY_ACCOUNT: 'my-account',
};
