import {registerTileSchema} from '#core/application/schema-registry.js';
import {resolveBackground} from '#core/utils/color-utils.js';

import PROGRESS_BAR from './progress-bar.palette-schema.yaml';

registerTileSchema(PROGRESS_BAR);

export function generateProgressBar(PALETTE) {
    const {FONT} = PALETTE;
    return {
        progressLineBackground: {
            style: {
                ...resolveBackground(PALETTE.PROGRESS_BAR_LINE_COLOR),
                borderRadius: '4px', //as it's 4px for all clients
            },
        },
        progressLine: {
            style: {
                ...resolveBackground(PALETTE.PROGRESS_BAR_FILL_LINE_COLOR),
                borderRadius: '4px', //as it's 4px for all clients
            },
        },
        rangeLabel: {
            style: {
                fontSize: FONT.SIZE_XSMALL,
                ...PALETTE.PROGRESS_BAR_RANGE_LABEL_TEXT,
            },
        },
        limitLabel: {
            style: {
                fontSize: FONT.SIZE_XSMALL,
                ...PALETTE.PROGRESS_BAR_LIMIT_LABEL_TEXT,
            },
        },
    };
}
