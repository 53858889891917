import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {loadScript} from '#core/utils/utils.js';

const GoogleAnalytics4Service = MojitoServices.Analytics.GoogleAnalytics4Service;

const log = MojitoCore.logger.get('GoogleAnalytics4Service');
const gTagUrl = 'https://www.googletagmanager.com/gtag/js';

/**
 * Google analytics 4 service extended with a support of multiple trackers, see DBX-10054 for details
 */
export class DBXGoogleAnalytics4Service extends GoogleAnalytics4Service {
    activate() {
        const tagId = Array.isArray(this.googleAnalytics4MeasurementId)
            ? this.googleAnalytics4MeasurementId[0]
            : this.googleAnalytics4MeasurementId;
        const url = `${gTagUrl}?id=${tagId}`;
        loadScript(url)
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                this.isInitialized = true;
                this.gtag('js', new Date());
                if (Array.isArray(this.googleAnalytics4MeasurementId)) {
                    this.googleAnalytics4MeasurementId.forEach(measurementId => {
                        this.gtag('config', measurementId);
                    });
                } else {
                    this.gtag('config', this.googleAnalytics4MeasurementId);
                }
            })
            .catch(() => {
                log.warn('Failed to load Google analytics 4');
            });
    }
}
