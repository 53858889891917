import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import MojitoModules from 'mojito/modules';
import MojitoCore from 'mojito/core';
import { isEmpty } from 'mojito/utils';

const { NavigationPathBuilder, types: NavigationTypes } = MojitoPresentation.Base.Navigation;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;
const { useMenuContent } = MojitoModules.Common.Hooks;
const { CustomRedirect } = MojitoPresentation.Components;
const { RacingHelper } = MojitoPresentation.Utils;
const log = MojitoCore.logger.get('SportDefaultPage');

export default function SportDefaultPage({ mojitoTools }) {
    const { config, appContext } = mojitoTools;
    const { sportId } = useParams();
    const menusContentInfo = useMenusContentInfo(sportId);

    if (!menusContentInfo || isEmpty(menusContentInfo)) {
        return null;
    }

    const supportedPages = getSupportedPages(config.sports, sportId);
    const defaultPage = getDefaultPageWithContent(supportedPages, menusContentInfo);
    const routeResolver = appContext.routeResolver;

    const routingData = getRoutingData(sportId, defaultPage);
    const sportPageRoute = routeResolver.getRoute(routingData);

    return <CustomRedirect to={sportPageRoute} isReplace={true} />;
}

function useMenusContentInfo(sportId) {
    const timeZone = useSelector(state => selectTimeZoneGmt(state));

    return useMenuContent(sportId, timeZone);
}

function getDefaultPageWithContent(defaultPages, content) {
    const contentKeys = Object.keys(content);
    const pageTitleWithContent = defaultPages
        .map(page => page.toLowerCase())
        .find(page => !!contentKeys.find(key => key.includes(page) && !!content[key]));

    return pageTitleWithContent?.toLowerCase();
}

function getSupportedPages(pages, sportId) {
    const supportedSubPagesList = pages[sportId];
    if (supportedSubPagesList?.length) {
        return supportedSubPagesList;
    }

    return pages.default;
}

function getRoutingData(sportId, defaultPage) {
    if (
        defaultPage === NavigationTypes.MENUS.HIGHLIGHTS ||
        defaultPage === NavigationTypes.MENUS.COMPETITIONS ||
        defaultPage === NavigationTypes.MENUS.OUTRIGHTS ||
        defaultPage === NavigationTypes.MENUS.MEETINGS ||
        defaultPage === NavigationTypes.MENUS.PRICE_BOOSTS ||
        defaultPage === NavigationTypes.MENUS.MATCHES ||
        defaultPage === NavigationTypes.MENUS.FUTURE
    ) {
        return NavigationPathBuilder.toSportCategory(sportId, defaultPage);
    }
    log.warn(`Sport page not supported: ${defaultPage}`);

    return RacingHelper.isRacingSport(sportId)
        ? NavigationPathBuilder.toSportHighlights(sportId)
        : NavigationPathBuilder.toSportCompetitions(sportId);
}
