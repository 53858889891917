import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { pick, isEqual } from 'mojito/utils';
import RaceCouponsView from './race-coupons-view/index.jsx';
import RaceCouponsTypes from './types/index.js';
import { buildRaceAnalyticsData, hasOngoingRaces } from './utils.js';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

const { RequestDataHelper } = MojitoCore.Services;

const {
    descriptor: ItemListDataDescriptor,
    selectors: { selectItemLists },
} = MojitoServices.ItemList;
const { selectors: raceRegionsSelectors, descriptors: RaceRegionsDescriptors } =
    MojitoServices.SportsContent.RaceRegions;
const { selectors: meetingsSelectors, descriptors: MeetingsDescriptors } =
    MojitoServices.SportsContent.Meetings;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;
const { selectAllowBestOddsGuaranteed } = MojitoServices.UserInfo.selectors;
const { selectMenuContentInfo } = MojitoServices.SportsContent.Menus.selectors;
const { selectMeetings } = meetingsSelectors;
const { selectRaceRegions } = raceRegionsSelectors;
const { TODAY, TOMORROW, STREAMING, DAY } = RaceCouponsTypes.FILTER;
const { MEETINGS, HIGHLIGHTS } = RaceCouponsTypes.VIEW_TYPE;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const SortingHelper = MojitoPresentation.Utils.SortingHelper;
const logger = MojitoCore.logger.get('RaceCouponsControllerView');

const FILTER_TYPES = [TODAY, TOMORROW, STREAMING, DAY];

/**
 * Retrieves a list of unique group IDs associated with the specified 'viewType'
 * ('highlights' or 'tomorrow') from the 'contentInfo' object.
 *
 * The function filters through the 'filters' array within the provided 'viewType',
 * selecting only those filters whose 'type' matches either 'DAY' or any type
 * in the 'FILTER_TYPES' array and have a defined 'group' property.
 *
 * The resulting 'group' values are then returned as an array. If no matching
 * filters are found, an empty array is returned.
 *
 * @param {object} contentInfo - The object containing filter and view type information.
 * @param {string} viewType - The view type to inspect ('highlights' or 'tomorrow').
 * @returns {string[]} An array of unique group IDs.
 */
const getListIds = (contentInfo, viewType) =>
    contentInfo?.[viewType]?.filters
        ?.filter(({ type, group }) => (type === DAY || FILTER_TYPES.includes(type)) && group)
        .map(({ group }) => group) || [];

/*
 * Terminology In Horse Racing:
 *
 * Location: Either a country or a region.
 * Meeting: A meetup in a certain track/town/place within the region (location) that hosts a few races.
 * Race: A single race that takes place during a meeting.
 */
export default function RaceCouponsControllerView(props) {
    const {
        sportId,
        couponSelectedFilter,
        couponFilters,
        mojitoTools: {
            appContext,
            config,
            config: { _instanceId, viewType, initiallyExpandedMeetings },
            emitPerformanceMark,
        },
    } = props;

    const helperRef = useRef(new RequestDataHelper(_instanceId));

    const timeZone = useSelector(state => selectTimeZoneGmt(state));
    const contentInfo = useSelector(state => selectMenuContentInfo(sportId, timeZone, state));
    const raceRegions = useSelector(state => selectRaceRegions(state));
    const meetings = useSelector(state => selectMeetings(state));
    const allowBestOddsGuaranteed = useSelector(state => selectAllowBestOddsGuaranteed(state));

    // The collection of list IDs (e.g. 'horse_racing-locations-with-meetings-tomorrow-gmt+3' etc.) associated with the given view type ('highlights' or 'meetings').
    const listIds = useMemo(() => getListIds(contentInfo, viewType), [contentInfo, viewType]);
    // An associative array where the key is a list ID (e.g.'horse_racing-locations-with-meetings-tomorrow-gmt+3') and the value
    // is an array of the associated location IDs which are maintained in and fetched from the 'raceRegion' Mongo collection
    // (e.g.  ['10042-meetings-tomorrow-gmt+3', ...]).
    const locations = useSelector(state => pick(selectItemLists(state), listIds), isEqual);
    // E.g. ['10027-meetings-today-gmt+3', '10042-meetings-tomorrow-gmt+3', ...]
    const memoizedLocationIds = useMemo(() => {
        return Object.values(locations).flat();
    }, [locations]);

    // An associative map maintaining pairs of locationId and filter selected for it.
    // E.g. {  'locationId1': 'today', 'locationId2': 'today', 'locationIdN': 'tomorrow' }
    const [selectedDays, setSelectedDays] = useState({});
    /*
     * A (convenience) array of objects each of which maintains the following data:
     *  - locationId: Identifier for a given location (e.g. '10042'),
     *  - selected: The ID of the selected filter ('today' or 'tomorrow') for the given location.
     *  - today: An array of the race IDs for the present day (i.e. 'today') for the given location.
     *  - tomorrow: An array of the race IDs for the next day (i.e. 'tomorrow') for the given location (e.g. ['115304', '115303']).
     */
    const [meetingIdsByFiltersForLocations, setMeetingIdsByFiltersForLocations] = useState(
        filterMeetingIdsForLocations(memoizedLocationIds)
    );
    const [allMeetingsLoaded, setAllMeetingsLoaded] = useState(false);

    useEffect(() => {
        requestItemListData();
        requestRaceRegionData();
        requestMeetingsData();
        checkDataLoadingCompletion();

        emitPerformanceMark('moduleRendered', allMeetingsLoaded);

        const currentHelperRef = helperRef.current;
        return () => {
            currentHelperRef.unsubscribeAll();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        requestItemListData();
        checkDataLoadingCompletion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sportId, timeZone, contentInfo]);

    useEffect(() => {
        requestRaceRegionData();
        requestMeetingsData();

        setMeetingIdsByFiltersForLocations(filterMeetingIdsForLocations(memoizedLocationIds));

        checkDataLoadingCompletion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sportId, timeZone, memoizedLocationIds, raceRegions, meetings, couponSelectedFilter]);

    /* Requests location/raceRegion IDs (e.g. '10042-meetings-tomorrow-gmt+3') from the server for each filter in the listId
     * (e.g. 'horse_racing-locations-with-meetings-tomorrow-gmt+3').
     * The results for each list ID will populate the corresponding Redux slice located at 'itemListStore/itemLists/${listId}'
     * (e.g. itemListStore/itemLists/horse_racing-locations-with-meetings-tomorrow-gmt+3/['10042-meetings-tomorrow-gmt+3', '13244-meetings-tomorrow-gmt+3', ...]).
     */
    function requestItemListData() {
        listIds
            .map(ItemListDataDescriptor.create)
            .forEach(item => helperRef.current.requestData(item));
    }

    function requestRaceRegionData() {
        // Requests data for each location (race region).
        // It will populate Redux: raceRegionsStore/raceRegions.
        memoizedLocationIds
            .map(RaceRegionsDescriptors.createRaceRegionsDescriptor)
            .forEach(item => helperRef.current.requestData(item));
    }

    function requestMeetingsData() {
        // It will populate Redux: meetingsStore/meetings.
        memoizedLocationIds
            .flatMap(getMeetingIdsForLocation)
            .map(MeetingsDescriptors.createMeetingsDescriptor)
            .forEach(item => helperRef.current.requestData(item));
    }

    const getAvailableFilters = () => {
        const filters = contentInfo?.meetings?.filters || [];

        return filters
            .map(({ type, dayOffset }) => {
                if (type === DAY) {
                    return dayOffset;
                } else if (FILTER_TYPES.includes(type)) {
                    return type;
                }
                return undefined; // Exclude invalid types
            })
            .filter(Boolean); // Remove undefined or falsy values
    };

    // Find what meetings are held in provided location/region.
    function getMeetingIdsForLocation(locationId) {
        const raceRegion = raceRegions[locationId];

        return raceRegion ? raceRegion.raceTracks.map(raceTrack => raceTrack.id) : [];
    }

    // Removes TODAY meeting ids from TOMORROW meeting ids for each location.
    function removeMeetingIdsInTomorrowThatExistInToday(meetingIdsByFiltersForLocations) {
        Object.values(meetingIdsByFiltersForLocations).forEach(meetingIdsByFilters => {
            let hasRemovedIdsInTomorrow = false;

            meetingIdsByFilters[TOMORROW] = meetingIdsByFilters[TOMORROW]?.filter(meetingId => {
                const foundMeetingId = meetingIdsByFilters[TODAY]?.includes(meetingId);

                hasRemovedIdsInTomorrow = hasRemovedIdsInTomorrow || foundMeetingId;

                return !foundMeetingId;
            });

            // Make sure we also update the selected day if we have emptied the tomorrow meeting ids list.
            if (
                hasRemovedIdsInTomorrow &&
                meetingIdsByFilters[TOMORROW]?.length === 0 &&
                meetingIdsByFilters.selected === TOMORROW
            ) {
                meetingIdsByFilters.selected = TODAY;
            }
        });
    }

    /* Determine the applicable filter ('today' or 'tomorrow').
     * By default, opt for 'today', but fall back to 'tomorrow' if there are no scheduled meetings for 'today'.
     * In case meetings for 'today' are scheduled after having opted for the 'tomorrow' fallback, revert to 'today'.
     *
     * Example data received:
     *    - meetingIdsByFilters: { today: [ {...}, {...}, ... ], tomorrow: [ {...}, {...}, ... ] }
     *    - locationId: 10017
     *    - meetings: [{...}, {...}, ...]
     */
    function getSelectedFilter(meetingIdsByFilters, locationId, meetings) {
        const hasMeetingsToday = meetingIdsByFilters[TODAY]?.length > 0;
        const hasMeetingsTomorrow = meetingIdsByFilters[TOMORROW]?.length > 0;
        const hasOngoingRacesToday = hasOngoingRaces(meetingIdsByFilters[TODAY] || [], meetings);

        if (hasMeetingsToday && hasMeetingsTomorrow) {
            return selectedDays[locationId] || (hasOngoingRacesToday ? TODAY : TOMORROW);
        }
        // Prefer Today if we have meetings today.
        return hasMeetingsToday && hasOngoingRacesToday ? TODAY : TOMORROW;
    }

    /*
     * Accepts a string in which a location and a filter id are encoded (e.g. '10027-meetings-today-gmt+2'),
     * and extracts them in the form of an object returned for easier retrieval (e.g. {locationId: '', filter: ''}).
     * E.g. given "10027-meetings-today-gmt+2", the following object is returned: { locationId: "10027", filter: "today"}.
     *      given "11349-meetings-today+2-gmt+2", the following object is returned: { locationId: "10027", filter: 2}.
     */
    function extractLocationIdAndFilter(locationIdWithFilter) {
        const locationId = locationIdWithFilter.split('-meetings-')[0];
        let filter = FILTER_TYPES.find(value => locationIdWithFilter.includes(value));

        // Handle today+ (i.e. day filters) and today- ('today' filter) cases
        if (filter === TODAY) {
            const dayOffsetMatch = locationIdWithFilter.match(/today\+(\d+)/);
            if (dayOffsetMatch) {
                filter = parseInt(dayOffsetMatch[1], 10); // Extract the integer value after 'today+'
            }
        }

        return { locationId, filter };
    }

    /*
     * Receives a list of different location IDs (e.g. ['10042-meetings-today-gmt+3', '10042-meetings-tomorrow-gmt+3', ...]) where each value
     * encodes a locationId (e.g. '10042') followed by a 'filterId' (e.g. 'tomorrow').
     * The function returns a map where keys are the passed-in location IDs, and each map value contains the following attributes:
     * displayOrder, locationId, name, selected, today, and tomorrow.
     * This returned map is essential for visualizing the corresponding locations and their associated racing events.
     *
     * E.g. in the returned map:
     *      Key: 'locationId' - Value: { displayOrder: '', locationId: '', name: '', selected: '', today: [], tomorrow: [] },
     *      ...
     *
     *      Example:
     *      Key: '10042' - Value: { displayOrder: 0, locationId: "10042", name: "Argentina", selected: "tomorrow", today: [], tomorrow: ['115304', '115303'] },
     *      ...
     */
    function filterMeetingIdsForLocations(locationIdsWithFilter) {
        const meetingsDataForLocations = {};
        locationIdsWithFilter.forEach(locationIdWithFilter => {
            const { locationId, filter } = extractLocationIdAndFilter(locationIdWithFilter);

            // Get or initialize object
            const meetingsData = meetingsDataForLocations[locationId] || {};

            // Load raceRegion data for the given locationIdWithFilter, e.g. { id: "10042-meetings-tomorrow-gmt+3", raceTracks: Array(11) [ {…}, {…}, {…}, … ], displayOrder: -95, valid: true, versionTag: 63, lastModified: "2024-07-24T01:57:43.852+00:00", lastTouched: "2024-07-24T10:12:46.035+00:00" }.
            const raceRegion = raceRegions[locationIdWithFilter];

            // Initialize filter array if it doesn't exist
            meetingsData[filter] = meetingsData[filter] || [];

            // Find the meeting ids for the location.
            meetingsData[filter] = raceRegion
                ? raceRegion.raceTracks.map(raceTrack => raceTrack.id)
                : [];

            // Select which filter to use for the view.
            meetingsData.selected = couponSelectedFilter
                ? couponSelectedFilter
                : getSelectedFilter(meetingsData, locationId, meetings);
            meetingsData.locationId = locationId;

            // Add data for sorting.
            const meeting = raceRegion && meetings && meetings[raceRegion.raceTracks[0].id];
            meetingsData.name = meeting?.className;
            meetingsData.displayOrder = raceRegion?.displayOrder;
            meetingsData.countryCode = meeting?.countryCode;

            meetingsDataForLocations[locationId] = meetingsData;
        });

        /*
         * Remove meeting IDs from the 'tomorrow' list that already exist in the 'today' list.
         * This handles meetings that stretch over midnight and appear in both lists.
         */
        removeMeetingIdsInTomorrowThatExistInToday(meetingsDataForLocations);

        return Object.values(meetingsDataForLocations).sort((a, b) =>
            SortingHelper.byDisplayOrderAndName(a, b, appContext.systemSettings().language)
        );
    }

    function checkDataLoadingCompletion() {
        const availableFilters = getAvailableFilters();

        const sumOfAllRaces = meetingIdsByFiltersForLocations.reduce((acc, meeting) => {
            return availableFilters.reduce(
                (sum, filter) => sum + (meeting[filter]?.length || 0),
                acc
            );
        }, 0);

        if (sumOfAllRaces > 0 && !allMeetingsLoaded) {
            setAllMeetingsLoaded(true);
        }
    }

    function onFilterClicked(filter, locationId) {
        setMeetingIdsByFiltersForLocations(
            meetingIdsByFiltersForLocations.map(elem => {
                if (elem.locationId === locationId) {
                    return { ...elem, selected: filter };
                }

                return elem;
            })
        );
        setSelectedDays({ ...selectedDays, [locationId]: filter });
    }

    function onRaceClicked(meetingId, raceId) {
        const data = buildRaceAnalyticsData(meetings, meetingId, raceId, appContext);
        appContext.analyticsEmitter.emitAnalytics('selectRaceOnMeetingsCoupon', data);
        IntentActionTrigger.showMeetingsRaces(sportId, meetingId, raceId);
    }

    switch (viewType) {
        case HIGHLIGHTS:
            return (
                <RaceCouponsView
                    sportId={sportId}
                    isContentPending={!allMeetingsLoaded}
                    meetingsForLocations={meetingIdsByFiltersForLocations}
                    initiallyExpandedMeetings={initiallyExpandedMeetings}
                    meetings={meetings}
                    filters={couponFilters}
                    onFilterClicked={onFilterClicked}
                    onRaceClicked={onRaceClicked}
                    allowBestOddsGuaranteed={allowBestOddsGuaranteed}
                    config={config.raceCouponsView}
                />
            );
        case MEETINGS:
            return (
                <RaceCouponsView
                    sportId={sportId}
                    isContentPending={!allMeetingsLoaded}
                    meetingsForLocations={meetingIdsByFiltersForLocations}
                    meetings={meetings}
                    selectedFilter={couponSelectedFilter}
                    onFilterClicked={onFilterClicked}
                    onRaceClicked={onRaceClicked}
                    allowBestOddsGuaranteed={allowBestOddsGuaranteed}
                    config={config.raceCouponsView}
                />
            );
        default:
            logger.error(`Unknown view type: "${viewType}"`);
            return null;
    }
}
