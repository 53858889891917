import { isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';

const { actions: betslipActions, types: BetslipTypes } = MojitoServices.Betslip;
const reduxInstance = MojitoCore.Services.redux;

/**
 * Prebuilt analytics listener listen to redux actions and/or store changes. Used to track if
 * a prebuilt match acca is added to betslip and used to tell a regular match acca apart from a prebuilt match acca.
 *
 * @class PrebuiltAnalyticsListener
 * @memberof Mojito.Application.Analytics
 */
class PrebuiltAnalyticsListener {
    init() {
        this.isPrebuilt = false;
        this.startListening();
    }

    getPreBuiltStatus() {
        return this.isPrebuilt;
    }

    startListening() {
        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.addPartSuccess,
            effect: action => {
                const { requestPayload } = action.payload;
                const { bettingContext } = requestPayload || {};
                // flag set to true when adding a prebuilt bet to Betslip.
                this.isPrebuilt =
                    bettingContext === BetslipTypes.BETTING_CONTEXT.PRE_BUILT_MATCH_ACCA_BET;
            },
        });

        reduxInstance.actionListener.startListening({
            matcher: isAnyOf(
                betslipActions.removePartSuccess,
                betslipActions.removeCompositeSuccess,
                betslipActions.clearBetslipSuccess,
                betslipActions.placeBetslipSuccess
            ),
            effect: () => {
                // flag set to false when modifying Betslip by adding, removing or clearing.
                this.isPrebuilt = false;
            },
        });
    }
}

export default new PrebuiltAnalyticsListener();
