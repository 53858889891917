export const PALETTE_IDS = {
    //-- Tiles
    FONT: 'tile.FONT',
    SKELETON: 'tile.SKELETON_PART',
    INPUT: 'tile.INPUT',
    STAKE_INPUT: 'tile.STAKE_INPUT',
    DOT_NOTIFICATION: 'tile.DOT_NOTIFICATION',
    STREAMING_INDICATOR: 'tile.STREAMING_INDICATOR',
    POPOVER: 'tile.POPOVER',
    ODDS_BUTTON: 'tile.ODDS_BUTTON',
    CHANGED_VALUE_INDICATOR: 'tile.CHANGED_VALUE_INDICATOR',
    ERROR_NOTIFICATION: 'tile.NOTIFICATION.ERROR',
    WARNING_NOTIFICATION: 'tile.NOTIFICATION.WARNING',
    SUCCESS_NOTIFICATION: 'tile.NOTIFICATION.SUCCESS',
    INFO_NOTIFICATION: 'tile.NOTIFICATION.INFO',
    RANGE_INPUT: 'tile.RANGE_INPUT',
    PRIMARY_LINK: 'tile.LINK.PRIMARY',
    SECONDARY_LINK: 'tile.LINK.SECONDARY',
    TERTIARY_LINK: 'tile.LINK.TERTIARY',
    SWITCH: 'tile.SWITCH',
    EMPTY_CONTENT_MESSAGE: 'tile.EMPTY_CONTENT_MESSAGE',
    PRIMARY_DROPDOWN: 'tile.DROPDOWN.PRIMARY',
    SECONDARY_DROPDOWN: 'tile.DROPDOWN.SECONDARY',
    TERTIARY_DROPDOWN: 'tile.DROPDOWN.TERTIARY',
    DROPDOWN_MENU: 'tile.DROPDOWN.MENU',
    TOGGLE_BUTTON: 'tile.BUTTON.TOGGLE',
    PRIMARY_BUTTON: 'tile.BUTTON.PRIMARY',
    SECONDARY_BUTTON: 'tile.BUTTON.SECONDARY',
    TERTIARY_BUTTON: 'tile.BUTTON.TERTIARY',
    CHECKBOX: 'tile.CHECKBOX',
    PIVOT: 'tile.PIVOT',
    CLOSE_BUTTON: 'tile.CLOSE_BUTTON',
    LIST_ITEM: 'tile.LIST_ITEM',
    EVENT_TIMING_STATUS: 'tile.EVENT_TIMING_STATUS',
    MINI_SCOREBOARD: 'tile.MINI_SCOREBOARD',
    RUNNER_INFO: 'tile.RUNNER_INFO',
    PARTICIPANTS: 'tile.PARTICIPANTS',
    TABLE: 'tile.TABLE',
    MINI_EVENT_ITEM_CONTENT: 'tile.MINI_EVENT_ITEM_CONTENT',
    MINI_EVENT_ITEM: 'tile.MINI_EVENT_ITEM',
    EVENT_LIST: 'tile.EVENT_LIST',
    EVENTS_COUPON: 'tile.EVENTS_COUPON',
    EVENT_DETAILS: 'tile.EVENT_DETAILS',
    PROMOTION_QUICK_LINKS: 'tile.PROMOTION_QUICK_LINKS',
    BET_BUILDER_SELECTION_ITEM: 'tile.BET_BUILDER_SELECTION_ITEM',
    BET_STATUS_ICON: 'tile.BET_STATUS_ICON',
    BET_FUNDS_INFO: 'tile.BET_FUNDS_INFO',
    BET_BASE_INFO: 'tile.BET_BASE_INFO',
    PROGRESS_BAR: 'tile.PROGRESS_BAR',
    BET_RECEIPT: 'tile.BET_RECEIPT',
    SETTINGS: 'tile.SETTINGS',
    EARLY_PAYOUT_INDICATOR: 'tile.EARLY_PAYOUT_INDICATOR',
    PRICE_BOOST_LABEL: 'tile.PRICE_BOOST_LABEL',
    PRICE_BOOST_ITEM: 'tile.PRICE_BOOST_ITEM',
    SEARCH_RESULT: 'tile.SEARCH_RESULT',
    //-- Palettes
    BASIC: 'palette.BASIC',
    MOBILE_NAVBAR: 'palette.MOBILE_NAVBAR',
    SEARCH: 'palette.SEARCH',
    SCOREBOARDS: 'palette.SCOREBOARDS',
    VIRTUALS: 'palette.VIRTUALS',
    VISUALISATION: 'palette.VISUALISATION',
    CUSTOM_PAGE: 'palette.CUSTOM_PAGE',
    EVENT_DISCLAIMER: 'palette.EVENT_DISCLAIMER',
    SPORTS_CONTENT: 'palette.SPORTS_CONTENT',
    HOME_PAGE: 'palette.HOME_PAGE',
    SPORT_PAGE: 'palette.SPORT_PAGE',
    INPLAY_PAGE: 'palette.INPLAY_PAGE',
    NUMERIC_KEYPAD: 'palette.NUMERIC_KEYPAD',
    RACE_CARD: 'palette.RACE_CARD',
    MENUS_LINKS_LISTS: 'palette.MENUS_LINKS_LISTS',
    AZ_MENU: 'palette.AZ_MENU',
    QUICK_NAVIGATIONS: 'palette.QUICK_NAVIGATIONS',
    DESKTOP_INFO_SECTION: 'palette.DESKTOP_INFO_SECTION',
    DESKTOP_CMS_LINKS: 'palette.DESKTOP_CMS_LINKS',
    INPLAY_MENU: 'palette.INPLAY_MENU',
    DIALOGS: 'palette.DIALOGS',
    AUTO_CASHOUT_DIALOG: 'palette.AUTO_CASHOUT_DIALOG',
    ACCEPT_DIALOG: 'palette.ACCEPT_DIALOG',
    PROMO_MESSAGE_DIALOG: 'palette.PROMO_MESSAGE_DIALOG',
    LOGIN_DIALOG: 'palette.LOGIN_DIALOG',
    TOAST: 'palette.TOAST',
    ALERT_DIALOG: 'palette.ALERT_DIALOG',
    QUICK_START_GUIDE: 'palette.QUICK_START_GUIDE',
    COMBINATION_ODDS_POPUP: 'palette.COMBINATION_ODDS_POPUP',
    FUTURE_RACING: 'palette.FUTURE_RACING',
    RACE_MEETINGS: 'palette.RACE_MEETINGS',
    RACING: 'palette.RACING',
    COUPONS: 'palette.COUPONS',
    EVENT_PAGE: 'palette.EVENT_PAGE',
    MARKETS: 'palette.MARKETS',
    VIDEO_STREAMING: 'palette.VIDEO_STREAMING',
    BET_HISTORY: 'palette.BET_HISTORY',
    YOUR_BETS: 'palette.YOUR_BETS',
    MY_ACCOUNT_DIALOG: 'palette.MY_ACCOUNT_DIALOG',
    ACCOUNT_POPOVER: 'palette.ACCOUNT_POPOVER',
    MY_ACCOUNT_PAGE: 'palette.MY_ACCOUNT_PAGE',
    STATIC_PAGES: 'palette.STATIC_PAGES',
    BAD_ROUTE_PAGE: 'palette.BAD_ROUTE_PAGE',
    NATIVE_HOSTING_PAGE: 'palette.NATIVE_HOSTING_PAGE',
    FOREIGN_WINDOW: 'palette.FOREIGN_WINDOW',
    APP_VIEW: 'palette.APPLICATION_VIEW',
    BETTING_CENTER: 'palette.BETTING_CENTER',
    BETSLIP: 'palette.BETSLIP',
    OVERASK: 'palette.OVERASK',
    QUICK_BETSLIP: 'palette.QUICK_BETSLIP',
    NATIVE_APP: 'palette.NATIVE_APP',
    EVENT_CARDS: 'palette.EVENT_CARDS',
    INTERFACE: 'palette.INTERFACE',
    APP_BANNER: 'APP_BANNER',
    APPLICATION_HEADER: 'palette.APPLICATION_HEADER',
    ONBOARDING: 'palette.ONBOARDING',
    MATCH_ACCA: 'palette.MATCH_ACCA',
    MARKET_INSTRUCTIONS: 'palette.MARKET_INSTRUCTIONS',
    MATCH_ACCA_SLIP: 'palette.MATCH_ACCA_SLIP',
    BONUSES: 'palette.BONUSES',
    ACCOUNT: 'palette.ACCOUNT',
    PROMOTIONS: 'palette.PROMOTIONS',
    STATSCORE: 'palette.STATSCORE',
};
