import {PALETTE_IDS} from '#core/application/modules/ids.js';

import {SPORT_BACKGROUND_IMAGE_MAP} from '#core/application/modules/sports-content/sport-page/sport-page.factory.js';
import {getSportBackgroundImages} from '#core/application/modules/sports-content/sport-page/utils.js';

const CUSTOM_SPORT_BACKGROUND_IMAGE_MAP = {
    ...SPORT_BACKGROUND_IMAGE_MAP,
    entertainment: 'entertainment',
    gaelic_games: 'gaelic_games',
    politics: 'politics',
    rugby_league: 'rugby_league',
    rugby_union: 'rugby_union',
    winter_sport: 'winter_sport',
    greyhound_racing: 'greyhound_racing',
    horse_racing: 'horse_racing',
};

export function getSportsContentConfigs() {
    return {
        custom: {
            [PALETTE_IDS.SPORTS_CONTENT]: {
                SportPage: {
                    sportBackgroundImages: getSportBackgroundImages({
                        sportBackgroundImageMap: CUSTOM_SPORT_BACKGROUND_IMAGE_MAP,
                    }),
                    _mobile_sportBackgroundImages: getSportBackgroundImages({
                        sportBackgroundImageMap: CUSTOM_SPORT_BACKGROUND_IMAGE_MAP,
                        folderName: 'mobile',
                    }),
                },
            },
        },
        palette: {},
    };
}
