import {registerTileSchema} from '#core/application/schema-registry.js';

import {resolveBackground} from '#core/utils/color-utils.js';

import CHANGED_VALUE_INDICATOR from './changed-value-indicator.palette-schema.yaml';

registerTileSchema(CHANGED_VALUE_INDICATOR);

export function generateChangedValueIndicator(TILE, {SHOW_ICON = false, HIGHLIGHT_NEW_VALUE = true} = {}) {
    const {FONT} = TILE;

    const CVI_NEW_VALUE_CONTAINER_BG = HIGHLIGHT_NEW_VALUE ? TILE.CVI_NEW_VALUE_BG : 'transparent';
    const CVI_NEW_VALUE_TEXT = HIGHLIGHT_NEW_VALUE ? TILE.CVI_NEW_VALUE_TEXT : TILE.CVI_NEW_VALUE_WITHOUT_BG_TEXT;

    return {
        container: {
            style: {
                alignItems: 'center',
            },
        },
        previousValue: {
            container: {
                flexGrow: '0',
                flexShrink: '0',
                flexBasis: 'auto',
            },
            style: {
                textDecoration: 'line-through',
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_NORMAL,
                ...TILE.CVI_PREVIOUS_VALUE_TEXT,
            },
        },
        newValueContainer: {
            style: {
                ...resolveBackground(CVI_NEW_VALUE_CONTAINER_BG),
                flexGrow: '0',
                flexShrink: '0',
                flexBasis: 'auto',
                borderRadius: TILE.CVI_NEW_VALUE_BORDER_RADIUS,
                padding: SHOW_ICON ? 0 : 2,
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_BOLD,
                ...CVI_NEW_VALUE_TEXT,
            },
        },
        indicationIconContainer: {
            style: {
                flexGrow: '0',
                flexShrink: '0',
                flexBasis: 'auto',
                minHeight: 12,
                minWidth: 12,
                height: 12,
                width: 12,
                overflow: 'hidden',
            },
        },
        indicationIcon: {
            src: SHOW_ICON ? 'double-arrow-right' : undefined,
            style: {
                minHeight: 12,
                minWidth: 12,
                height: 12,
                width: 12,
                fill: TILE.CVI_INDICATION_ICON_COLOR,
            },
        },
    };
}
