import React from 'react';
import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {LoginRoute} from './login-route.js';
import {RegistrationRoute} from './registration-route.js';

class DeepLinkingFeature extends AbstractFeature {
    get name() {
        return 'DeepLinking';
    }

    afterMojitoConfigBuild(mojitoConfig) {
        mojitoConfig.customRoutes = mojitoConfig.customRoutes || [];

        mojitoConfig.customRoutes.push({path: 'login', element: React.createElement(LoginRoute)});
        mojitoConfig.customRoutes.push({path: 'registration', element: React.createElement(RegistrationRoute)});

        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const DeepLinking = new DeepLinkingFeature(allFeatures);
