import MojitoCore from 'mojito/core';
import CollapsibleMeetingPaneImpl from './collapsible-meeting-pane';
import PropTypes from 'prop-types';

const UIView = MojitoCore.Presentation.UIView;

/**
 * Collapsible meeting pane. Used in racing sports to display events.
 *
 * @class CollapsibleMeetingPane
 * @memberof Mojito.Presentation.Components
 */

/**
 * CollapsibleMeetingPane component prop types.
 *
 * @property {string} [sportId] - The sportId to display.
 * @property {string} [countryIcon] - Short name of country, which flag to show.
 * @property {string} [countryName] - Name of country to show.
 * @property {boolean} [allowBestOddsGuaranteed] - Allow best odds guaranteed flag.
 * @property {boolean} [initiallyExpanded = false] - Set to true if the view initially should be expanded.
 * @property {Array} [filters] - An array of objects with information for ButtonBar.
 * @property {string} [selectedFilter] - Id of the selected filter in ButtonBar.
 * @property {Function} [filterClickedCb] - Callback invoked upon clicking on any filter item receiving as an argument the Id of selected tab in ButtonBar.
 * @property {Array} [meetings] - Array containing meetings.
 * @property {Function} [onRaceClicked] - Callback function triggered when a meeting is clicked.
 *
 * @memberof Mojito.Presentation.Components.CollapsibleMeetingPane
 */
const propTypes = {
    sportId: PropTypes.string,
    countryIcon: PropTypes.string,
    countryName: PropTypes.string,
    allowBestOddsGuaranteed: PropTypes.bool,
    initiallyExpanded: PropTypes.bool,
    filters: PropTypes.array,
    selectedFilter: PropTypes.string,
    filterClickedCb: PropTypes.func,
    onRaceClicked: PropTypes.func,
    meetings: PropTypes.array,
};

const defaultProps = {
    initiallyExpanded: false,
};

export default UIView(
    'CollapsibleMeetingPane',
    CollapsibleMeetingPaneImpl,
    propTypes,
    defaultProps
);
