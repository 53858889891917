import MojitoCore from 'mojito/core';

import {getResettingCSSForContainerId} from '#core/utils/embedding-utils.js';
import AbstractWidget from '#core/widgets/AbstractWidget.js';
import WidgetBonusHistoryController from '#core/components/bonus-history/index.jsx';
import {MobileContext} from '#core/utils/context-utils.js';
import {generateUniqueId} from '#core/utils/utils.js';
import {StoreProviderWrapper} from './wrappers/index.js';

const AnalyticsContextExtender = MojitoCore.Presentation.AnalyticsContext.ContextExtender;
const AnalyticsContextBuilder = MojitoCore.Presentation.AnalyticsContext.ContextBuilder;

class WidgetBonusHistoryImpl extends AbstractWidget {
    constructor(props) {
        super(props);

        this.widgetId = 'widget-bonus-history-' + generateUniqueId();

        this.analyticsContext = new AnalyticsContextBuilder().withAnalyticsPath('WidgetBonusHistory').build();
    }

    render() {
        return (
            <AnalyticsContextExtender value={this.analyticsContext}>
                <MobileContext>
                    <style
                        type="text/css"
                        dangerouslySetInnerHTML={{__html: getResettingCSSForContainerId(this.widgetId)}}
                    ></style>
                    <div id={this.widgetId}>
                        <WidgetBonusHistoryController
                            config={{view: {compactMode: true, showCompactEmptyContent: true}}}
                        />
                    </div>
                </MobileContext>
            </AnalyticsContextExtender>
        );
    }
}

export const WidgetBonusHistory = StoreProviderWrapper('WidgetBonusHistory', WidgetBonusHistoryImpl);
