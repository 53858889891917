import {AbstractFeature, allFeatures} from '#core/application/abstract-feature.js';
import {ensureHierarchy} from '#core/utils/config-utils.js';
import {loadScript} from '#core/utils/utils.js';
import {IMSMessageService} from './message-adapters-service.js';
import SCHEMA from './ims.schema.yaml';
import MojitoServices from 'mojito/services';

const {types: AuthenticationTypes} = MojitoServices.Authentication;
const {CREDENTIALS_PUBLIC_TYPE} = AuthenticationTypes;

const isCardNumber = value => {
    return value && !/\D/.test(value);
};

const isPin = (value, min = 4, max = 6) => {
    return value && new RegExp(`^\\d{${min},${max}}$`).test(value);
};

// TODO: move also IMS related presentation layer(factory) here
class IMSFeature extends AbstractFeature {
    constructor(parent) {
        super(parent);

        this.initialized = false;
        this.MojitoIMSIntegration = null;
    }

    get name() {
        return 'IMS';
    }

    get configSchema() {
        return SCHEMA;
    }

    get ready() {
        return this.initialized && this.config.enabled;
    }

    get isThirdParty() {
        return false;
    }

    get enabled() {
        // Disable IMS feature if using embedded sportsbook (because all authentication is going through DrivenSSOService)
        return IMS_BASED && super.enabled && !Sportsbook.isEmbedded;
    }

    async beforeMojito() {
        if (!BUILT_IN_AUTHORIZATION || !IMS_BASED) {
            return Promise.resolve(); // To eliminate dead code
        }

        if (this.initialized) {
            return Promise.resolve();
        }
        const loadPas = !window.iapiGetLoggedInPlayer;

        if (loadPas) {
            loadScript(this.config.pasUrl)
                .then(() => {
                    this.initialized = true;
                    this.logger.info('Script was loaded successfully');
                })
                .catch(err => {
                    this.logger.error(`Failed to load PAS script: ${err}`);
                });
        }

        return import(
            /* webpackChunkName: "sportsbook.ims" */
            /* webpackMode: "lazy" */
            'mojito-client/ims-integration'
        )
            .then(module => {
                this.MojitoIMSIntegration = module.default;
            })
            .then(super.beforeMojito.bind(this));
    }

    loginMethodDetector(credentials) {
        const {publicFactor, privateFactor} = credentials.data;
        const loginMethod =
            isCardNumber(publicFactor) && isPin(privateFactor) ? CREDENTIALS_PUBLIC_TYPE.CARD : credentials.publicType;
        return loginMethod;
    }

    afterMojitoConfigBuild(mojitoConfig) {
        const imsBalanceService = this.MojitoIMSIntegration.Balance.service;

        const userInfoService = ensureHierarchy(mojitoConfig, 'services.userInfoService');
        userInfoService.balanceService = imsBalanceService;

        const messagingServiceConfig = this.config.messagingUrl
            ? {
                  service: this.MojitoIMSIntegration.Messaging.service.configure({
                      casinoName: this.config.casinoName,
                      messagingUrl: this.config.messagingUrl,
                      urlTemplateTypes: [this.config.urlTemplateId],
                      messageAdapters: IMSMessageService.getMessageAdapters(
                          this.MojitoIMSIntegration.Messaging.service
                      ),
                  }),
              }
            : undefined;
        const imsServiceConfig = this.config.enableLoginMethodDetector
            ? {...this.config, loginMethodDetector: this.loginMethodDetector}
            : this.config;
        let services = ensureHierarchy(mojitoConfig, 'services');
        services.ims = imsServiceConfig;
        services.messagingService = messagingServiceConfig;

        return super.afterMojitoConfigBuild(mojitoConfig);
    }
}

export const IMS = BUILT_IN_AUTHORIZATION && IMS_BASED ? new IMSFeature(allFeatures) : null;
