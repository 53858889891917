import {resolveBackground} from '#core/utils/color-utils.js';
import {registerTileSchema} from '#core/application/schema-registry.js';

import {BASE_BACKGROUND_RESETER, getButtonSidesIndent} from '../utils.js';

import PRIMARY_BUTTON from './primary-button.palette-schema.yaml';

registerTileSchema(PRIMARY_BUTTON);

export function generatePrimaryButton(TILE) {
    const {FONT} = TILE;

    const ACTIVE_BUTTON = {
        ...BASE_BACKGROUND_RESETER,
        ...resolveBackground(TILE.PRIMARY_BUTTON_SELECTED_BG),
        ...TILE.PRIMARY_BUTTON_SELECTED_TEXT,
    };

    return {
        style: {
            base: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                borderRadius: TILE.PRIMARY_BUTTON_BORDER_RADIUS,
                alignItems: 'center',
                height: 40,
                borderWidth: 0,
                padding: `0 ${getButtonSidesIndent(TILE.PRIMARY_BUTTON_BORDER_RADIUS)}px`,
                ...resolveBackground(TILE.PRIMARY_BUTTON_BG),
                ...TILE.PRIMARY_BUTTON_TEXT,
            },
            active: ACTIVE_BUTTON,
            hover: ACTIVE_BUTTON,
            disabled: {
                ...BASE_BACKGROUND_RESETER,
                ...resolveBackground(TILE.PRIMARY_BUTTON_DISABLED_BG),
                ...TILE.PRIMARY_BUTTON_DISABLED_TEXT,
            },
        },
    };
}

function animatedButtonBase(TILE, CUSTOM_SETTINGS = {}) {
    const {FONT} = TILE;
    const BASE_BG = CUSTOM_SETTINGS.ANIMATED_BUTTON_BG || TILE.PRIMARY_BUTTON_BG;
    const ACTIVE_BUTTON = resolveBackground(TILE.PRIMARY_BUTTON_HOVER_BG);

    return {
        style: {
            base: {
                fontSize: FONT.SIZE_MEDIUM,
                fontWeight: FONT.WEIGHT_SEMI_BOLD,
                borderRadius: TILE.PRIMARY_BUTTON_BORDER_RADIUS,
                alignItems: 'center',
                minHeight: 40,
                padding: `0 ${getButtonSidesIndent(TILE.PRIMARY_BUTTON_BORDER_RADIUS)}px`,
                ...resolveBackground(BASE_BG),
                ...TILE.PRIMARY_BUTTON_TEXT,
                ...CUSTOM_SETTINGS.ANIMATED_BUTTON_TEXT,
            },
            active: ACTIVE_BUTTON,
            hover: ACTIVE_BUTTON,
            disabled: {
                ...BASE_BACKGROUND_RESETER,
                ...resolveBackground(TILE.PRIMARY_BUTTON_DISABLED_BG),
                ...TILE.PRIMARY_BUTTON_DISABLED_TEXT,
            },
        },
    };
}

export function generateAnimatedButton(TILE) {
    const ANIMATION_BUTTON_BASE = animatedButtonBase(TILE);
    const ANIMATION_BUTTON_CONFIRM = {
        ANIMATED_BUTTON_BG: TILE.PRIMARY_BUTTON_CONFIRM_BG,
        ANIMATED_BUTTON_TEXT: TILE.PRIMARY_BUTTON_CONFIRM_TEXT,
    };
    const ANIMATION_BUTTON_FAILED = {
        ANIMATED_BUTTON_BG: TILE.PRIMARY_BUTTON_FAILED_BG,
        ANIMATED_BUTTON_TEXT: TILE.PRIMARY_BUTTON_FAILED_TEXT,
    };

    return {
        style: {
            animatedBackground: {
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
            },
        },
        button: ANIMATION_BUTTON_BASE,
        confirm: animatedButtonBase(TILE, ANIMATION_BUTTON_CONFIRM),
        success: ANIMATION_BUTTON_BASE,
        fail: animatedButtonBase(TILE, ANIMATION_BUTTON_FAILED),
    };
}
