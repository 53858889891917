import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { isAnyOf } from '@reduxjs/toolkit';
import { actions as routerActions } from 'application/stores/router/slice.js';
import { actions as cookieConsentActions } from 'core/services/cookie-consent/slice.js';

const reduxInstance = MojitoCore.Services.redux;
const { routeChange } = routerActions;
const { serviceFactory, types: PerformanceTypes } = MojitoCore.Services.Performance;
const { selectUserName } = MojitoServices.UserInfo.selectors;
const bootstrapActions = MojitoServices.Bootstrap.actions;
const { selectionPending } = MojitoServices.Betslip.actions;
const { PAGE_READY, PAGE_INTERACTED, PAGE_INTERACTED_BEFORE_READY } =
    PerformanceTypes.TIMELINE_RECORD_TYPES;

/**
 * Class to be used in UI components to emit performance metrics.
 *
 * @class PerformanceEmitter
 * @memberof Mojito.Application
 */
export default class PerformanceEmitter {
    constructor() {
        this.pageReady = false;
        this.pageInteracted = false;
        this.navigated = false;
        // Do not listen for fired actions when NULL_SERVICE is kept
        if (serviceFactory.isActive()) {
            reduxInstance.actionListener.startListening({
                actionCreator: routeChange,
                effect: ({ payload }, { getState }) => {
                    const service = serviceFactory.getService();
                    const userName = selectUserName(getState());
                    this.navigated = true;
                    service.navigate(payload, userName);
                },
            });

            reduxInstance.actionListener.startListening({
                actionCreator: selectionPending,
                effect: () => {
                    this.emitPerformanceMetric(PAGE_INTERACTED, { eventType: 'betslipSelection' });
                },
            });

            reduxInstance.actionListener.startListening({
                matcher: isAnyOf(cookieConsentActions.giveConsent, cookieConsentActions.reset),
                effect: () => {
                    const service = serviceFactory.getService();
                    service.activate();
                },
            });

            reduxInstance.actionListener.startListening({
                actionCreator: bootstrapActions.dispose,
                effect: () => {
                    this.pageReady = false;
                    this.pageInteracted = false;
                    this.navigated = false;
                },
            });
        }
    }
    /**
     * Emits a performance metric.
     *
     * @param {string} type - Performance metric type.
     * @param {*} payload - Data of any type to be used in the performance report.
     * @function Mojito.Application.PerformanceEmitter#emitPerformanceMetric
     */
    emitPerformanceMetric(type, payload) {
        const typeToReport = this.adaptMetricType(type);
        switch (type) {
            case PAGE_READY: {
                this.pageReady = true;
                break;
            }
            case PAGE_INTERACTED: {
                this.pageInteracted = true;
                break;
            }
        }
        const service = serviceFactory.getService();
        typeToReport && service.report(typeToReport, payload);
    }

    adaptMetricType(type) {
        if (type === PAGE_READY || type === PAGE_INTERACTED) {
            // If user interacts with a page before PAGE_READY is triggered, we will use PAGE_INTERACTED_BEFORE_READY metric type.
            // This is needed to distinguish valid PAGE_READY timings from the cases when user starts navigating too soon, not waiting for data load and rendered.
            if (type === PAGE_INTERACTED && !this.pageReady) {
                return PAGE_INTERACTED_BEFORE_READY;
            }
            // Ignore subsequent PAGE_READY metric if it was already reported before.
            if (type === PAGE_READY && this.pageReady) {
                return;
            }
            // Ignore PAGE_READY and PAGE_INTERACTED metrics if user already has interacted before or navigated away to another page.
            if (this.pageInteracted || this.navigated) {
                return;
            }
        }
        return type;
    }
}
