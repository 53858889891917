import {mapValues} from '#core/utils/utils.js';

const SPORT_DEFAULT_PAGE_PRIORITIES = {
    matches: ['Matches', 'Competitions', 'Outrights'],
    competitions: ['Competitions', 'Matches', 'Outrights'],
    outrights: ['Outrights', 'Matches', 'Competitions'],
};

const getMatchSportsDefaultPagePriority = (defaultValue, overriddenSports = {}) => {
    const sports = {
        default: SPORT_DEFAULT_PAGE_PRIORITIES[defaultValue],
    };

    for (const sportId in overriddenSports) {
        sports[sportId] = SPORT_DEFAULT_PAGE_PRIORITIES[overriddenSports[sportId]];
    }

    return sports;
};

const getSportBackgroundImages = ({
    sportBackgroundImageMap,
    folderName = '',
    extension = 'png',
    alternateExtensionImageMap,
}) => {
    let images = mapValues(sportBackgroundImageMap, imgName => `sport-page/${folderName}/${imgName}.${extension}`);

    if (alternateExtensionImageMap) {
        const altExtension = extension === 'png' ? 'svg' : 'png';
        const additionalImages = mapValues(
            alternateExtensionImageMap,
            imgName => `sport-page/${folderName}/${imgName}.${altExtension}`
        );
        images = {
            ...images,
            ...additionalImages,
        };
    }
    return images;
};

export {getMatchSportsDefaultPagePriority, getSportBackgroundImages};
