import MojitoModules from 'mojito/modules';
import MojitoPresentation from 'mojito/presentation';
import MojitoServices from 'mojito/services';
import { useLayoutEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { FiltersBar } = MojitoModules;
const { FlexPane } = MojitoPresentation.Components;
const { selectMenuContentInfo } = MojitoServices.SportsContent.Menus.selectors;
const { selectTimeZoneGmt } = MojitoServices.UserSettings.selectors;

/**
 * Checks if a filter is valid based on its type and a list of possible filters.
 *
 * @param {string} filter - The filter to validate. This can represent a type, a day offset, or a span.  If `filter` is
 * falsy, the function considers it invalid by default.
 * @param {Array} validFilters - The list of filter objects to validate against.
 * @returns {boolean} True if the filter is valid, false otherwise.
 */
const isValidFilterId = (filter, validFilters) => {
    return (
        filter &&
        validFilters.some(
            filterItem =>
                filterItem.type === filter ||
                validFilters.some(
                    filterItem =>
                        `${filterItem?.dayOffset}` === filter || filterItem?.span === filter
                )
        )
    );
};

const getPreSelectedFilter = filters => filters[0].type;

export default function MeetingsView(props) {
    const {
        mojitoTools: { config, appContext },
    } = props;
    const { routeResolver, analyticsEmitter } = appContext;
    const timeZone = useSelector(state => selectTimeZoneGmt(state));
    const { sportId, filter } = useParams();
    const contentInfo = useSelector(state => selectMenuContentInfo(sportId, timeZone, state));
    const { filters } = contentInfo.meetings;
    const filterAutoSelect = !isValidFilterId(filter, filters);

    const [selectedFilterKey, setSelectedFilterKey] = useState(
        filterAutoSelect ? getPreSelectedFilter(filters) : filter
    );

    const getHrefMeetingFilterItem = filterId =>
        routeResolver.getRoute(NavigationPathBuilder.toSportMeetings(sportId, filterId));
    const onFilterClick = key => {
        setSelectedFilterKey(key);
        IntentActionTrigger.showSportMeetings(sportId, key);
        analyticsEmitter.emitAnalytics('matchesFilterClicked', {
            selectedFilterKey: key,
            sport: sportId,
        });
    };

    useLayoutEffect(() => {
        if (filterAutoSelect) {
            IntentActionTrigger.showSportMeetings(sportId, getPreSelectedFilter(filters), true);
        }
    }, [sportId, filterAutoSelect, filters]);

    return (
        <FlexPane config={config.container} class="ta-MeetingsView">
            <FlexPane config={config.filterContainer}>
                <FiltersBar
                    sportId={sportId}
                    filters={filters}
                    selectedFilterKey={selectedFilterKey}
                    onFilterClicked={onFilterClick}
                    getItemHrefLink={getHrefMeetingFilterItem}
                    config={config.filtersBar}
                />
            </FlexPane>
            <Outlet
                context={{
                    selectedFilterKey,
                    sportId,
                    raceCouponsConfig: config.raceCoupons,
                }}
            />
        </FlexPane>
    );
}
