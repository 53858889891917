import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import Image from 'presentation/components/image/index.jsx';

const RaceExplanationPane = props => {
    const {
        showBestOddsGuaranteed,
        mojitoTools: { config },
    } = props;

    const explanationsItemsDefault = [
        { iconConfig: config.finishedRaceIcon, textKey: 'RESULT' },
        { iconConfig: config.liveRaceIcon, textKey: 'RACE_OFF' },
    ];

    const explanationsItems = showBestOddsGuaranteed
        ? [
              ...explanationsItemsDefault,
              { iconConfig: config.bestOddsRaceIcon, textKey: 'BEST_ODDS' },
          ]
        : explanationsItemsDefault;

    return (
        <FlexPane class="ta-RaceExplanationPane" config={config.explanationItemsContainer}>
            {explanationsItems.map(({ iconConfig, textKey }) => (
                <ExplanationItem
                    key={textKey}
                    {...props}
                    iconConfig={iconConfig}
                    textKey={textKey}
                />
            ))}
        </FlexPane>
    );
};

const ExplanationItem = props => {
    const {
        iconConfig,
        textKey,
        mojitoTools: { config, stringResolver },
    } = props;
    return (
        <FlexPane config={config.explanationItem}>
            <Image config={iconConfig} />
            <Text config={config.explanationText}>
                {stringResolver.resolveString(`$RACE_EXPLANATION_PANE.${textKey}`)}
            </Text>
        </FlexPane>
    );
};

export default RaceExplanationPane;
