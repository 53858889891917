import {configureSportsbook} from '#core/application-initializer.js';
import {expandToStructure} from '#core/utils/config-utils.js';

import {getTokens} from './config/viewsconfig.js';
import translations from './config/translations.l10n.yaml'; // This will be transpiled by webpack.translations-loader
import defaultConfig from './config/default-config.yaml';
import fonts from './config/fonts.yaml';
import {THEME} from './config/theme';

function getMojitoConfig() {
    return {
        services: {
            configurations: {
                BetHistoryView: {
                    excludedStatusTypes: ['PLACED'],
                },
            },
        },
    };
}

configureSportsbook({
    getMojitoConfigFn: getMojitoConfig,
    getTokensFn: getTokens,
    configuration: {
        ...defaultConfig,
        ...expandToStructure(THEME),
        translations,
        fonts,
    },
});
