import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MojitoPresentation from 'mojito/presentation';
import SportGroupsListTypes from 'modules/sport-groups-list/types';
import SportGroupsListSkeleton from 'modules/sport-groups-list/skeleton/index.jsx';
import { useLocation } from 'react-router-dom';
import MojitoServices from 'mojito/services';
import SingleLeagueCouponTypes from 'modules/single-league-coupon/types.js';

const { selectItemsState } = MojitoServices.SportsContent.ContainerItems.selectors;
const { Text, FlexPane, Image, Button, LoaderSuspense } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const { IntentActionTrigger } = MojitoPresentation.Base.Intent;
const { SortingHelper, RacingHelper } = MojitoPresentation.Utils;
const { EventFormatter } = MojitoPresentation.Utils;

const {
    CONTENT_STATE: { UNKNOWN, PENDING },
} = MojitoServices.Common.types;
const { COUPONS } = SingleLeagueCouponTypes;

const getIcon = (sportId, { countryIcons, sportIcons }, country) =>
    countryIcons[country] || sportIcons[sportId] || sportIcons.defaultIcon;

const sortSportGroups = (sportGroups, language) =>
    [...sportGroups].sort((a, b) => SortingHelper.compare(a.name, b.name, language));

const SportGroupsListView = ({
    sportGroups,
    type,
    mojitoTools,
    sportId,
    pageType,
    groupId,
    onDataLoad,
}) => {
    const {
        config,
        appContext: { routeResolver, analyticsEmitter, systemSettings },
        stringResolver,
    } = mojitoTools;
    const isTopSportGroups = type === SportGroupsListTypes.TYPE.TOP;
    const headerText =
        stringResolver.resolveString(`$SPORT_GROUPS_LIST.${type}.${sportId}`, true) ||
        stringResolver.resolveString(`$SPORT_GROUPS_LIST.${type}.DEFAULT`);

    const [loadingAnimationDone, setLoadingAnimationDone] = useState(false);

    const createHref = typeId => {
        if (RacingHelper.isRacingSport(sportId)) {
            return routeResolver.getRoute(NavigationPathBuilder.toFutureRaceEvent(sportId, typeId));
        }
        if (pageType === COUPONS.COMPETITIONS) {
            return routeResolver.getRoute(
                NavigationPathBuilder.toSportCompetitionsLeague(sportId, typeId)
            );
        }
        if (pageType === COUPONS.OUTRIGHTS) {
            return routeResolver.getRoute(
                NavigationPathBuilder.toSportOutrightsLeague(sportId, typeId)
            );
        }
    };

    const onClick = typeId => {
        if (isTopSportGroups) {
            analyticsEmitter.emitAnalytics('topLeagueClick', typeId);
        }
        if (RacingHelper.isRacingSport(sportId)) {
            return IntentActionTrigger.showFutureRaceEventPage(sportId, typeId);
        }
        if (pageType === COUPONS.COMPETITIONS) {
            IntentActionTrigger.showSportCompetitions(sportId, typeId);
        } else if (pageType === COUPONS.OUTRIGHTS) {
            IntentActionTrigger.showSportOutrights(sportId, typeId);
        }
    };

    const isContentPending = useSelector(state => {
        const loadingState = selectItemsState(groupId, state);
        return loadingState === UNKNOWN || loadingState === PENDING;
    });

    const skeleton = (
        <SportGroupsListSkeleton
            headerText={headerText}
            isTopSportGroups={isTopSportGroups}
            config={config.sportGroupsListSkeleton}
        />
    );

    const onShowContent = () => {
        setLoadingAnimationDone(true);
    };

    useEffect(() => {
        if (!isContentPending && onDataLoad && sportGroups?.length) {
            onDataLoad(sportGroups);
        }
    }, [isContentPending, onDataLoad, sportGroups]);

    return (
        <LoaderSuspense
            config={config.skeletonLoader}
            isContentPending={isContentPending}
            loader={skeleton}
            onShowContent={onShowContent}
        >
            {sportGroups.length > 0 && (
                <FlexPane config={config.container} class="ta-SportGroupsListView">
                    {!RacingHelper.isRacingSport(sportId) && (
                        <FlexPane config={config.headerContainer}>
                            <Text config={config.headerText}>{headerText}</Text>
                        </FlexPane>
                    )}
                    <FlexPane config={config.mainContainer} class="ta-SportGroupsListViewContent">
                        {isTopSportGroups ? (
                            <TopSportGroups
                                sportId={sportId}
                                sportGroups={sportGroups}
                                config={config}
                                createHref={createHref}
                                onClick={onClick}
                            />
                        ) : (
                            <AllSportGroups
                                sportId={sportId}
                                sportGroups={sortSportGroups(
                                    sportGroups,
                                    systemSettings().language
                                )}
                                config={config}
                                createHref={createHref}
                                onClick={onClick}
                                viewable={loadingAnimationDone}
                            />
                        )}
                    </FlexPane>
                </FlexPane>
            )}
        </LoaderSuspense>
    );
};

const TopSportGroups = ({ sportGroups, config, createHref, onClick, sportId }) => (
    <FlexPane config={config.listContainer}>
        {sportGroups.map(({ name, id, country, className }) => (
            <SportGroup
                sportId={sportId}
                config={config}
                country={country}
                name={name}
                key={id}
                onClick={() => onClick(id)}
                hrefLink={createHref(id)}
                classname={className}
            />
        ))}
    </FlexPane>
);

const AllSportGroups = ({ sportGroups, config, createHref, onClick, sportId, viewable }) => {
    const selectedElementRef = useRef();
    const location = useLocation();

    const selectedSportGroupId = location.hash.slice(1);

    useEffect(() => {
        if (selectedSportGroupId && viewable) {
            const selectedSportGroupElement = selectedElementRef.current;
            selectedSportGroupElement?.scrollIntoView();
        }
    }, [selectedSportGroupId, viewable]);

    return sportGroups.map(({ name, id, items, country }) => (
        <div
            id={id}
            ref={element => {
                if (id === selectedSportGroupId) {
                    selectedElementRef.current = element;
                }
            }}
            key={id}
        >
            <FlexPane config={config.listContainer}>
                <FlexPane config={config.locationContainer}>
                    <CountryIcon config={config} country={country} sportId={sportId} />
                    <Text config={config.locationName}>{name}</Text>
                </FlexPane>

                {items?.map(sportGroup => (
                    <SportGroup
                        hideIcon={true}
                        config={config}
                        key={sportGroup.id}
                        name={sportGroup.name}
                        onClick={() => onClick(sportGroup.id)}
                        hrefLink={createHref(sportGroup.id)}
                    />
                ))}
            </FlexPane>
        </div>
    ));
};

const CountryIcon = ({ config, country, sportId }) => (
    <FlexPane config={config.iconContainer}>
        <Image src={getIcon(sportId, config, country)} config={config.countryIcon} />
    </FlexPane>
);

const SportGroup = ({ config, country, name, hrefLink, onClick, sportId, hideIcon, classname }) => {
    const labelText = classname ? `${classname} - ${name}` : EventFormatter.formatEventName(name);

    return (
        <Button
            config={config.sportGroupButton}
            class="ta-SportGroupsListButton"
            hrefLink={hrefLink}
            onClick={onClick}
        >
            {!hideIcon && <CountryIcon config={config} country={country} sportId={sportId} />}
            <Text config={config.sportGroupName}>{labelText}</Text>
            <Image config={config.chevronIcon} />
        </Button>
    );
};

export default SportGroupsListView;
