import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import {addParamToURL, getParamFromURL, removeParamFromURL} from '#core/utils/url-utils.js';
import {isMobile, isNative, getAdditionalContext} from '#core/utils/context-utils.js';
import {
    DBXCustomStrategies,
    OpenUrlStrategies,
    isValidCustomStrategy,
    isValidIntentStrategy,
} from '#core/utils/intent-validation.cjs';
import {INTENT_TYPES} from '#core/utils/intent-types.js';
import {getConfigByContext} from '#core/utils/config-utils.js';
import {ApplicationConfig} from '#core/config/application-config.js';
import {Localization} from '#core/localization.js';
import {openInternalIntent, openAccountPopupIntent, isValidIntent} from '#core/utils/intent-utils.js';
import {allFeatures} from '#core/application/abstract-feature.js';
import {allVisuals} from '#core/application/modules/visual-feature.js';
import {Intents} from '#core/application/intents/index.js';
import {Affiliates} from '#core/features/affiliates/index.js';
import {Logger} from '#core/utils/logger.js';

const log = Logger('IntentFactory');

const {actions: UserInfoDataActions} = MojitoServices.UserInfo;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const AuthenticationActions = MojitoServices.Authentication.actions;
const IntentActions = MojitoCore.Intents.actions;
const {dispatch} = MojitoCore.Services.redux.store;

export const OPEN_EXTERNAL_URL = 'OPEN_EXTERNAL_URL';

export const UPDATE_USER_BALANCE = () => dispatch(UserInfoDataActions.requestBalanceUpdate());
export const REDIRECT_TO_MY_ACCOUNT = () => dispatch(IntentActions.publishIntent(IntentTypes.SHOW_ACCOUNT));

export const LOGIN_INTO_ACCOUNT = () => dispatch(AuthenticationActions.restoreSession());
export const CHECK_IF_USER_LOGGED_IN = () => dispatch(AuthenticationActions.validateSession());

export const ON_CLOSE_MY_ACCOUNT = wrapOnCloseStrategies([REDIRECT_TO_MY_ACCOUNT, CHECK_IF_USER_LOGGED_IN]);
export const ON_CLOSE_CASHIER = wrapOnCloseStrategies([REDIRECT_TO_MY_ACCOUNT, UPDATE_USER_BALANCE]);

export const DEFAULT_INTENT_STRATEGY = 'auto';

function wrapOnCloseStrategies(onCloseStrategies) {
    const onCloseHandler =
        onCloseStrategies != null ? () => onCloseStrategies.forEach(strategy => strategy.call()) : () => {};
    return onCloseHandler;
}

// ------------

function parseUrl(urlConfig) {
    function removeEmptyParameters(url) {
        let [base, params] = url.split('?');
        let pairs = (params || '').split('&');
        params = pairs.reduce((result, pair) => {
            if (!pair) return result;
            let [key, value] = pair.split('=');
            if (!value) return result;
            return (result ? result + '&' : '') + key + '=' + value;
        }, '');
        return params ? base + '?' + params : base;
    }

    function processTemplate(str, vars) {
        str = str || '';
        for (let key in vars) {
            const value = vars[key];
            if (typeof value !== 'string') continue;
            str = str.split(`{{${key}}}`).join(value);
            str = str.replace(/\/\//g, '/').replace(/&&/g, '&').replace(/:\//, '://');
        }
        str = str.replace(/\?&/g, '&');
        return str;
    }

    const currentLanguageCode = Localization.getCurrentLanguageCode();
    let urlTemplate;
    let langToReplace = ApplicationConfig.templateProcessing.omitDefaultLanguage ? '' : currentLanguageCode;
    const affiliateId = Affiliates.affiliateId || '';

    if (typeof urlConfig === 'string') {
        urlTemplate = processTemplate(urlConfig, {lang: langToReplace, affiliateId});
    } else {
        urlTemplate = urlConfig[currentLanguageCode];
        urlTemplate = processTemplate(urlTemplate, {lang: langToReplace, affiliateId});
    }

    let values = ApplicationConfig.templateProcessing.values || ApplicationConfig.externalLinks.values; // if templateProcessing.values exists use it instead (this 'if' for backward compatibility)
    values = getConfigByContext(values, isNative() ? 'native' : isMobile() ? 'mobile' : 'desktop');

    let result = processTemplate(urlTemplate, values);
    result = ApplicationConfig.templateProcessing.omitEmptyParameters ? removeEmptyParameters(result) : result;
    return result;
}

function parseStrategy(str) {
    if (str === DEFAULT_INTENT_STRATEGY) {
        str = '101';
    }

    if (!isValidIntentStrategy(str)) {
        // regexp checks for all possible correct values
        log.warn(`Invalid strategy: "${str}. Fallback to "101"`);
        str = '101';
    }

    if (isNative()) return parseInt(str[2]);
    else if (isMobile()) return parseInt(str[1]);
    else return parseInt(str[0]);
}

function getFeatureByName(featureName) {
    const feature = allFeatures.featuresMap[featureName] || allVisuals.getFeatureByName(featureName);

    if (!feature || !feature.enabled) {
        log.error(`Feature "${featureName}" is disabled`);

        return null;
    }

    return feature;
}

function getFeatureIntent(featureName) {
    const {intent} = getFeatureByName(featureName) || {};

    return intent;
}

function getNamedIntent(intentName) {
    const intent = Intents[intentName];

    if (!intent) {
        log.error(`Named intent "${intentName}" not found`);

        return null;
    }

    return intent;
}

export function getExtendedFrameIntentData(data = {}, title, onClose) {
    const newData = {...data};

    if (isNative()) {
        // This strategy open link in a 2nd view of the app on top with X button to close it
        newData.url = addParamToURL(newData.url, 'openExternal', 'webview');
        newData.url = addParamToURL(newData.url, 'title', title ? Localization.getTranslation(title) : '');
    } else {
        newData.header = {label: title || ''};
        newData.onClose = onClose;
    }

    return newData;
}

export function convertStringIntentToObject(rawIntent = '') {
    if (typeof rawIntent === 'string') {
        let [openStrategy, urlTemplate, params] = rawIntent.split('|');

        if (!urlTemplate) {
            // case when only url is specified
            urlTemplate = openStrategy;
            openStrategy = DEFAULT_INTENT_STRATEGY;
        }

        return {openStrategy, urlTemplate, params};
    }

    return rawIntent;
}

export function getExternalUrlIntent(urlConfig, title, onClose = null) {
    if (!urlConfig) {
        throw new Error(`Probably missing config key. Linked label: ${title}`);
    }
    let {openStrategy, subStrategy, urlTemplate, params} = convertStringIntentToObject(urlConfig);
    const url = parseUrl(urlTemplate);

    if (isValidCustomStrategy(openStrategy)) {
        switch (openStrategy) {
            case DBXCustomStrategies.INTERNAL:
                if (params) {
                    params = parseUrl(params).split(',');
                }

                return openInternalIntent(urlTemplate, params);
            case DBXCustomStrategies.ACCOUNT_POPUP:
                // This strategy is used to open My Account modal.
                // Example: `accountPopup|bet-history`
                return openAccountPopupIntent(urlTemplate);
            case DBXCustomStrategies.FEATURE:
                return getFeatureIntent(urlTemplate);
            case DBXCustomStrategies.NAMED_INTENT:
                return getNamedIntent(urlTemplate);
            case DBXCustomStrategies.NAVIGATE_HOST:
                return {
                    type: INTENT_TYPES.NAVIGATE_HOST,
                    data: {url: url},
                };
            default:
                return null;
        }
    }

    const strategy = parseStrategy(openStrategy);
    const intent = {
        type: OPEN_EXTERNAL_URL,
        data: {url: url},
        strategy,
    };

    // TODO: Move this switch to portal store (where is OPEN_EXTERNAL_URL)
    // Cannot do it now until lot of places where '.url' of intent is used
    // So lot of places should be refactored to use pure intent instead of '.url' hacks
    switch (strategy) {
        case OpenUrlStrategies.SAME: // same window - 0
            if (isMobile() || isNative()) {
                // This strategy open link in the same view with back button
                intent.data.addBackUrl = true;
            }
            break;
        case OpenUrlStrategies.SAME_NO_BACK: // no back url
            // do nothing
            break;
        case OpenUrlStrategies.FRAME: // iframe / webview - 1
            intent.type = IntentTypes.SHOW_FOREIGN_WINDOW;
            intent.data = getExtendedFrameIntentData(intent.data, title, onClose);

            break;
        case OpenUrlStrategies.BROWSER: // external / browser - 2
            if (isNative()) {
                // This strategy open link in browser outside the app
                intent.data.url = addParamToURL(intent.data.url, 'openExternal', 'browser');
            } else {
                intent.data.windowName = '_blank';
            }
            break;
        case OpenUrlStrategies.NATIVE_CASINO: // Native casino - 3
            intent.data.url = addParamToURL(intent.data.url, 'openExternal', 'casino');
            break;
        case OpenUrlStrategies.NATIVE_GAMES: // Native games - 4
            subStrategy = getParamFromURL('game', intent.data.url);
            intent.data.url = removeParamFromURL(intent.data.url, 'game');
            intent.data.url = addParamToURL(
                intent.data.url,
                'openExternal',
                subStrategy ? 'games&' + subStrategy : 'games'
            );
            break;
        case OpenUrlStrategies.NATIVE_WEBVIEW: // Native webview - 7
            intent.data.url = addParamToURL(intent.data.url, 'openExternal', 'webview');
            intent.data.versionedApiMessage = true;
            break;
        case OpenUrlStrategies.WINDOW: // Separate window above original - 6
            intent.data.windowName = '_blank';
            intent.data.windowWidth = urlConfig.windowWidth || 992;
            break;
        default:
            log.error(`Unknown strategy: "${strategy}" for url:${urlTemplate}.`);
    }

    return intent;
}

// Made it as class to allow easily navigation in IDE
export class DBXStandardIntents {
    init() {
        intentInstance = this;

        // TODO: Not all clients have link to DEPOSIT
        // Please keep intents and their translation keys clean and generic.
        // Do not mix it with customer specific ones
        this.MY_ACCOUNT = {};

        this.INFO = {};
    }
}

function getCommonLinkItemConfig(linkItem) {
    let {label, intent, hide, icon, hrefLink, ...rest} = linkItem;

    let config = {...rest};

    config.label = `${label}`;
    config.hide = hide;
    intent ? (config.intent = getExternalUrlIntent(intent, `${label}`)) : null;

    if (config.intent && config.intent.data) {
        config.hrefLink = config.intent.data.url;
    }

    if (icon) {
        config.icon = icon;
        config.image = icon;
    }

    hrefLink ? (config.hrefLink = hrefLink) : null;

    return config;
}

export function buildAccountItemsFromConfig(linksConfig) {
    let result = null;

    function buildMenuItem(item) {
        let additionalContext = getAdditionalContext();

        item = getConfigByContext(item, additionalContext ? additionalContext : isMobile() ? 'mobile' : 'desktop');
        let {id, items} = item;
        let config = getCommonLinkItemConfig(item);

        if (config.intent && config.intent.data) {
            config.url = config.intent.data.url;
        }

        id ? (config.id = id) : null;

        if (items && items.length > 0) {
            config.items = items.map(menuItem => {
                return buildMenuItem(menuItem);
            });
            config.items = config.items.filter(intentConfig => Boolean(intentConfig.hide) === false);
        }

        return config;
    }

    if (!linksConfig) {
        return [];
    }

    result = linksConfig.map(intentConfig => buildMenuItem(intentConfig));

    result = result.filter(intentConfig => Boolean(intentConfig.hide) === false);
    return result;
}

//Returns array of intents, in case of undefined config, empty array returned
export function buildIntentsFromConfig(linksConfig) {
    let additionalContext = getAdditionalContext();
    let result = null;

    if (!linksConfig) {
        return [];
    }

    linksConfig = linksConfig.map(link =>
        getConfigByContext(link, additionalContext ? additionalContext : isMobile() ? 'mobile' : 'desktop')
    );
    linksConfig = linksConfig.filter(link => link.hide !== true);
    result = linksConfig.map(intentConfig => getCommonLinkItemConfig(intentConfig));
    result = result.filter(intentConfig => Boolean(intentConfig.hide) === false && isValidIntent(intentConfig.intent));

    return result;
}

export function getIntent() {
    return intentInstance;
}

let intentInstance = {};
