import { isAnyOf } from '@reduxjs/toolkit';
import MojitoCore from 'mojito/core';
import MojitoModules from 'mojito/modules';
import MojitoServices from 'mojito/services';
import MojitoPresentation from 'mojito/presentation';
import { isEmpty, first } from 'mojito/utils';
import {
    selectActiveSlidingViewType,
    selectActiveSlidingViewData,
} from 'application/stores/application/selectors.js';
import { selectRoute } from 'application/stores/router/selectors.js';
import { isQuickBetslipActive } from 'application/stores/application/utils.js';
import prebuiltAnalyticsListener from 'application/analytics/prebuilt-analytics-listener.js';

const AbstractAnalyticsService = MojitoServices.Analytics.AbstractAnalyticsService;

const searchActions = MojitoServices.Search.actions;
const authenticationActions = MojitoServices.Authentication.actions;
const matchAccaActions = MojitoServices.MatchAcca.actions;
const { actions: bonusFreeBetsActions, types: BonusFreeBetsTypes } = MojitoServices.BonusFreeBets;
const { selectLanguage, subscribe: subscribeSystemSettings } =
    MojitoCore.Services.SystemSettings.selectors;
const {
    actions: betslipActions,
    utils: BetslipUtils,
    types: BetslipTypes,
    selectors: { selectBetslip, selectBetslipMode, selectActiveStakeGroup },
} = MojitoServices.Betslip;
const {
    openBetsSlice: { actions: openBetsActions },
    openBetsSelectors: { selectBetCount, selectBet, selectCashoutStatus },
    utils: BetsUtils,
    types: BetsTypes,
} = MojitoServices.Bets;
const {
    actions: sportsMenuActions,
    selectors: { selectFavoriteSports },
} = MojitoModules.SportsMenu;
const {
    actions: cookieConsentActions,
    selectors: { selectCookieConsent },
} = MojitoCore.Services.CookieConsent;
const {
    actions: userInfoActions,
    selectors: { selectCurrency, selectUserInfo, selectUserName },
} = MojitoServices.UserInfo;
const {
    actions: userSettingsActions,
    selectors: { selectOddsFormat },
} = MojitoServices.UserSettings;
const {
    utils: EventUtils,
    selectors: { selectEventItem, selectMarket, selectSelection },
} = MojitoServices.SportsContent.Events;

const log = MojitoCore.logger.get();
const reduxInstance = MojitoCore.Services.redux;
const intentActions = MojitoCore.Intents.actions;
const CurrencyConfig = MojitoCore.Services.CurrencyConfig;
const IntentTypes = MojitoPresentation.Base.Intent.Types;
const analyticsReporter = MojitoServices.Analytics.reporter;
const AnalyticsTypes = MojitoServices.Analytics.types;
const { BETSLIP_TYPE: BETSLIP_VIEW_TYPES } = MojitoModules.SlidingBetslip.types;
const { FREE_BETS_CODE_STATE } = BonusFreeBetsTypes;
const { PREBUILT_MATCH_ACCA, PRICE_BOOST } = AnalyticsTypes.SELECTION_TYPE;
const { types: MarketGroupTypes } = MojitoServices.SportsContent.MarketGroups;
const { GROUP_TYPES } = MarketGroupTypes;

const STANDARD = 'standard';
const NOT_AVAILABLE = 'n/a';

/**
 * A class for analytics listener to listen redux actions and/or store changes.
 *
 * @class AnalyticsListener
 * @memberof Mojito.Application.Analytics
 */
class AnalyticsListener {
    constructor() {
        this.language = '';
        this.oddsFormat = '';
        this.balance = '';
        this.username = '';
        this.currency = '';
    }

    /**
     * The `init` method begins the process of analytics by accepting a history object.
     *
     * @param {object} history - History object to listen to for route changes.
     * @function Mojito.Application.Analytics.AnalyticsListener#init
     */
    init(history) {
        if (selectCookieConsent()) {
            const currentPath = selectRoute();
            analyticsReporter.activate(currentPath);
        }

        // Navigation - Listen to route changes and back navigation
        history.listen(({ location }) => analyticsReporter.navigation(location.pathname));

        // Language - Get initial state and setup listener
        this.language = selectLanguage();
        subscribeSystemSettings('language', newLanguage => {
            analyticsReporter.languageChanged(this.language, newLanguage);
            this.language = newLanguage;
        });

        this.oddsFormat = selectOddsFormat();
        this.username = selectUserName();
        this.currency = this.getCurrency();

        analyticsReporter.setUsername(this.username);
        analyticsReporter.setCurrency(this.currency);

        this.startListening();
    }

    resolveContentNodes(eventId, marketId, selectionId) {
        const event = selectEventItem(eventId) || {};
        const market = selectMarket(marketId) || {};
        const selection = selectSelection(marketId, selectionId) || {};
        return { event, market, selection };
    }

    getWithdrawableBalance() {
        const { balances } = selectUserInfo();
        return balances && balances.withdrawable;
    }

    reportPartRemoved(bet, selectionId, source = 'Betslip') {
        // Part can only belong to one event and market.
        const eventId = BetslipUtils.getEventIdsFromBet(bet)[0];
        const marketId = BetslipUtils.getMarketIdsFromBet(bet)[0];
        const { event, market, selection } = this.resolveContentNodes(
            eventId,
            marketId,
            selectionId
        );

        const price = EventUtils.getPrice(selection.prices);
        const selectionPrice = (price && price.decimalLabel) || NOT_AVAILABLE;
        analyticsReporter.selectionRemovedFromBetslip({
            selectionId,
            selectionPrice,
            selectionLabel: selection.canonicalName || NOT_AVAILABLE,
            selectionSource: source,
            eventName: event.canonicalName || NOT_AVAILABLE,
            marketName: market.canonicalName || NOT_AVAILABLE,
        });
    }

    reportCompositePartRemoved(bet, selectionIds, source = 'Betslip') {
        // Composite can only belong to one event.
        const eventId = BetslipUtils.getEventIdsFromBet(bet)[0];
        const marketIds = BetslipUtils.getMarketIdsFromBet(bet);
        const parents = marketIds.map(marketId => this.resolveContentNodes(eventId, marketId));
        const markets = parents.map(parent => parent.market);

        const eventName = (first(parents) && first(parents).event.canonicalName) || NOT_AVAILABLE;
        const marketNames = markets.map(market => market.canonicalName || NOT_AVAILABLE);

        const selectionNames = selectionIds.map(selectionId => {
            const marketWithSelection = markets.find(market => market.selectionMap[selectionId]);
            const selection =
                (marketWithSelection && marketWithSelection.selectionMap[selectionId]) || {};
            return selection.canonicalName || NOT_AVAILABLE;
        });

        analyticsReporter.selectionRemovedFromBetslip({
            selectionId: selectionIds.join(','),
            selectionPrice: bet.exactOdds || bet.odds || NOT_AVAILABLE,
            selectionLabel: selectionNames.join(','),
            selectionSource: source,
            eventName,
            marketName: marketNames.join(','),
        });
    }

    getCurrentBetslipType() {
        const slidingViewType = selectActiveSlidingViewType();
        const slidingViewData = selectActiveSlidingViewData();
        const isQuickBetslip = isQuickBetslipActive(slidingViewType, slidingViewData);
        return isQuickBetslip
            ? BETSLIP_VIEW_TYPES.QUICK_BETSLIP
            : BETSLIP_VIEW_TYPES.STANDARD_BETSLIP;
    }

    getCurrency() {
        const { defaultCurrencyCode } = CurrencyConfig.getCurrencies();
        const userCurrency = selectCurrency();
        return userCurrency || defaultCurrencyCode;
    }

    startListening() {
        reduxInstance.actionListener.startListening({
            actionCreator: searchActions.deliverResult,
            effect: action => {
                const { result, query } = action.payload;
                const { error, items, hasNextPage } = result;
                if (!error) {
                    analyticsReporter.searchPerformed(query, items.length, hasNextPage);
                }
            },
        });

        reduxInstance.actionListener.startListening({
            matcher: isAnyOf(
                authenticationActions.loginSuccess,
                authenticationActions.logoutSuccess,
                authenticationActions.loginFailed,
                authenticationActions.disposeSession,
                userInfoActions.updateBalance
            ),
            effect: () => {
                const username = selectUserName();
                const currency = this.getCurrency();
                const balance = this.getWithdrawableBalance();

                if (this.username !== username) {
                    analyticsReporter.setUsername(username);
                    this.username = username;
                }

                if (this.currency !== currency) {
                    analyticsReporter.setCurrency(currency);
                    this.currency = currency;
                }

                // Update balance if changed and user logged in
                if (balance && this.balance !== balance) {
                    analyticsReporter.onBalanceUpdate(balance);
                }

                this.balance = balance;
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: bonusFreeBetsActions.addFreeBetFailure,
            effect: action => {
                let error;
                const { errorCode, voucherCode } = action.payload;

                switch (errorCode) {
                    case FREE_BETS_CODE_STATE.USED:
                        error = 'Used code';
                        break;
                    case FREE_BETS_CODE_STATE.INVALID:
                        error = 'Invalid code';
                        break;
                    case FREE_BETS_CODE_STATE.REDEEMED:
                        error = 'Redeemed code';
                        break;
                    case FREE_BETS_CODE_STATE.UNKNOWN:
                    // fallthrough
                    default:
                        error = 'Unknown error';
                }

                analyticsReporter.addFreebetVoucherFailure({ code: voucherCode, error });
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.loginSuccess,
            effect: action => {
                const { userInfo = {} } = action.payload;
                const { userName } = userInfo;

                // Notify
                analyticsReporter.loggedIn(userName);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.logoutSuccess,
            effect: () => analyticsReporter.loggedOut(),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.loginFailed,
            effect: ({ payload: error }) => {
                // Notify
                analyticsReporter.loginFailed(error);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: authenticationActions.unexpectedSessionLost,
            effect: () => analyticsReporter.unexpectedSessionLost(),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: intentActions.publishIntent,
            effect: action => {
                const { type, data } = action.payload;
                switch (type) {
                    case IntentTypes.SHOW_BETSLIP: {
                        analyticsReporter.betslipTabChanged(
                            AbstractAnalyticsService.BETSLIP.TAB.BETSLIP
                        );
                        break;
                    }
                    case IntentTypes.SHOW_OPEN_BETS: {
                        analyticsReporter.betslipTabChanged(
                            AbstractAnalyticsService.BETSLIP.TAB.OPEN_BETS,
                            selectBetCount()
                        );
                        break;
                    }
                    case IntentTypes.SHOW_SLIDING_AZ_MENU: {
                        analyticsReporter.sportsSideMenuOpenStateChanged(true);
                        break;
                    }
                    case IntentTypes.HIDE_SLIDING_AZ_MENU: {
                        analyticsReporter.sportsSideMenuOpenStateChanged(false);
                        break;
                    }
                    case IntentTypes.SHOW_QUICK_LEAGUES_NAVIGATION: {
                        analyticsReporter.leaguesQuickNavigationOpened();
                        break;
                    }
                    case IntentTypes.HIDE_QUICK_LEAGUES_NAVIGATION: {
                        analyticsReporter.leaguesQuickNavigationClosed();
                        break;
                    }
                    case IntentTypes.SHOW_QUICK_EVENTS_NAVIGATION: {
                        analyticsReporter.eventsQuickNavigationOpened({
                            sportId: data.sportId,
                            eventType: data.eventType,
                            eventName: data.eventName,
                            isInPlay: data.isInPlay,
                        });
                        break;
                    }
                    case IntentTypes.HIDE_QUICK_EVENTS_NAVIGATION: {
                        analyticsReporter.eventsQuickNavigationClosed();
                        break;
                    }
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: sportsMenuActions.toggleFavoriteSport,
            effect: ({ payload: sportId }) => {
                const isAdded = selectFavoriteSports().includes(sportId);
                if (isAdded) {
                    analyticsReporter.addSportToFavourites(sportId);
                } else {
                    analyticsReporter.removeSportFromFavourites(sportId);
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: userSettingsActions.updateOddsFormat,
            effect: ({ payload: newOddsFormat }) => {
                analyticsReporter.oddsFormatChanged(this.oddsFormat, newOddsFormat);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: cookieConsentActions.giveConsent,
            effect: () => {
                if (selectCookieConsent()) {
                    const currentPath = selectRoute();
                    analyticsReporter.activate(currentPath);
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.activateStakeGroupPending,
            effect: ({ payload: stakeGroupName }) => {
                const groupName =
                    stakeGroupName === BetslipTypes.STAKE_GROUP_NAME.DEFAULT
                        ? STANDARD
                        : stakeGroupName;
                analyticsReporter.betslipStakeGroupChanged(groupName, selectBetslipMode());
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.activateTeaserTypePending,
            effect: ({ payload: teaserType }) => {
                analyticsReporter.betslipTeaserTypeChanged(teaserType);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.addPartSuccess,
            effect: action => {
                const { requestPayload } = action.payload;
                const { selection, parent, source, bettingContext } = requestPayload || {};
                if (!selection || !parent) {
                    log.warn('selectionAddedToBetslip not reported!');
                    return;
                }

                const { id, odds } = selection;

                const { eventId, marketId } = parent;
                const {
                    event,
                    market,
                    selection: selectionData,
                } = this.resolveContentNodes(eventId, marketId, id);

                const isPrebuiltMatchAcca =
                    bettingContext === BetslipTypes.BETTING_CONTEXT.PRE_BUILT_MATCH_ACCA_BET;
                const isPriceBoost = !!selection.odds.base;
                const selectionType =
                    (isPrebuiltMatchAcca && { selectionType: PREBUILT_MATCH_ACCA }) ||
                    (isPriceBoost && { selectionType: PRICE_BOOST }) ||
                    {};

                analyticsReporter.selectionAddedToBetslip({
                    marketId,
                    marketName: market.name,
                    selectionLabel: selectionData.name,
                    selectionId: id,
                    selectionPrice: odds.current,
                    selectionSource: source,
                    eventId,
                    eventName: event.name,
                    ...selectionType,
                });
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.addCompositeSuccess,
            effect: action => {
                const { requestPayload, betslip } = action.payload;
                const { odds, selections, legSort, source, parent } = requestPayload || {};
                if (!selections) {
                    log.warn('compositeAddedToBetslip not reported!');
                    return;
                }
                if (legSort === BetsTypes.LEG_SORT.MATCH_ACCA) {
                    analyticsReporter.addMatchAccaToBetslip(
                        odds,
                        selections.length,
                        this.getWithdrawableBalance()
                    );
                } else {
                    // This execution flow suppose to happen only for adding FORECAST and TRICAST bets
                    const selectionIdsInMulticast = selections.map(selection => selection.id);
                    const partsInBetslip = BetslipUtils.getParts(betslip);
                    const partsInBet = selectionIdsInMulticast.map(selectionId =>
                        partsInBetslip.find(part => part.selectionId === selectionId)
                    );

                    const selectionsLabel = partsInBet.map(part => part.selectionName).join(', ');
                    const selectionsId = partsInBet.map(part => part.selectionId).join(', ');
                    const bettingContext = AnalyticsTypes.SELECTION_TYPE[legSort];
                    const selectionType = bettingContext ? { selectionType: bettingContext } : {};

                    // Multicast bets are built from the same market and event, so it is safe to take this info from the first part.
                    const { marketId, marketName, eventName } = partsInBet[0].partInfo;

                    analyticsReporter.selectionAddedToBetslip({
                        eventId: parent.eventId,
                        eventName,
                        marketId,
                        marketName,
                        selectionId: selectionsId,
                        selectionLabel: selectionsLabel,
                        selectionSource: source,
                        ...selectionType,
                    });
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.removePartSuccess,
            effect: action => {
                const { requestPayload } = action.payload;
                const { selectionId, bet, source } = requestPayload || {};
                if (!selectionId || !bet) {
                    log.warn('selectionRemovedFromBetslip not reported!');
                    return;
                }
                this.reportPartRemoved(bet, selectionId, source);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.removeCompositeSuccess,
            effect: action => {
                const { requestPayload } = action.payload;
                const { selectionIds, bet, source } = requestPayload || {};
                if (!selectionIds || !bet) {
                    log.warn('compositeRemovedFromBetslip not reported!');
                    return;
                }
                this.reportCompositePartRemoved(bet, selectionIds, source);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.placeBetslipAttempt,
            effect: () => {
                const stakeGroup = selectActiveStakeGroup();
                const betslip = selectBetslip();
                const { stake } = BetslipUtils.getCalculation(betslip, stakeGroup);
                const singleBetsCount = BetslipUtils.getSingleBetsCount(betslip);
                const balance = this.getWithdrawableBalance();
                const betslipType = this.getCurrentBetslipType();
                analyticsReporter.placingBet({
                    totalStake: stake,
                    betslipSelectionCount: singleBetsCount,
                    userBalance: balance,
                    betslipType,
                });
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.placeBetslipSuccess,
            effect: action => {
                const { receipt = {} } = action.payload;
                const { bets = [] } = receipt;
                const balance = this.getWithdrawableBalance();

                // Get unique selections
                const selectionIds = bets.reduce((ids, bet) => {
                    const parts = bet.legs.flatMap(leg => leg.parts);
                    parts.forEach(part => ids.add(part.selectionId));
                    return ids;
                }, new Set());

                bets.forEach(bet => {
                    const { funds, betType, legs } = bet;
                    const baseOdds = legs.find(leg => leg.baseOdds)?.baseOdds;
                    const odds = legs.find(leg => leg.odds)?.odds;
                    const isMatchAcca = BetsUtils.hasMatchAccaLeg(bet);
                    const betslipType = this.getCurrentBetslipType();
                    const accaBoost =
                        BetslipUtils.betHasAccaBoost(bet) &&
                        BetslipUtils.getBetAccaBoost(bet).value;
                    const isPreBuiltMatchAcca =
                        isMatchAcca && prebuiltAnalyticsListener.getPreBuiltStatus();
                    const buildType =
                        (isPreBuiltMatchAcca && { buildType: GROUP_TYPES.PREBUILT_MATCH_ACCA }) ||
                        (isMatchAcca && { buildType: GROUP_TYPES.MATCH_ACCA }) ||
                        {};
                    analyticsReporter.betPlaced({
                        totalStake: funds.stake,
                        betType,
                        selectionCount: selectionIds.size,
                        userBalance: balance,
                        odds,
                        freebetStake: funds.freebetStake,
                        betslipType,
                        accaBoost,
                        baseOdds,
                        ...buildType,
                    });
                });
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.clearBetslipPending,
            effect: () => {
                const betslip = selectBetslip();
                const bets = BetslipUtils.getSingleBets(betslip);
                bets.forEach(bet => {
                    if (bet.betType === BetsTypes.LEG_SORT.MATCH_ACCA) {
                        const selectionIds = BetslipUtils.getSelectionIdsFromBet(bet);
                        this.reportCompositePartRemoved(bet, selectionIds);
                    } else {
                        const { selectionId } = BetslipUtils.getFirstBetPart(bet);
                        this.reportPartRemoved(bet, selectionId);
                    }
                });
                analyticsReporter.removeAllInBetslip(bets.length);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.toggleFreeBetSuccess,
            effect: action => {
                const { token, name, shouldRemove } = action.payload;
                if (shouldRemove) {
                    analyticsReporter.removeFreebetVoucherSuccess(token);
                } else {
                    analyticsReporter.addFreebetVoucherSuccess(token, name);
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.betslipResponseFailed,
            effect: () => {
                const betslipType = this.getCurrentBetslipType();
                analyticsReporter.betslipRequestFailed(betslipType);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.acceptChanges,
            effect: () => {
                const betslipType = this.getCurrentBetslipType();
                analyticsReporter.acceptChanges(betslipType);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.bankersModeChange,
            effect: action => {
                const { active, manual } = action.payload;
                manual && analyticsReporter.bankersSwitchToggled(active);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: betslipActions.toggleBankerPending,
            effect: ({ payload: betId }) => {
                const { bets } = selectBetslip();
                const bankerBet = bets.find(({ id }) => id === betId);
                analyticsReporter.bankersBetToggled(bankerBet.id, !bankerBet.isBanker);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: matchAccaActions.addSelection,
            effect: action => {
                const { selection, parent, source } = action.payload;
                const { id: selectionId, odds } = selection;
                const { eventId, marketId } = parent;
                const {
                    event,
                    market,
                    selection: selectionData,
                } = this.resolveContentNodes(eventId, marketId, selectionId);

                analyticsReporter.matchAccaAddSelection(
                    source,
                    selectionId,
                    odds.current,
                    event.canonicalName,
                    selectionData.canonicalName,
                    market.canonicalName
                );
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: matchAccaActions.removeSelection,
            effect: action => {
                const { parent, selectionId, source } = action.payload;
                const { eventId, marketId } = parent;
                const { selection: selectionData } = this.resolveContentNodes(
                    eventId,
                    marketId,
                    selectionId
                );
                const price = EventUtils.getPrice(selectionData.prices);
                const odds = (price && price.decimalLabel) || NOT_AVAILABLE;
                analyticsReporter.removeMatchAccaSelection(source, selectionId, odds);
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: matchAccaActions.clear,
            effect: () => analyticsReporter.clearMatchAccaSlip(),
        });

        reduxInstance.actionListener.startListening({
            actionCreator: openBetsActions.confirmCashout,
            effect: action => {
                const { betId, amount } = action.payload;
                const status = selectCashoutStatus(betId);

                if (status === BetsTypes.CASHOUT_STATUS.CONFIRMATION) {
                    const cashoutBet = selectBet(betId) || {};
                    const { betType, status: betStatus, funds } = cashoutBet;
                    analyticsReporter.initCashOut({
                        betId,
                        cashoutValue: amount,
                        numberSelections: BetsUtils.getAllParts(cashoutBet).length,
                        betType: betType || NOT_AVAILABLE,
                        betStatus: betStatus || NOT_AVAILABLE,
                        payoutValue: funds ? funds.payout : NOT_AVAILABLE,
                    });
                } else {
                    analyticsReporter.cashoutConfirmed(amount, betId);
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: openBetsActions.cashoutSuccess,
            effect: ({ payload: cashoutInfo }) => {
                const { betId, amount, errors } = cashoutInfo;
                if (isEmpty(errors)) {
                    analyticsReporter.cashedOut(
                        AbstractAnalyticsService.CASHOUT.STATUS.OK,
                        amount,
                        betId
                    );
                } else {
                    analyticsReporter.cashedOut(
                        AbstractAnalyticsService.CASHOUT.STATUS.FAILED,
                        betId
                    );
                }
            },
        });

        reduxInstance.actionListener.startListening({
            actionCreator: openBetsActions.cashoutFailed,
            effect: ({ payload: betId }) => {
                analyticsReporter.cashedOut(AbstractAnalyticsService.CASHOUT.STATUS.FAILED, betId);
            },
        });
    }
}

export default new AnalyticsListener();
