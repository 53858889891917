import FlexPane from 'presentation/components/flex-pane/index.jsx';
import Image from 'presentation/components/image/index.jsx';
import Text from 'presentation/components/text/index.jsx';
import ButtonBar from 'presentation/components/button-bar/index.jsx';
import ExpandableView from 'presentation/components/expandableview/index.jsx';
import RaceMeetingCoupon from 'presentation/components/race-meeting-coupon/index.jsx';
import RaceExplanationPane from 'presentation/components/race-explanation-pane/index.jsx';
import RacingHelper from 'presentation/formatters/racing/index.js';

const getIcon = (sportId, { countryIcons, sportIcons }, country) =>
    countryIcons[country] || sportIcons[sportId] || sportIcons.defaultIcon;

const CollapsibleMeetingPane = props => {
    const {
        sportId,
        countryIcon,
        countryName,
        initiallyExpanded,
        filters,
        selectedFilter,
        filterClickedCb,
    } = props;
    const {
        mojitoTools: { config },
    } = props;
    const onTabSelectionClick = selectionId => filterClickedCb(selectionId);

    const renderHeader = () => (
        <>
            {config.shouldShowFlag && (
                <CountryIcon config={config} country={countryIcon} sportId={sportId} />
            )}
            <Text config={config.countryName}>{countryName}</Text>
        </>
    );

    return (
        <FlexPane config={config.container}>
            <ExpandableView
                config={config.expandableView}
                expanded={initiallyExpanded}
                headerRenderer={renderHeader}
            >
                <FlexPane config={config.contentContainer}>
                    {filters && (
                        <ButtonBar
                            items={filters}
                            selectedKey={selectedFilter}
                            onSelectionChanged={onTabSelectionClick}
                            config={config.locationsButtonBar}
                        />
                    )}
                    <Meetings {...props} />
                </FlexPane>
            </ExpandableView>
        </FlexPane>
    );
};

const Meetings = props => {
    const {
        allowBestOddsGuaranteed,
        meetings,
        mojitoTools: { config },
    } = props;
    const hasBestOddsGuaranteed = RacingHelper.hasBestOddsGuaranteed(meetings);
    const showBestOddsGuaranteed = allowBestOddsGuaranteed && hasBestOddsGuaranteed;
    const meetingContent = meetings.map(meeting => (
        <MeetingCoupon
            {...props}
            key={meeting.id}
            meeting={meeting}
            allowBestOddsGuaranteed={allowBestOddsGuaranteed}
        />
    ));

    return (
        <FlexPane config={config.meetingsContainer}>
            <FlexPane config={config.meetingContentContainer}>{meetingContent}</FlexPane>
            <RaceExplanationPane
                config={config.raceExplanationPane}
                showBestOddsGuaranteed={showBestOddsGuaranteed}
            />
        </FlexPane>
    );
};

const MeetingCoupon = ({
    meeting,
    allowBestOddsGuaranteed,
    onRaceClicked,
    mojitoTools: { config, stringResolver },
}) => {
    const { races, streamAvailable, raceTrackStatus } = meeting;

    let trackStatus;
    if (races.length) {
        const trackCondition = (raceTrackStatus.trackCondition || '').trim();
        if (trackCondition) {
            trackStatus = `${stringResolver.resolveString(
                '$COLLAPSIBLE_MEETING_PANE.TRACK_CONDITION_PREFIX'
            )}${trackCondition}`;
        }
    }

    return (
        <RaceMeetingCoupon
            meetingName={meeting.name}
            meetingId={meeting.id}
            races={races}
            cbRaceClicked={onRaceClicked}
            trackStatus={trackStatus}
            streamAvailable={streamAvailable}
            allowBestOddsGuaranteed={allowBestOddsGuaranteed}
            config={config.raceMeetingCoupon}
        />
    );
};

const CountryIcon = ({ config, country, sportId }) => (
    <FlexPane config={config.countryIconContainer}>
        <Image src={getIcon(sportId, config, country)} config={config.countryIcon} />
    </FlexPane>
);

export default CollapsibleMeetingPane;
