export {Application} from './application.js';

import './actions-proxy/index.js';
import './analytics-proxy/index.js';
import './balances/index.js';
import './embedded-app/index.js';
import './external-navigation/index.js';
import './intents/index.js';
import './mojito-fixes/index.js';
import './multi-jurisdiction/index.js';
import './seo/index.js';
import './routes/index.js';
import './widgets-provider/index.js';
import './user-settings/index.js';
import './currencies/index.js';
import './other-configs/index.js';
import './reloader/index.js';

export const applicationModulesPromise = import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "sportsbook.dbx" */
    /* webpackPreload: true */
    './modules/index.js'
);
