import {registerTileSchema} from '#core/application/schema-registry.js';

import {resolveBackground} from '#core/utils/color-utils.js';
import {isIOS} from '#core/utils/context-utils.js';

import INPUT from './input.palette-schema.yaml';

registerTileSchema(INPUT);

export function generateInput(PALETTE) {
    const {FONT} = PALETTE;

    return {
        style: {
            base: {
                backgroundColor: 'transparent',
                ...resolveBackground(PALETTE.INPUT_BG),
                fontSize: FONT.SIZE_MEDIUM,
                _mobile_fontSize: isIOS() ? 16 : FONT.SIZE_MEDIUM, // increased font size to avoid automatic zoom-in on iOS devices
                fontWeight: FONT.WEIGHT_NORMAL,
                borderColor: PALETTE.INPUT_BORDER_COLOR,
                minHeight: 40,
                padding: '0 8px',
                borderWidth: 1,
                borderRadius: PALETTE.INPUT_BORDER_RADIUS,
                borderStyle: 'solid',
                outline: 'none',
                ...PALETTE.INPUT_TEXT,
            },
            placeholder: PALETTE.INPUT_PLACEHOLDER_TEXT,
            focus: {
                borderColor: PALETTE.INPUT_FOCUSED_BORDER_COLOR,
            },
            error: {
                ...PALETTE.INPUT_ERROR_TEXT,
                borderColor: PALETTE.INPUT_ERROR_BORDER_COLOR,
            },
            disabled: {
                ...resolveBackground(PALETTE.INPUT_DISABLED_BG),
                borderWidth: 1,
                borderColor: PALETTE.INPUT_DISABLED_BORDER_COLOR,
                borderRadius: PALETTE.INPUT_BORDER_RADIUS,
                ...PALETTE.INPUT_DISABLED_TEXT,
            },
        },
    };
}
