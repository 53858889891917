import { useState, useCallback, useMemo, useContext, useRef, memo } from 'react';
import MojitoPresentation from 'mojito/presentation';
import MojitoCore from 'mojito/core';
import MojitoServices from 'mojito/services';
import { sumBy } from 'mojito/utils';
import EventListHeader from './header/index.jsx';
import EventListGroup from './group/index.jsx';
import EventListItem from './item/index.jsx';
import {
    useMarketColumnCount,
    useMarketSwitchers,
    useMarketsPreload,
    useLoadEventsInBatches,
} from './hooks';
import EventListUtils from './utils.js';
import { EventListContext } from 'modules/event-list/context/event-list-context.jsx';
import { useContentPreload } from 'modules/common/hooks';

const { DATA_TYPES } = MojitoServices.SportsContent.Events.descriptors;
const { EVENT_CHUNK } = DATA_TYPES;
const { FlexPane, ImageButton, InfiniteList } = MojitoPresentation.Components;
const { NavigationPathBuilder } = MojitoPresentation.Base.Navigation;
const IntentActionTrigger = MojitoPresentation.Base.Intent.IntentActionTrigger;
const { EVENT_TYPE } = MojitoServices.SportsContent.Events.types;
const { COUPON_EVENTS, COUPON_MARKETS } = MojitoServices.SportsContent.Events.types.CONTENT_ORIGIN;
const log = MojitoCore.logger.get('EventList');

const TYPE_SORTING = {
    TIME: 'TIME',
    DEFAULT: 'DEFAULT',
};
const TIME_SORTED_GROUP_ID = 'TIME_SORTED_GROUP_ID';

export default memo(function EventList(props) {
    const {
        mojitoTools: { config, appContext, stringResolver, instanceId },
        eventGroups,
        eventGroupsId,
        marketOptions,
        sportId,
        isInPlay,
        headerText,
        initialExpandedGroupIds,
        onItemClick,
        onDataLoad,
        enableSorting,
    } = props;
    const { numberOfEvents } = useContext(EventListContext) || {};
    const {
        routeResolver,
        analyticsEmitter: { emitAnalytics },
    } = appContext;
    const loadedEventIds = useRef([]);

    const getHrefAllEvents = () => {
        return isInPlay
            ? NavigationPathBuilder.inplayPath(sportId, routeResolver)
            : NavigationPathBuilder.sportPath(sportId, routeResolver);
    };

    const onEventClick = useCallback(
        eventId => {
            if (isInPlay) {
                IntentActionTrigger.showInPlayEvent(eventId);
            } else {
                IntentActionTrigger.showSportEvent(sportId, eventId);
            }
            onItemClick(eventId);
        },
        [sportId, isInPlay, onItemClick]
    );

    const onShowAllEventsClick = () => {
        if (isInPlay) {
            IntentActionTrigger.showInPlay(sportId);
        } else {
            IntentActionTrigger.showSport(sportId);
        }
        props.onShowAllButtonClick(sportId);
    };

    const isGameLineMode = config.gameLineSports[sportId];
    const { count: marketColumnCount, elementRef } = useMarketColumnCount(
        config.marketsSection,
        isGameLineMode
    );

    const marketSwitcherLabels = useMemo(() => {
        const labels = marketOptions.map(getOptionLabel).filter(Boolean);
        if (labels.length !== marketOptions.length) {
            log.warn(`Labels are missing for some of ${sportId} market options.`);
        }
        return labels;
    }, [marketOptions, sportId]);

    const { onMarketSwitch, selectedSwitchers } = useMarketSwitchers(
        marketSwitcherLabels,
        marketColumnCount
    );

    const selectedMarketOptions = useMemo(
        () => findSelectedMarketOptions(selectedSwitchers, marketOptions),
        [selectedSwitchers, marketOptions]
    );

    const headerTypes = useMemo(() => {
        const optionMapper = isGameLineMode ? getGameLineHeaders : option => option?.headers;
        return selectedMarketOptions.map(optionMapper);
    }, [isGameLineMode, selectedMarketOptions]);

    const sportName = appContext.sportName(sportId);

    // Determining child modules loading state and preload events
    const eventIds = useMemo(() => {
        loadedEventIds.current = [];
        return eventGroups
            .filter(eventGroup => initialExpandedGroupIds.includes(eventGroup.id))
            .flatMap(eventGroup => eventGroup.events.map(event => event.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventGroups, initialExpandedGroupIds, sportId]);

    // Preload events
    const eventContentType = isInPlay ? `${COUPON_EVENTS}Live` : COUPON_EVENTS;
    const marketContentType = isInPlay ? `${COUPON_MARKETS}Live` : COUPON_MARKETS;
    const [eventsPreloadDone] = useContentPreload(
        instanceId,
        eventContentType,
        EVENT_CHUNK,
        eventIds,
        [eventGroupsId]
    );

    // Preload markets
    const marketsPreloadDone = useMarketsPreload(
        instanceId,
        marketContentType,
        eventIds,
        eventGroups,
        selectedMarketOptions,
        isGameLineMode,
        [eventGroupsId]
    );

    const onChildDataLoad = eventId => {
        loadedEventIds.current.push(eventId);
        const dataLoaded = eventIds.every(eventId => loadedEventIds.current.includes(eventId));
        if (dataLoaded && onDataLoad) {
            onDataLoad(eventGroups);
        }
    };

    const titleText = headerText || sportName;
    const { nothingToShow: hideHeader } = EventListUtils.resolveHeaderAppearance(
        config.header,
        titleText,
        marketSwitcherLabels,
        isGameLineMode,
        isInPlay,
        enableSorting
    );

    const sportNameSorting =
        stringResolver.resolveString(`$EVENT_LIST.SPORT_NAME_BUTTON.${sportId}`, true) ||
        stringResolver.resolveString(`$EVENT_LIST.SPORT_NAME_BUTTON.DEFAULT`);

    const [sortingType, setSortingType] = useState(TYPE_SORTING.DEFAULT);
    const [sortingTitle, setSortingTitle] = useState(sportNameSorting);

    const handleToggleSort = () => {
        if (sortingType === TYPE_SORTING.DEFAULT) {
            emitAnalytics('timeSortingClicked', sportId);
            setSortingType(TYPE_SORTING.TIME);
            setSortingTitle(stringResolver.resolveString(`$EVENT_LIST.TIME_BUTTON`));
        } else {
            emitAnalytics('typeSortingClicked', sportId);
            setSortingType(TYPE_SORTING.DEFAULT);
            setSortingTitle(sportNameSorting);
        }
    };

    const sortedEvents = useMemo(() => {
        const events = eventGroups
            .flatMap(group => group.events)
            .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
            .sort((a, b) => a.startTime === b.startTime && a.id - b.id);

        return [
            {
                id: TIME_SORTED_GROUP_ID,
                events,
            },
        ];
    }, [eventGroups]);

    const isTimeSorting = sortingType === TYPE_SORTING.TIME;
    const groups = isTimeSorting ? sortedEvents : eventGroups;

    return (
        <FlexPane class="ta-EventList" config={config.container}>
            {!hideHeader && (
                <EventListHeader
                    config={config.header}
                    marketSectionRef={elementRef}
                    sportId={sportId}
                    titleText={titleText}
                    isGameLineMode={isGameLineMode}
                    isInPlay={isInPlay}
                    numberOfMarketSwitchers={marketColumnCount}
                    marketOptions={marketSwitcherLabels}
                    selectedMarketOptions={selectedSwitchers}
                    onSelectedMarketOptionChange={onMarketSwitch}
                    enableSorting={enableSorting}
                    showEarlyPayoutOffers={config.showEarlyPayoutInHeader}
                    eventGroups={eventGroups}
                    sortingTitle={sortingTitle}
                    onSortingClicked={handleToggleSort}
                />
            )}
            <FlexPane class="ta-EventListGroups" config={config.eventGroupsContainer}>
                {groups.map(group => (
                    <EventListGroup
                        key={group.id}
                        eventGroup={group}
                        sportId={sportId}
                        config={config.eventListGroup}
                        headerTypes={
                            group.eventGroupType !== EVENT_TYPE.OUTRIGHT ? headerTypes : undefined
                        }
                        initiallyExpanded={
                            isTimeSorting || initialExpandedGroupIds.includes(group.id)
                        }
                        isTimeSorting={isTimeSorting}
                        showEarlyPayoutOffers={!config.showEarlyPayoutInHeader}
                    >
                        <FlexPane class="ta-EventListItems" config={config.eventItemsContainer}>
                            <EventBatchesLoader
                                {...props}
                                isTimeSorting={isTimeSorting}
                                events={group.events}
                                onChildDataLoad={onChildDataLoad}
                                onEventClick={onEventClick}
                                isGameLineMode={isGameLineMode}
                                selectedMarketOptions={selectedMarketOptions}
                                eventsPreloadDone={eventsPreloadDone}
                                marketsPreloadDone={marketsPreloadDone}
                            />
                        </FlexPane>
                    </EventListGroup>
                ))}
            </FlexPane>
            {config.showAllEventsButton && (
                <ImageButton
                    onClick={onShowAllEventsClick}
                    hrefLink={getHrefAllEvents()}
                    label={resolveAllEventsLabel(
                        sportName,
                        isInPlay,
                        numberOfEvents || getTotalEventCount(eventGroups),
                        stringResolver
                    )}
                    config={config.allEventsButton}
                />
            )}
        </FlexPane>
    );
});

const EventBatchesLoader = props => {
    const {
        events,
        isTimeSorting,
        mojitoTools: { config },
    } = props;
    const [hasMore, setHasMore] = useState(true);
    const { visibleEvents, onFetchMore } = useLoadEventsInBatches(
        events,
        () => setHasMore(false),
        config.batchSize
    );

    return isTimeSorting ? (
        <div id="resultsContainer">
            <InfiniteList
                fetchMore={onFetchMore}
                hasMore={hasMore}
                config={config.infiniteListItemsContainer}
            >
                {visibleEvents.map(eventInfo => (
                    <Event {...props} key={eventInfo.id} eventInfo={eventInfo} />
                ))}
            </InfiniteList>
        </div>
    ) : (
        <>
            {events.map(eventInfo => (
                <Event {...props} key={eventInfo.id} eventInfo={eventInfo} />
            ))}
        </>
    );
};

const Event = props => {
    const {
        eventInfo,
        onEventClick,
        isGameLineMode,
        selectedMarketOptions,
        eventsPreloadDone,
        marketsPreloadDone,
        onChildDataLoad,
        sportId,
        isInPlay,
        mojitoTools: { config, appContext },
    } = props;
    const { routeResolver } = appContext;

    const getHrefItem = eventId => {
        return isInPlay
            ? NavigationPathBuilder.inplayEventPath(eventId, routeResolver)
            : NavigationPathBuilder.sportEventPath(sportId, eventId, routeResolver);
    };

    return (
        <EventListItem
            key={eventInfo.id}
            eventId={eventInfo.id}
            marketLines={eventInfo.marketLines}
            hrefLink={getHrefItem(eventInfo.id)}
            config={config.eventItem}
            onClick={onEventClick}
            isGameLineMode={isGameLineMode}
            marketOptions={selectedMarketOptions}
            shouldRequestData={eventsPreloadDone && marketsPreloadDone}
            onDataLoad={onChildDataLoad}
        />
    );
};

const getTotalEventCount = groups => sumBy(groups, group => group.events.length);
const getGameLineHeaders = gameLineOption => gameLineOption.options?.map(getOptionLabel);

const findSelectedMarketOptions = (optionLabels, marketOptions) => {
    if (!optionLabels || !marketOptions) {
        return;
    }
    return optionLabels
        .map(label => marketOptions.find(option => getOptionLabel(option) === label))
        .filter(Boolean);
};

const resolveAllEventsLabel = (sportName, isInPlay, eventCount, stringResolver) => {
    const l10nKey = isInPlay
        ? `$EVENT_LIST.ALL_INPLAY_EVENTS_BUTTON`
        : `$EVENT_LIST.ALL_EVENTS_BUTTON`;
    return stringResolver.resolveAndFormatString(l10nKey, sportName, eventCount);
};

const getOptionLabel = option => option.name || option.label;
