import PropTypes from 'prop-types';
import MojitoCore from 'mojito/core';
import DateTimeImpl from './date-time.jsx';

const UIView = MojitoCore.Presentation.UIView;

/**
 * This component is used for returning a formatted date and/or time string. No styling can be applied to the string
 * in this component and it should instead be handled in the wrapping component or element.
 *
 * @class DateTime
 * @memberof Mojito.Presentation.Components
 */

/**
 * This method allows customization of how the `dateTime` is rendered from a parent component. Useful when
 * the date and time string needs to be included within a larger text.
 *
 * @example <caption>A parent component where the the date time string is rendered together with a translation.</caption>
 * import MojitoPresentation from 'mojito/presentation';
 * const { DateTime } = MojitoPresentation.Components;
 *
 * class Example extends React.Component {
 *     renderDateTimeText(dateTime) {
 *       return this.resolveAndFormatString('$TRANSLATION_KEY', dateTime);
 *     }
 *
 *     render() {
 *       return <DateTime dateTime={this.state.dateTime} dateTimeRenderer={renderDateTimeText} />
 *     }
 * }
 *
 * @param {string} dateTime - A formatted date and time string.
 * @param {*} data - Data of unspecified type passed as rendererData prop to DateTime component.
 * @callback Mojito.Presentation.Components.DateTime.dateTimeRenderer
 */

/**
 * Defines parameters and the return value for a custom formatter, which can be used in the <code>DateTime.customFormatter</code>
 * configuration. This method is employed by the component when a format in the configuration is set to
 * <code>'CUSTOM'</code>. This function allows complete customization of the date and time string.
 *
 * @param {string} dateTime - Date and time string in ISO 8601 format.
 * @param {(string|string[])} locales - An array of locales or a locale string.
 * @param {number} timeOffset - UTC time offset in hours.
 *
 * @returns {string} Formatted date and time.
 * @function Mojito.Presentation.Components.DateTime#customFormatter
 */

/**
 * DateTime prop types.
 *
 * @property {string} dateTime - A date and time string, in ISO 8601 format.
 * @property {Mojito.Presentation.Components.DateTime.dateTimeRenderer} [dateTimeRenderer] - A custom render method for the formatted date and time string.
 * @property {*} [rendererData] - Data of unspecified type that will be passed into dateTimeRenderer callback method.
 * @memberof Mojito.Presentation.Components.DateTime
 */
const propTypes = {
    dateTime: PropTypes.string.isRequired,
    dateTimeRenderer: PropTypes.func,
    rendererData: PropTypes.any,
};

export default UIView('DateTime', DateTimeImpl, propTypes);
