import MojitoCore from 'mojito/core';
import { isBoolean, pick } from 'mojito/utils';
import { createRef } from 'react';

const { merge, shallowCopy } = MojitoCore.Base.objUtils;
const classUtils = MojitoCore.Base.classUtils;

export default class Button extends MojitoCore.Presentation.UIViewImplementation {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false,
            isActive: false,
        };

        this.onMouseEnter = () => this.setState({ isHovering: true });
        this.onMouseLeave = () => this.setState({ isHovering: false });

        this.onMouseDown = () => {
            window.addEventListener('mouseup', this.onMouseUp);
            this.onActiveChange(true);
        };

        this.onMouseUp = () => {
            window.removeEventListener('mouseup', this.onMouseUp);
            this.onActiveChange(false);
        };

        this.onTouchStart = () => {
            window.addEventListener('touchend', this.onTouchEnd);
            this.onActiveChange(true);
        };

        this.onTouchEnd = () => {
            window.removeEventListener('touchend', this.onTouchEnd);
            this.onActiveChange(false);
        };

        this.onClick = this.onClick.bind(this);
        this.onActiveChange = this.onActiveChange.bind(this);
        this.defaultButtonRef = createRef();
    }

    onActiveChange(active) {
        // Ignore active change if parent explicitly controls it.
        if (isBoolean(this.props.active)) {
            return;
        }
        this.setState({ isActive: active });
        this.props.onActiveChange(active);
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
    }

    onClick(event) {
        const { hrefLink, disabled, onClick, onClickData } = this.props;
        this.config.enableAHref && hrefLink && event.preventDefault();
        if (!disabled) {
            onClick(event, onClickData);
        }
        if (this.config.triggerCustomEvent) {
            const event = new Event('mojito_button_click', {
                bubbles: true,
                cancelable: true,
            });
            const { current } = this.getElementRef() || {};
            current?.dispatchEvent(event);
        }
    }

    getElementRef() {
        return this.props.buttonElementRef || this.defaultButtonRef;
    }

    render() {
        const customStyle = this.props.disabled
            ? shallowCopy(this.style.disabled)
            : merge(
                  this.state.isHovering ? this.style.hover : {},
                  this.state.isActive || this.props.active ? this.style.active : {}
              );

        if (this.props.hidden) {
            customStyle.visibility = 'hidden';
        }

        const style = merge(this.style.base, customStyle);

        if (
            style.backgroundImage &&
            style.backgroundImage !== 'none' &&
            !style.backgroundImage.startsWith('url(') &&
            !style.backgroundImage.includes('-gradient(')
        ) {
            style.backgroundImage = `url(${this.appContext().getImage(style.backgroundImage)})`;
        }

        const btnAtt = {
            style: style,
            onClick: this.onClick,
            className: classUtils.classNames('ta-Button', this.props.class),
            ref: this.getElementRef(),
            ...pick(this, 'onMouseEnter', 'onMouseLeave', 'onMouseDown', 'onTouchStart'),
            role: 'button',
        };

        if (this.config.enableAHref && this.props.hrefLink) {
            return (
                <a href={this.props.hrefLink} {...btnAtt}>
                    {this.props.children}
                </a>
            );
        }

        return <div {...btnAtt}>{this.props.children}</div>;
    }
}

Button.getStyle = config => {
    const { style } = config;
    const extraStyle = {
        display: config.inline ? 'inline-flex' : 'flex',
        boxSizing: 'border-box', // So that changing the border will not affect the overall layout
    };
    if (style.base.userSelect) {
        extraStyle.userSelect = style.base.userSelect;
    }
    return {
        ...style,
        base: merge(extraStyle, style.base),
    };
};
