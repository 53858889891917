import MojitoCore from 'mojito/core';
import DateTimeFormatter from 'presentation/formatters/date-time/index.js';

const { DateTimeUtils } = MojitoCore.Base;
const DAY = {
    TODAY: 'TODAY',
    TOMORROW: 'TOMORROW',
    OTHER: 'OTHER',
};
const CUSTOM = 'CUSTOM';

export default class DateTime extends MojitoCore.Presentation.UIViewImplementation {
    getDay(dateTime, timeOffset) {
        if (DateTimeUtils.isSameDateWithOffset(new Date(dateTime), 0, timeOffset)) {
            return DAY.TODAY;
        } else if (DateTimeUtils.isSameDateWithOffset(new Date(dateTime), 1, timeOffset)) {
            return DAY.TOMORROW;
        }

        return DAY.OTHER;
    }

    getFormattedDateTime(dateTime, format, timeOffset, locales) {
        const { customFormatter } = this.config;

        return format === CUSTOM
            ? customFormatter(dateTime, locales, timeOffset)
            : DateTimeFormatter.formatDateTime(dateTime, format, this.appContext().userSettings());
    }

    getTodayTomorrowDateTime(dateTime, day, timeOffset, locales) {
        const { todayTomorrowFormat } = this.config;
        const formattedDateTime =
            todayTomorrowFormat &&
            this.getFormattedDateTime(dateTime, todayTomorrowFormat, timeOffset, locales);

        return this.resolveAndFormatString(`$DATE_TIME.${day}`, formattedDateTime).trim();
    }

    render() {
        const { dateTime, dateTimeRenderer, rendererData } = this.props;
        const { showTodayAndTomorrowLabels, format } = this.config;
        const { timeOffset, locales } = this.appContext().userSettings();
        let formattedDateTime;

        if (showTodayAndTomorrowLabels) {
            const day = this.getDay(dateTime, timeOffset);
            formattedDateTime =
                day === DAY.OTHER
                    ? this.getFormattedDateTime(dateTime, format, timeOffset, locales)
                    : this.getTodayTomorrowDateTime(dateTime, day, timeOffset, locales);
        } else {
            formattedDateTime = this.getFormattedDateTime(dateTime, format, timeOffset, locales);
        }

        return dateTimeRenderer
            ? dateTimeRenderer(formattedDateTime, rendererData)
            : formattedDateTime;
    }
}
